import React, { useState, useRef } from 'react';
import './seek.scss';


const SeekProgressBar = ({ percentage, setPercentage, onPercentageChange, barHeight = 3, barbackground = '#d8d8d8', progressBarBackground = "#fc3434", buttonColor = '#000', buttonHeight = 10 }) => {
  const barRef = useRef();

  const handleMouseDown = (event) => {
    event.preventDefault();
    const rect = barRef.current.getBoundingClientRect();
    const updatePercentage = (event) => {
      const x = event.clientX - rect.left;
      const newPercentage = Math.max(0, Math.min((x / rect.width) * 100, 100));
      setPercentage(newPercentage);
      onPercentageChange(newPercentage);
    };
    updatePercentage(event);
    document.addEventListener('mousemove', updatePercentage);
    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updatePercentage);
    }, { once: true });
  };

  const handleClick = (event) => {
    const rect = barRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const newPercentage = Math.max(0, Math.min((x / rect.width) * 100, 100));
    setPercentage(newPercentage);
    onPercentageChange(newPercentage);
  };

  return (
    <div ref={barRef} onClick={handleClick} style={{ width: '100%', height: `${barHeight}px`, backgroundColor: `${barbackground}`, position: 'relative', overflow: 'visible' }} className='seek-bar'>
      <div style={{ width: `${percentage}%`, height: '100%', backgroundColor: `${progressBarBackground}`, position: 'relative', overflow: 'visible' }}>
        <div style={{ width: `${buttonHeight}px`, height: `${buttonHeight}px`, borderRadius: '50%', backgroundColor: `${buttonColor}`, position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', opacity: 0, transition: 'opacity 0.2s' }} onMouseDown={handleMouseDown} className="seek-button"></div>
      </div>
    </div>
  );
};


export default SeekProgressBar;

const SeekBarComponent = () => {
  const [percentage, setPercentage] = useState(50);

  const handlePercentageChange = (newPercentage) => {
    console.log(`New percentage: ${newPercentage}`);
  };

  return (
    <>
      {percentage}
      <SeekProgressBar percentage={percentage} setPercentage={setPercentage} onPercentageChange={handlePercentageChange} />
    </>
  );
};

// export default SeekBarComponent;