// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.see-more {
  position: relative;
}
.see-more .seemore-back {
  background: linear-gradient(0deg, #ffffff 6%, rgba(255, 255, 255, 0) 40%);
  z-index: 100;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
}
.see-more .content {
  position: relative;
}
.see-more .action {
  z-index: 101;
  cursor: pointer;
}
.see-more .action div {
  display: flex;
  align-items: center;
}
.see-more .action button {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.see-more .action button :before {
  content: "+";
  font-family: Verizon-NHG-eDS;
}`, "",{"version":3,"sources":["webpack://./src/components/common/seeMore.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EAEE,yEAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;AACJ;AAGE;EACE,kBAAA;AADJ;AAME;EACE,YAAA;EACA,eAAA;AAJJ;AAKI;EACE,aAAA;EAEA,mBAAA;AAJN;AAMI;EACE,YAAA;EACA,6BAAA;EACA,0BAAA;EACA,eAAA;EAEA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AALN;AAMM;EACE,YAAA;EACA,4BAAA;AAJR","sourcesContent":[".see-more{\n  position: relative;\n  .seemore-back{\n    // background: linear-gradient(180deg, #ffffff 74.74%, rgba(255, 255, 255, 0) 100%);\n    background: linear-gradient(0deg, #ffffff 6%, rgba(255, 255, 255, 0) 40%);\n    z-index: 100;\n    position: absolute;\n    top: 0px;\n    left: 0px;\n    right: 0px;\n    bottom: 0px;\n    width: 100%;\n    // height: auto;\n    // max-height: 78%;\n  }\n  .content{\n    position: relative;\n    // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 100%, #FFFFFF 100%);\n    // background: linear-gradient(180deg, red 74.74%, rgba(255, 255, 255, 0) 100%);\n\n  }\n  .action{\n    z-index: 101;\n    cursor: pointer;\n    div{\n      display: flex;\n      // justify-content: center;\n      align-items: center;\n      }\n    button{\n      border: none;\n      background-color: transparent;\n      text-decoration: underline;\n      cursor: pointer;\n\n      line-height: 20px;\n      font-size: 16px;\n      font-weight: 400;\n      letter-spacing: 0.5px;\n      :before{\n        content: '+';\n        font-family: Verizon-NHG-eDS;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
