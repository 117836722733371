// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input span[class^=InputContainer-VDS] {
  border-radius: 24px;
}
.search-input input {
  border-radius: 24px;
}

.search-input-wrapper {
  position: relative;
  min-width: 228px;
  max-width: 384px;
  width: 100%;
}
.search-input-wrapper .search-input {
  border: 0.0625rem solid rgb(111, 113, 113);
  padding: 8px;
  font-family: Verizon-NHG-eDS;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.03125rem;
  margin: 0px;
  padding: 10px 90px 11px 16px;
  width: -webkit-fill-available;
  border-radius: 24px;
}
.search-input-wrapper .search-input ::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color: red;
}
.search-input-wrapper .icon-space {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/common/inputSearch.scss"],"names":[],"mappings":"AACI;EACE,mBAAA;AAAN;AAEI;EAEE,mBAAA;AADN;;AAQE;EACE,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;AALJ;AAMI;EACE,0CAAA;EACA,YAAA;EACA,4BAAA;EACA,eAAA;EACA,oBAAA;EACA,0BAAA;EACA,WAAA;EACA,4BAAA;EACA,6BAAA;EACA,mBAAA;AAJN;AAKM;EACE,iBAAA;EACA,YAAA;EACA,UAAA;AAHR;AAMK;EACC,kBAAA;EACA,WAAA;EACA,MAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAJN","sourcesContent":[".search-input{\n    span[class^=InputContainer-VDS]{\n      border-radius: 24px;\n    }\n    input{\n     \n      border-radius: 24px;\n    \n    }\n  }\n  \n  \n  \n  .search-input-wrapper {\n    position: relative;\n    min-width: 228px;\n    max-width: 384px;\n    width: 100%;\n    .search-input {\n      border: 0.0625rem solid rgb(111, 113, 113);\n      padding: 8px;\n      font-family: Verizon-NHG-eDS;\n      font-size: 1rem;\n      line-height: 1.25rem;\n      letter-spacing: 0.03125rem;\n      margin: 0px;\n      padding: 10px 90px 11px 16px;\n      width: -webkit-fill-available;\n      border-radius: 24px;\n      ::placeholder {\n        font-weight: bold;\n        opacity: 0.5;\n        color: red;\n      }\n    }\n     .icon-space {\n      position: absolute;\n      right: 10px;\n      top: 0;\n      bottom: 0;\n      display: flex;\n      align-items: center;\n      cursor: pointer;\n  }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
