import { useSelector } from "react-redux";
// import GrantsPageComponent from "./Grants";
import GrantsPageComponent from "./GrantsSuperBowl.jsx";

const GrantsWrapperComponent = () => {

    const aemData = useSelector((state) => state.Aem.data);
    const aemFAQ = useSelector((state) => state.Aem.FAQ);
    const aemIncentivesData = useSelector((state) => state.Aem.incentives);

    return <>
    {aemIncentivesData.length > 0 && aemFAQ.length > 0 && aemData && <GrantsPageComponent />}
    </>;
}

export default GrantsWrapperComponent;