// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-container {
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
.preview-container .completed {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.preview-container .complete-icon {
  height: 64px;
  width: 64px;
  border-radius: 100%;
  background-color: #008331;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-container .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.8);
}`, "",{"version":3,"sources":["webpack://./src/components/common/preview.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAEJ;AAAE;EACE,YAAA;EACA,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACF,mBAAA;EAEE,oCAAA;AACJ","sourcesContent":[".preview-container{\n  width: 100%;\n  height: 450px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  border-radius: 16px;\n  .completed{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n  }\n  .complete-icon{\n    height: 64px;\n    width: 64px;\n    border-radius: 100%;\n    background-color: #008331;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  .button-container{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n  border-radius: 16px;\n\n    background-color: rgba(0,0,0,0.8);\n    \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
