import React, { useState, useRef, useEffect } from 'react';
import './CategoryPage.scss';
import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { useTranslation } from 'react-i18next';
import CategoryHeadingComponent from './common/CategoryHeadingComponent.jsx';
import LearningPathFilter from '../LearningPath/LearningPathFilter.jsx';
import { useSelector } from 'react-redux';
import { Title } from '@vds/typography';
import { Button, ButtonGroup } from '@vds/buttons';
import { retrieveLearningPathDetailFromAEM } from '../../utility/common.js';
import { useNavigate } from 'react-router-dom';
import { updateRegisterTriggeredFrom } from '../../store/aem.js';
import store from '../../store/store.js';

const CategoryLearningPathComponent = () => {
  const { t, i18n } = useTranslation();
  const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
  const [learningPathsDetailsHeading, setLearningPathsDetailsHeading] = useState();
  const navigate = useNavigate();
  
  useEffect(() => {
    retrieveLearningPathDetailFromAEM(userLanguage).then((response) => {
      setLearningPathsDetailsHeading({
        breadcrumbLabel: t('Learning Paths'),
        label: response.category.heading,
        subText: response.category.guestSubheading,
        registerNowText: response.category.registerNowText,
        registerText: response.category.registerText,
        registerLinkText: response.category.registerLinkText,
      });
    });
  },[]);

  useEffect(() => {
    localStorage.setItem('categoryPath',location.pathname);
  }, [location.pathname]);

  return (
    <section>
      <div className='categories-header bg-gray'>
        <Grid bleed="1440" colSizes={{ mobile: 4, tablet: 12, desktop: 12, }} >
          {/*<Row>
            <Col>
              <div className='mv-8X bg-gray'>
                <Breadcrumbs surface="light">
                  <BreadcrumbItem href="/">{t("Home")}</BreadcrumbItem>
                  <BreadcrumbItem>{t('Learning Paths')}</BreadcrumbItem>
                </Breadcrumbs>
              </div>
            </Col>
  </Row>*/}
          <Row>
            <Col>
              {learningPathsDetailsHeading && <CategoryHeadingComponent props={learningPathsDetailsHeading} />}
            </Col>
          </Row>
        </Grid>
        <div className='notification-ribbon'>
          <Grid>
          <Row>
            <Col
              colSizes={{
                mobile: 4,
                tablet: 12,
                desktop: 12,
              }}
            >
            <div className='ribbon-container pv-6X'>
              <Title size='small' primitive='h1'>
              <b> {learningPathsDetailsHeading?.registerNowText}</b> {learningPathsDetailsHeading?.registerText}
              </Title>
              <div>
             {/*} <Button onClick={e=>{store.dispatch(updateRegisterTriggeredFrom(location.pathname));navigate("/onboarding")}}>
              {learningPathsDetailsHeading?.registerLinkText}
            </Button>*/}
            <ButtonGroup
            childWidth={'100%'}
            viewport={'desktop'}
            rowQuantity={{ desktop: 2 }}
            data={[
              {
                children:learningPathsDetailsHeading?.registerLinkText,
                size: 'large',
                use: 'primary',
                width: 'auto',
                onClick: ()=>{store.dispatch(updateRegisterTriggeredFrom(location.pathname));navigate("/onboarding")},
              },
              {
                children: t('Sign in'),
                size: 'large',
                use: 'textLink',
                width: 'auto',
                onClick: () => {
                  location.href=global.config.CIAM_SIGN_IN_URL+encodeURI(`${global.config.URL_MAPPING_AUTHENTICATED_USER[location.pathname]}?ciamLang=${userLanguage}`)
                  document.cookie = `firstTimeLogin=true;domain=${global.config.APP_COOKIE_DOMAIN};path=/`
                },
              },
             
            ]}
            alignment={'center'}
          />
          
              </div>
              
             

            </div>
            </Col>
            </Row>
            </Grid>
          </div>
        <LearningPathFilter />
      </div>
    </section >
  );
};

export default CategoryLearningPathComponent;