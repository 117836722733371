import React , { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';

import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';

import CategoryHeadingComponent from './common/CategoryHeadingComponent.jsx';
import CategoryFeatureComponent from './common/CategoryFeatureComponent.jsx';
import CategoryFiltersAndSortComponent from './common/CategoryFiltersAndSortComponent.jsx';
import CategoryFiltersComponent from './common/CategoryFiltersComponent.jsx';
import CategoryFilteredCardsComponent from './common/CategoryFilteredCardsComponent.jsx';
import { filters } from './mocks.js';

import './CategoryPage.scss';

const CategoryOffersComponent = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [offersDetails , setOffersDetails] = useState(null);

    useEffect(()=>{
        localStorage.setItem('categoryPath',location.pathname);
    },[]);
    
    useEffect(() => {
        //Mock API Call
        const fetchOffersDetails = async () => {
            // Replace with actual API Call
            const data = {
                'heading': {
                    breadcrumbLabel: 'Offers',
                    h1:'',
                    h2:'',
                    modalLinkHeading:'',
                    modalLinkUrl:'',
                    rightNavHeading:'',
                    rightNavUrl:'',
                    label: 'Offers',
                    subText: 'Discover exciting ways to learn about crucial topics with other small business owners.',
                    textLink: { label: 'What types of event formats do we offer?', link: '', tooltipText: '' },
                    buttons: [],
                    sideTextLink: { label: '', link: '' },
                    sideIcon: { name: '', color: '' },
                    toolTipHeading:'Get your next phone free',
                    toolTipBody:'Up to $168 device payment purchase or full retail price purchase req’d. Less up to $168 promo credit applied over 24 mos.promo credit ends if req’s are no longer met 0% APR.'
                },
                'feature':{

                },
                'filterSortData':{

                },
                'filters':{ filters },
                'filteredData':{

                }
            };
            setOffersDetails(data);
        };
        fetchOffersDetails();
    },[]);

    if (!offersDetails) return <div>Loading...</div>;

    console.log("*****OffersCategoryComponent offersDetails.heading:");

    return (
        <section>
            <div className='categories-header bg-gray'>
                <Grid bleed="1440" colSizes={{ mobile: 4, tablet: 12, desktop: 12, }} >
                    <Row>
                        <Col>
                            <div className='mv-8X bg-gray'>
                                <Breadcrumbs surface="light">
                                    <BreadcrumbItem href="https://www.verizon.com/">Home</BreadcrumbItem>
                                    <BreadcrumbItem href="https://www.verizon.com/">Courses</BreadcrumbItem>
                                </Breadcrumbs>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CategoryHeadingComponent props={offersDetails.heading} />
                        </Col>
                    </Row>
                </Grid>
            </div>
            <div className='bg-white results-section pt-8X sm-pt-8X pb-16X'>
                <Grid bleed="1440" colSizes={{ mobile: 4, tablet: 12, desktop: 12, }}>
                    <Row>
                        <Col>
                            <div>
                                <CategoryFeatureComponent data={offersDetails.feature} />
                            </div>
                            <CategoryFiltersAndSortComponent data={offersDetails.filterSortData} />
                        </Col>
                    </Row>
                    <Row>
                        <Col colSizes={{ mobile: 4, tablet: 3, desktop: 3, }}> {!isMobile && (
                            <CategoryFiltersComponent filters={offersDetails.filters} />
                            )}
                        </Col>
                        <Col colSizes={{ mobile: 4, tablet: 12, desktop: 9,}} >
                            <CategoryFilteredCardsComponent data={offersDetails.filteredData} />
                        </Col>
                    </Row>
                </Grid>
            </div>
        </section>
    );

};

export default CategoryOffersComponent;

