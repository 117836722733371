import moment from 'moment-timezone';
import 'moment/locale/es';
import { quickTip_en,quickTip_es } from '../components/results/quickTips';

const HOURS_TO_SHOW_BLUE = 48;
const HOURS_TO_SHOW_STARTS_SOON = 24;
const MIN_TO_SHOW_IN_PROGRESS = 15;
const BLUE_COLOR = "#006FC1";
const DEFAULT_COLOR = "#6F7171";
const ORANGE_COLOR = "#FF8027";
const DARK_ORANGE_COLOR = "#B95319";
const SEATS_THRESHHOLD = 3;
const GREEN_COLOR = '#008330';
let dateFormat = "MMM D, h:mm a";
const TAG_BILINGUAL="Bilingual";
const TAG_SPANISH="En español";
const TAG_RECORDED_EVENTS="Recorded Event";

let topicsCollection = [
  { id: '2', arialabel: 'New', label: 'Branding', icon: 'small-business' },
  { id: '1', arialabel: 'Recommended', label: 'Cybersecurity', icon: 'security-check' },
  { id: '3', arialabel: 'Recommended', label: 'Funding', icon: 'coin' },
  { id: '4', arialabel: 'Recommended', label: 'Legal Essentials', icon: 'law' },
  { id: '5', arialabel: 'Recommended', label: 'Managing Money', icon: 'wallet' },
  { id: '6', arialabel: 'Recommended', label: 'Online Marketing', icon: 'laptop-trends' },
  { id: '7', arialabel: 'Recommended', label: 'Operations', icon: 'operational-transformation' },
  { id: '8', arialabel: 'Recommended', label: 'Social Media', icon: 'chat' },
  { id: '9', arialabel: 'Recommended', label: 'Strategy', icon: 'survey' },
  { id: '10', arialabel: 'Recommended', label: 'Website Design', icon: 'digital-content' },
  { id: '11', arialabel: 'Recommended', label: 'Wellness', icon: 'emoji' },
  { id: '12', arialabel: 'Recommended', label: 'Winning Customers', icon: 'custom-audience' },
];

Date.prototype.addDays = function(days)
{
    var day = new Date(this.valueOf());
    day.setDate(day.getDate() + days);
    return day;
}

function compareCurrentTimeInSeconds(date) {
    const now = new Date(Date.now());
    const elapsedTimeInSeconds = Math.floor((now - date) / 1000);
    return elapsedTimeInSeconds;
}

function getCookieValue(name){
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
}

function setWithExpiry(key, value, ttl, expiryTime) {
	const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: expiryTime && new Date().getTime() < expiryTime ? expiryTime : now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}

function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

const createCourseObject = (course, userLanguage) => {
  let userLanguageTitle = course.localizeData.filter(e => e.locale.indexOf(userLanguage) != -1);
  let title = course.title;
  if(userLanguageTitle.length > 0) {
    console.log("userLanguageTitle", userLanguageTitle);
    title = userLanguageTitle[0].name
  }
  let type = '';
  if(course.tags?.indexOf('Standard Course') != -1) {
    type = 'Standard Course';
  } else if (course.tags?.indexOf('Mini Course') != -1) {
    type = 'Mini Course'
  }
  
  let icon = 'count-down';

  let skillLevel = [];
  course.skills?.forEach((skill) => {
    if(skill.level && skill.level != null) {
      skillLevel.push(skill.level);
    }
  });

  let tag="";
  if (course.locale.indexOf('es-ES') != -1 &&  userLanguage === "es" ){
    tag= TAG_SPANISH;
  }

  return {
    id: course.id,
    image: course.imageUrl, 
    title: title,
    format: 'Course',
    tag:tag,
    locale: course.locale,
    enrollmentCount: course.enrollmentCount,
    tags:course.tags,
    launchUrl: course.launchUrl,
    type: type,
    duration: `${Math.ceil(course.duration/60)} minutes`,
    icon: icon,
    datePublished: course.datePublished,
    skillLevels: skillLevel
  };
}

const forcedRankingOffersTopics = (offersData,topics,requiredResults) => {
  const forcedRanking = offersData?.forcedRanking;
  const rawOffers = offersData?.offers;
  var interestsTopics=Object.keys(forcedRanking);
  let userInterests = [];
  // const businessData = userDataFromStore?.business;
  interestsTopics.map((item) => {
    if (topics.includes(item)) {
      userInterests.push(topics[0]);
    }
  });
  let sortedresults = [];
  let interestIndex = 0, forcedRankIndex = 0, completedIndex = 0;
  if(userInterests.length == 1 && userInterests[0] == "I'm not sure"){
    completedIndex++;
  }
  while (completedIndex < userInterests?.length) {
    const tempObj = forcedRanking?.[userInterests[interestIndex]];
    if (tempObj.length > forcedRankIndex) {
      var offerObject = rawOffers[0]?.[tempObj[forcedRankIndex]];
      if (sortedresults.filter((item) => item == offerObject).length == 0) {
        sortedresults.push(offerObject);
      }
    } else {
      completedIndex++;
    }
    if (interestIndex < (userInterests.length - 1)) {
      interestIndex++;
    }
    else {
      interestIndex = 0;
      forcedRankIndex++;
    }
  }
  var otherIndex = 0;
  const iamNotSureRankinList = forcedRanking?.["I'm not sure"];
  while (sortedresults.length < requiredResults && otherIndex < iamNotSureRankinList.length) {
    var otherSortItem = rawOffers[0]?.[iamNotSureRankinList[otherIndex]];
    if (sortedresults.filter((item) => item == otherSortItem).length == 0) {
      sortedresults.push(otherSortItem);
    }
    otherIndex++;
  }
  return sortedresults;
}

const createLearningPathObject = (learningPath, userLanguage) => {
  let userLanguageTitle = learningPath.localizeData.filter(e => e.locale.indexOf(userLanguage) != -1);
  let title = "";
  let shortDesc = "";
  if(userLanguageTitle.length > 0) {
    console.log("userLanguageTitle", userLanguageTitle);
    title = userLanguageTitle[0].name;
    shortDesc = userLanguageTitle[0].description;
  } else {
    title = learningPath.localizeData[0].name;
    shortDesc = learningPath.localizeData[0].description;
  }

  let type = 'Topic';
  if(learningPath.tags?.indexOf('Standard Course') != -1) {
    type = 'Standard Course';
  } else if (learningPath.tags?.indexOf('Mini Course') != -1) {
    type = 'Mini Course'
  }
  
  let status = 'Get Started';
  let statusColor = DEFAULT_COLOR;
  let statusIcon = 'count-down';

  let numberOfResources = 0;
  // learningPath.listOfResources.forEach((item) => {
  //   numberOfResources = numberOfResources + item.loIds.length;
  // });

  let tag="";
  if (learningPath.locale.indexOf('es-ES') != -1 &&  userLanguage === "es" ){
    tag= TAG_SPANISH;
  }

  return {
    courseId: learningPath.id,
    desktopImage: learningPath.imageUrl, 
    label: title,
    format: 'Learning Path',
    tag:tag,
    locale: learningPath.locale,
    enrollmentCount: learningPath.enrollmentCount,
    tags:learningPath.tags,
    type: type,
    duration: learningPath.duration,
    datePublished: learningPath.datePublished,
    //resourceType: topicsCollection.filter(e => learningPath.tags?.indexOf(e.label) != -1).map(e=>e.label).join(', '),
    shortDesc: shortDesc,
    duration: { 
      time: status, 
      color: statusColor, 
      icon: statusIcon
    },
  };
}

const createEventObject = (event, userLanguage) => {
  let locationForConferenceModule = event.modules.filter(e => e.location != null);
  let locationForConf = null;
  if(locationForConferenceModule.length > 0) 
    locationForConf = locationForConferenceModule[0].location;

  //Change locale for date format
  console.log("userLanguage", userLanguage);
  moment.locale(userLanguage);
  switch(userLanguage) {
    case "en":
      dateFormat = "MMM D, h:mm a";
      break;
    case "es":
      dateFormat = "LL, h:mm a";
      break;
  }

  let duration = getDateFormatted(event);
  let additionalStatus = getAdditionalStatus(event, duration);
  let userLanguageTitle = event.localizeData.filter(e => e.locale.indexOf(userLanguage) != -1);
  let title = event.title;
  if(userLanguageTitle.length > 0) {
    console.log("userLanguageTitle", userLanguageTitle);
    title = userLanguageTitle[0].name
  }

  let type = '';
  if(event.tags?.indexOf('Group Session') != -1) {
    type = 'Ask the Expert';
  } else if (event.tags?.indexOf('Workshop/Networking') != -1) {
    type = 'Hands-on Help'
  } else if (event.tags?.indexOf('Office Hours') != -1) {
    type = 'Courses Q&As'
  } else if (event.tags?.indexOf('Community') != -1) {
    type = 'Networking Sessions'
  }

  let skillLevel = [];
  event.skills?.forEach((skill) => {
    if(skill.level && skill.level != null) {
      skillLevel.push(skill.level);
    }
  });

  let startDate = new Date(new Date(event.dateStart));

  let tag="";
  if(event.locale.indexOf('en-US') != -1 && event.locale.indexOf('es-ES') != -1){
    tag= TAG_BILINGUAL;
   } else if (event.locale.indexOf('es-ES') != -1 &&  userLanguage === "es" ){
    tag= TAG_SPANISH;
  }

  return { 
    id: event.id,
    image: event.imageUrl, 
    additionalStatus: additionalStatus, 
    duration: duration, 
    title: title,
    status: '',
    showBookmarkIcon: true,
    startDate: new Date(new Date(event.dateStart)).toString(),
    endDate: new Date(new Date(startDate.getTime() + event.duration*1000)),
    location: locationForConf,
    availableSeats: event.availableSeats,
    tags:event.tags,
    enrollmentDeadline: event.enrollmentDeadline,
    recording: event.attachments,
    launchUrl: event.launchUrl,
    locale: event.locale,
    format: 'Event',
    tag:tag,
    type: type,
    isFinalDayEnrollment: additionalStatus.tagName == 'Final day to register',
    skillLevels: skillLevel,
    seatLimit: event.seatLimit
  }
}

function getDateFormatted(event) {
  let startTime = new Date(event.dateStart);
  let endTime = new Date(event.completionDeadline);
  let formattedDate = "";
  let tileViewTime = "";
  let colorForDate = DEFAULT_COLOR;
  
  let momentStartTime = moment(startTime);
  let momentEndTime = moment(endTime);
  
  if(startTime.getDate() != endTime.getDate()) {
    formattedDate = momentStartTime.format(dateFormat) + "-" + momentEndTime.format(dateFormat);
  } else {
    formattedDate = momentStartTime.format(dateFormat) + "-" + momentEndTime.format('h:mm a')
  }
  tileViewTime = momentStartTime.format(dateFormat);

  if(moment.duration(momentStartTime.diff(moment(new Date()))).asHours() <= HOURS_TO_SHOW_BLUE) {
    colorForDate = BLUE_COLOR;
  }
  return {
    dateTime: formattedDate, 
    tileViewTime: tileViewTime,
    color: colorForDate, 
    timeInMinutes: Math.floor(moment.duration(momentStartTime.diff(moment(new Date()))).asMinutes()),
    timeInSeconds: Math.floor(moment.duration(momentStartTime.diff(moment(new Date()))).asSeconds()),
    timeInHours: Math.floor(moment.duration(momentStartTime.diff(moment(new Date()))).asHours())
  };
}

function compareDates(a, b) {
  let aStartTime = a.dateStart;
  let bStartTime = b.dateStart;
  if(aStartTime < bStartTime) {
    return -1;
  } else if(aStartTime > bStartTime) {
    return 1;
  }
  return 0;
}

const getAdditionalStatus = (event, duration) => {

  let additionalStatus = {};  
  if(event.availableSeats <= 0 && event.seatLimit != 0) {
    additionalStatus = {
      tagIcon: 'warning', 
      tagName: 'Event full - waitlist available', 
      color: ORANGE_COLOR
    };
  }
  if(event.availableSeats > 0 && event.availableSeats < SEATS_THRESHHOLD) {
    additionalStatus = {
      tagIcon: 'warning', 
      tagName: `${event.availableSeats} spots remaining`, 
      color: ORANGE_COLOR
    };
  }
  if(duration.timeInHours <= HOURS_TO_SHOW_STARTS_SOON) {
    additionalStatus = {
      tagIcon: 'warning', 
      tagName: 'Final day to register', 
      color: ORANGE_COLOR
    };
  }
  if(event.enrollmentDeadline != null && event.enrollmentDeadline <= new Date().getTime()) {
    additionalStatus = {
      tagIcon: 'history', 
      tagName: 'Registration closed', 
      color: DARK_ORANGE_COLOR
    };
  }
  return additionalStatus;
}

const getRecommended = (attr, recommendedCourses, courses, inProgressIds ) => {
  console.log(inProgressIds, '=> inProgressIds')
  const inProgressCourses = inProgressIds.map(item =>  courses.find(course => course.id === item)).filter(item => item!== undefined)
  console.log(inProgressCourses, '=> inProgressCourses')
  const attrCourses =  attr.reduce((accum, item) => {
    const arr = recommendedCourses[item]??[]
    const data = arr.reduce((acc, courseId) => {
      const obj = courses.find(course => course.id === courseId)
      if(obj){
        acc.push(obj)
      }
        return acc
    }, [])
    accum = [...accum, ...data]
    return accum
  }, [])

  const data = ([...inProgressCourses, ...attrCourses]).filter((item, index, array) => array.findIndex(subItem => subItem.id === item.id) === index)
  return data;
 
}
const getRecommendedResults = (attr, recommendedCourses, courses ) => {
  //console.log(inProgressIds, '=> inProgressIds')
 // const inProgressCourses = inProgressIds.map(item =>  courses.find(course => course.id === item)).filter(item => item!== undefined)
 // console.log(inProgressCourses, '=> inProgressCourses')
  const attrCourses =  attr.reduce((accum, item) => {
    const arr = recommendedCourses[item]??[]
    const data = arr.reduce((acc, courseId) => {
      const obj = courses.find(course => course.id === courseId)
      if(obj){
        acc.push(obj)
      }
        return acc
    }, [])
    accum = [...accum, ...data]
    return accum
  }, [])

  const data = ([...attrCourses]).filter((item, index, array) => array.findIndex(subItem => subItem.id === item.id) === index)
  return data;
 
}

const getSuggestedEnglish = (enrollments, suggestions, userDataFromStore, recommendedCourses, count=12) => {
  const courses = Object.values((suggestions).courses??{}).filter(item => (item.locale??[]).includes('en-US'))
  console.log("suggestions api courses", courses);  
  const attributes = userDataFromStore.business?.attributes??{}
  const inProgressIds = (enrollments??[]).filter(item => item.attributes.state === "STARTED").sort((a,b) => new Date(b.attributes?.dateEnrolled??'') - new Date(a.attributes?.dateEnrolled??''))
  .map(item => item.relationships?.learningObject?.data?.id??'' ).filter(item => item !== '')
  let attr = Object.keys(attributes).filter(key => attributes[key] === 'Yes')
  if(!attr.includes("I'm not sure")){
      attr = [...attr, "I'm not sure"]
  }
  
  const recommended = (getRecommended(attr, recommendedCourses, courses, inProgressIds )??[]).slice(0, count)
  const newCourses = Object.values(courses).sort((a,b) => new Date(b.datePublished) - new Date(a.datePublished)).slice(0, count)
  const popular = Object.values(courses).sort((a,b)=> (b.enrollmentCount) - (a.enrollmentCount)).slice(0,count);
  return {
    recommended,
    newCourses,
    popular
  }
}


const getSuggestedSpanish = (enrollments, suggestions, userDataFromStore, recommendedCourses, count=12) => {
  const courses = Object.values((suggestions).courses??{}) 
  const spanishCourses = courses.filter(item => (item.locale??[]).includes('es-ES')) 
  const englishCourses = courses.filter(item => (item.locale??[]).includes('en-US')) 
  const finalCourses = [...spanishCourses, ...englishCourses]
  const attributes = userDataFromStore.business?.attributes??{}
  const inProgressIds = (enrollments??[]).filter(item => item.attributes.state === "STARTED").sort((a,b) => new Date(b.attributes?.dateEnrolled??'') - new Date(a.attributes?.dateEnrolled??''))
  .map(item => item.relationships?.learningObject?.data?.id??'' ).filter(item => item !== '')
  console.log("inProgressIds",inProgressIds);
  let attr = Object.keys(attributes).filter(key => attributes[key] === 'Yes')
  if(!attr.includes("I'm not sure")){
      attr = [...attr, "I'm not sure"]
  }
  
  const recommendedSpanish = (getRecommended(attr, recommendedCourses, spanishCourses, inProgressIds )??[])
  const recommendedEnglish = (getRecommended(attr, recommendedCourses, englishCourses, inProgressIds )??[])
  const recommended = ([...recommendedSpanish, ...recommendedEnglish]).slice(0, count)
  const newCourses = Object.values(finalCourses).sort((a,b) => new Date(b.datePublished) - new Date(a.datePublished)).slice(0, count)
  const popular = Object.values(finalCourses).sort((a,b)=> (b.enrollmentCount) - (a.enrollmentCount)).slice(0,count);
  return {
    recommended,
    newCourses,
    popular
  }
}
const getSuggestedEnglishbyTopic = ( suggestions, topicsOfInterest, recommendedCourses) => {
  console.log("suggestions api courses", suggestions);  
  const courses = suggestions
  //Object.values((suggestions).courses??{}).filter(item => (item.locale??[]).includes('en-US'))
  let count=courses?.length;
  console.log("suggestions api courses", courses);  
  //const attributes = topicsOfInterest
  //const inProgressIds = (enrollments??[]).filter(item => item.attributes.state === "STARTED").sort((a,b) => new Date(b.attributes?.dateEnrolled??'') - new Date(a.attributes?.dateEnrolled??''))
 // .map(item => item.relationships?.learningObject?.data?.id??'' ).filter(item => item !== '')
  let attr = topicsOfInterest
  if(!attr.includes("I'm not sure")){
      attr = [...attr, "I'm not sure"]
  }
  
  const recommended = (getRecommendedResults(attr, recommendedCourses, courses )??[]).slice(0, count)
  const newCourses = Object.values(courses).sort((a,b) => new Date(b.datePublished) - new Date(a.datePublished)).slice(0, count)
  const popular = Object.values(courses).sort((a,b)=> (b.enrollmentCount) - (a.enrollmentCount)).slice(0,count);
  console.log()
  return {
    recommended,
    newCourses,
    popular
  }
//   console.log("Suggestions",suggestions)
//   const courses = Object.values((suggestions??[]).courses)
//   console.log("suggestions api courses", courses);  
//  // const attributes = userDataFromStore.business?.attributes??{}
//   //const inProgressIds = (enrollments??[]).filter(item => item.attributes.state === "STARTED").sort((a,b) => new Date(b.attributes?.dateEnrolled??'') - new Date(a.attributes?.dateEnrolled??''))
//  // .map(item => item.relationships?.learningObject?.data?.id??'' ).filter(item => item !== '')
//   let attr = topicsOfInterest
//   if(!attr.includes("I'm not sure")){
//       attr = [...attr, "I'm not sure"]
//   }
  
//   const recommended = (getRecommendedResults(attr, recommendedCourses, courses )??[]).slice(0, count)
//   const newCourses = Object.values(courses).sort((a,b) => new Date(b.datePublished) - new Date(a.datePublished)).slice(0, count)
//   const popular = Object.values(courses).sort((a,b)=> (b.enrollmentCount) - (a.enrollmentCount)).slice(0,count);
//   return {
//     recommended,
//     newCourses,
//     popular
//   }
}


const getSuggestedSpanishbyTopic = ( suggestions, topicsOfInterest, recommendedCourses) => {
  const courses = suggestions
  //Object.values((suggestions).courses??{}) 
  const spanishCourses = courses.filter(item => (item.locale??[]).includes('es-ES')) 
  const englishCourses = courses.filter(item => (item.locale??[]).includes('en-US')) 
  const finalCourses = [...spanishCourses, ...englishCourses]
  let count=courses.length;
 // const attributes = userDataFromStore.business?.attributes??{}
  // const inProgressIds = (enrollments??[]).filter(item => item.attributes.state === "STARTED").sort((a,b) => new Date(b.attributes?.dateEnrolled??'') - new Date(a.attributes?.dateEnrolled??''))
  // .map(item => item.relationships?.learningObject?.data?.id??'' ).filter(item => item !== '')
 // console.log("inProgressIds",inProgressIds);
  let attr = topicsOfInterest
  if(!attr.includes("I'm not sure")){
      attr = [...attr, "I'm not sure"]
  }
  
  const recommendedSpanish = (getRecommendedResults(attr, recommendedCourses, spanishCourses )??[])
  const recommendedEnglish = (getRecommendedResults(attr, recommendedCourses, englishCourses )??[])
  const recommended = ([...recommendedSpanish, ...recommendedEnglish]).slice(0, count)
  // const newCourses = Object.values(finalCourses).sort((a,b) => new Date(b.datePublished) - new Date(a.datePublished)).slice(0, count)
  // const popular = Object.values(finalCourses).sort((a,b)=> (b.enrollmentCount) - (a.enrollmentCount)).slice(0,count);
  const sortedCourses = Object.values(englishCourses).sort((a,b) => new Date(b.datePublished) - new Date(a.datePublished)).slice(0, count)
  const newCourses = [...spanishCourses, ...sortedCourses]
  const sortedPopular = Object.values(englishCourses).sort((a,b)=> (b.enrollmentCount) - (a.enrollmentCount)).slice(0,count);
  const popular =  [...spanishCourses, ...sortedPopular]
  return {
    recommended,
    newCourses,
    popular
  }
//   const courses = Object.values((suggestions??[]).courses) 
//   const spanishCourses = courses.filter(item => (item.locale??[]).includes('es-ES')) 
//   const englishCourses = courses.filter(item => (item.locale??[]).includes('en-US')) 
//   const finalCourses = [...spanishCourses, ...englishCourses]
//  // const attributes = userDataFromStore.business?.attributes??{}
//   // const inProgressIds = (enrollments??[]).filter(item => item.attributes.state === "STARTED").sort((a,b) => new Date(b.attributes?.dateEnrolled??'') - new Date(a.attributes?.dateEnrolled??''))
//   // .map(item => item.relationships?.learningObject?.data?.id??'' ).filter(item => item !== '')
//   let attr = topicsOfInterest
//   if(!attr.includes("I'm not sure")){
//       attr = [...attr, "I'm not sure"]
//   }
  
//   const recommended = (getRecommendedResults(attr, recommendedCourses, finalCourses )??[]).slice(0, count)
//   const newCourses = Object.values(finalCourses).sort((a,b) => new Date(b.datePublished) - new Date(a.datePublished)).slice(0, count)
//   const popular = Object.values(finalCourses).sort((a,b)=> (b.enrollmentCount) - (a.enrollmentCount)).slice(0,count);
//   return {
//     recommended,
//     newCourses,
//     popular
//   }
}
const getSuggestedCourses = (lng, enrollments, suggestions, userDataFromStore, recommendedCourses, count=12) => {

 if(lng === 'en'){
  const data = getSuggestedEnglish( enrollments, suggestions, userDataFromStore, recommendedCourses, count)
  return data;
 }
 

 const data = getSuggestedSpanish( enrollments, suggestions, userDataFromStore, recommendedCourses, count)
  return data;
}
const getSuggestedCoursesbyTopic = (lng, suggestions, topicsOfInterest, recommendedCourses, count=12) => {
console.log("result.data",suggestions)
  if(lng === 'en'){
   const data = getSuggestedEnglishbyTopic( suggestions, topicsOfInterest, recommendedCourses, count)
   return data;
  }
  
 
  const data = getSuggestedSpanishbyTopic( suggestions, topicsOfInterest, recommendedCourses, count)
   return data;
 }

const recommendedEvents = (events, selectedTopicsOfInterest, language, limit) => {
  let sortedEvents = [];
  switch (language) {
    case "en":
      if(events?.length){
        sortedEvents = events.filter(e=>selectedTopicsOfInterest.some(r=> e.tags?.includes(r)));
        let firstOccurence = null;
        sortedEvents.every((event, index) => {
          if(event.availableSeats > 0 || event.seatLimit == 0) {
            firstOccurence = index;
            return false;
          }
          return true;
        });
        if(firstOccurence != null) {
          let availableItem = sortedEvents.splice(firstOccurence, 1);
          sortedEvents = [availableItem[0], ...sortedEvents];
        }
      }
      break;
    case "es":
      if(events?.length){
        sortedEvents = recommenededSpanish(events, selectedTopicsOfInterest, limit);
      }
      break;
  }
  return sortedEvents;
}

const recommenededSpanish = (events, selectedTopicsOfInterest, limit) => {
  let firstEventForSpanish = null;
  let spanishEventWithTOI = [];
  let spanishSeatAvailable = [];
  let spanishWaitlist = [];
  let englishEventWithTOI = [];
  let englishSeatAvailable = [];
  let englishWaitlist = [];
  let sortedEvents = [];

  if(!selectedTopicsOfInterest.includes("I'm not sure")) {
    events.forEach((event) => {
      let matchesTOI = selectedTopicsOfInterest.some(r=> event.tags?.includes(r));
      if(event.locale.indexOf("es-ES") != -1) {
        if(matchesTOI) {
          if(event.availableSeats > 0 || event.seatLimit == 0) {
            if(firstEventForSpanish == null) {
              firstEventForSpanish = event;
            } else {
              spanishEventWithTOI.push(event);
            }
          } else {
            spanishEventWithTOI.push(event);
          }
        } else {
          if(event.availableSeats > 0 || event.seatLimit == 0) {
            spanishSeatAvailable.push(event);
          } else {
            spanishWaitlist.push(event);
          }
        }
      } else {
        if(matchesTOI) {
          englishEventWithTOI.push(event);
        } else {
          if(event.availableSeats > 0 || event.seatLimit == 0) {
            englishSeatAvailable.push(event);
          } else {
            englishWaitlist.push(event);
          }
        }
      }
    });

    if(firstEventForSpanish == null) {
      if(spanishSeatAvailable.length > 0) {
        firstEventForSpanish = spanishSeatAvailable.splice(0, 1);
      }
    }

    if(firstEventForSpanish != null && firstEventForSpanish.length > 0) {
      sortedEvents = [firstEventForSpanish[0]];
    }

    if(spanishEventWithTOI.length <= limit) {
      sortedEvents = sortedEvents.concat(spanishEventWithTOI);
    } else {
      sortedEvents = sortedEvents.concat(spanishEventWithTOI.splice(0,limit));
    }
    
    let remainingEventToReachLimit = limit - sortedEvents.length;
    if(remainingEventToReachLimit > 0) {
      if(spanishSeatAvailable.length <= remainingEventToReachLimit) {
        sortedEvents = sortedEvents.concat(spanishSeatAvailable);
      } else {
        sortedEvents = sortedEvents.concat(spanishSeatAvailable.splice(0,remainingEventToReachLimit));
      }
    } else {
      return sortedEvents;
    }

    remainingEventToReachLimit = limit - sortedEvents.length;
    if(remainingEventToReachLimit > 0) {
      if(spanishWaitlist.length <= remainingEventToReachLimit) {
        sortedEvents = sortedEvents.concat(spanishWaitlist);
      } else {
        sortedEvents = sortedEvents.concat(spanishWaitlist.splice(0,remainingEventToReachLimit));
      }
    } else {
      return sortedEvents;
    }

    remainingEventToReachLimit = limit - sortedEvents.length;
    if(remainingEventToReachLimit > 0) {
      if(englishEventWithTOI.length <= remainingEventToReachLimit) {
        sortedEvents = sortedEvents.concat(englishEventWithTOI);
      } else {
        sortedEvents = sortedEvents.concat(englishEventWithTOI.splice(0,remainingEventToReachLimit));
      }
    } else {
      return sortedEvents;
    }

    remainingEventToReachLimit = limit - sortedEvents.length;
    if(remainingEventToReachLimit > 0) {
      if(englishSeatAvailable.length <= remainingEventToReachLimit) {
        sortedEvents = sortedEvents.concat(englishSeatAvailable);
      } else {
        sortedEvents = sortedEvents.concat(englishSeatAvailable.splice(0,remainingEventToReachLimit));
      }
    } else {
      return sortedEvents;
    }

    remainingEventToReachLimit = limit - sortedEvents.length;
    if(remainingEventToReachLimit > 0) {
      if(englishWaitlist.length <= remainingEventToReachLimit) {
        sortedEvents = sortedEvents.concat(englishWaitlist);
      } else {
        sortedEvents = sortedEvents.concat(englishWaitlist.splice(0,remainingEventToReachLimit));
      }
    } else {
      return sortedEvents;
    }
  } else {
    let spanishEvents = [];
    let englishEvents = [];
    let remainingEventToReachLimit = limit - sortedEvents.length;

    events.forEach((event) => {
      if(event.locale.indexOf("es-ES") != -1) {
        spanishEvents.push(event);
      } else {
        englishEvents.push(event);
      }
    });

    if(spanishEvents.length <= limit) {
      sortedEvents = sortedEvents.concat(spanishEvents);
    } else {
      sortedEvents = sortedEvents.concat(spanishEvents.splice(0,limit));
    }

    remainingEventToReachLimit = limit - sortedEvents.length;
    if(remainingEventToReachLimit > 0) {
      if(englishEvents.length <= remainingEventToReachLimit) {
        sortedEvents = sortedEvents.concat(englishEvents);
      } else {
        sortedEvents = sortedEvents.concat(englishEvents.splice(0,remainingEventToReachLimit));
      }
    } else {
      return sortedEvents;
    }
  }

  return sortedEvents;
}

const comingSoonEvents = (events, selectedTopicsOfInterest, language, isAuthenticatedUser, limit, recommendedEventList, upcomingEvents) => {
  
  switch (language) {
    case "en":
      return comingSoonEnglishEvents(events, selectedTopicsOfInterest, isAuthenticatedUser, limit, recommendedEventList, upcomingEvents);
    
    case "es":
      return comingSoonSpanishEvents(events, selectedTopicsOfInterest, isAuthenticatedUser, limit, upcomingEvents);
  }
}

const comingSoonEnglishEvents = (events, selectedTopicsOfInterest, isAuthenticatedUser, limit, recommendedEventList, upcomingEvents) => {
  let sortedEvents = [];
  let registeredEvents = [];
  let waitListedEvents = [];
  let sortedEventCourseId = [];
  let upcomingEventsWithoutAvailability = [];
 

  //Reusing recommendedEventList
  let sortedEventsLength = sortedEvents.length;
  if(!recommendedEventList || recommendedEventList.length == 0) {
    recommendedEventList = recommendedEvents(events, selectedTopicsOfInterest, "en", limit);
  }
  recommendedEventList.forEach((event) => {
    if(event.availableSeats > 0 || event.seatLimit == 0) {
      if(event.enrollmentDeadline == null || event.enrollmentDeadline >= new Date().getTime()) {
        sortedEventCourseId.push(event.id);
        sortedEvents = [...sortedEvents, event];
        sortedEventsLength = sortedEventsLength + 1;
      }
    } else {
      upcomingEventsWithoutAvailability.push(event);
    }
    if(sortedEventsLength >= limit)
      return sortedEvents;
  });

  //Upcoming events with open registration
  upcomingEvents.forEach((event) => {
    if(sortedEventCourseId.indexOf(event.id) == -1  && (event.availableSeats > 0 || event.seatLimit == 0)) {
      if(event.enrollmentDeadline == null || event.enrollmentDeadline >= new Date().getTime()) {
        sortedEventCourseId.push(event.id);
        sortedEvents = [...sortedEvents, event];
        sortedEventsLength = sortedEventsLength + 1;
      }
    } 
    if(sortedEventsLength >= limit)
      return sortedEvents;
  });

  //upcoming events full and availabe inidicator to add to waitlist
  console.log("sortedEvents.length", sortedEvents.length);
  console.log("upcomingEventsWithoutAvailability", upcomingEventsWithoutAvailability);
  if(upcomingEventsWithoutAvailability.length > 0)
    sortedEvents = sortedEvents.concat(upcomingEventsWithoutAvailability.splice(0, (limit - sortedEvents.length)));

  return sortedEvents;
}

const comingSoonSpanishEvents = (events, selectedTopicsOfInterest, isAuthenticatedUser, limit, upcomingEvents) => {
  let sortedEvents = [];
  let registeredEvents = [];
  let waitListedEvents = [];
  let spanishEventApproachingCapacityAndDeadline = [];
  let spanishEventApproachingCapacityAndDeadlineTOI = [];
  let englishEventApproachingCapacityAndDeadlineTOI = [];
  let englishEventApproachingCapacityAndDeadline = [];
  let spanishWLTOI = [];
  let spanishWL = [];
  let englishWLTOI =[];
  let englishWL = [];

 

  // event.enrollmentStatus == "NOT_ENROLLED" && event.availableSeats > 0) {
  //   if(event.enrollmentDeadline == null || event.enrollmentDeadline >= new Date().getTime()) {

  upcomingEvents.forEach((event) => {
    let matchesTOI = selectedTopicsOfInterest.some(r=> event.tags?.includes(r));
    if(event.locale.indexOf("es-ES") != -1) {
      // if(event.enrollmentStatus == "NOT_ENROLLED") {
        if(event.availableSeats > 0 || event.seatLimit == 0) {
          if(event.enrollmentDeadline == null || event.enrollmentDeadline >= new Date().getTime()) {
            if(matchesTOI) {
              spanishEventApproachingCapacityAndDeadlineTOI.push(event);
            } else {
              spanishEventApproachingCapacityAndDeadline.push(event);
            }
          } else {
            if(matchesTOI) {
              spanishEventApproachingCapacityAndDeadlineTOI.push(event);
            } else {
              spanishEventApproachingCapacityAndDeadline.push(event);
            }
          }
        } else {
          if(matchesTOI) {
            spanishWLTOI.push(event);
          } else {
            spanishWL.push(event);
          }
        }
      //}
    } else {
     // if(event.enrollmentStatus == "NOT_ENROLLED") {
        if(event.availableSeats > 0 || event.seatLimit == 0) {
          if(event.enrollmentDeadline == null || event.enrollmentDeadline >= new Date().getTime()) {
            if(matchesTOI) {
              englishEventApproachingCapacityAndDeadlineTOI.push(event);
            } else {
              englishEventApproachingCapacityAndDeadline.push(event);
            }
          } else {
            if(matchesTOI) {
              englishEventApproachingCapacityAndDeadlineTOI.push(event);
            } else {
              englishEventApproachingCapacityAndDeadline.push(event);
            }
          }
        } else {
          if(matchesTOI) {
            englishWLTOI.push(event);
          } else {
            englishWL.push(event);
          }
        }
      //}
    }
  });

  let remainingEventToReachLimit = limit - sortedEvents.length;
  if(remainingEventToReachLimit > 0) {
    if(spanishEventApproachingCapacityAndDeadlineTOI.length <= remainingEventToReachLimit) {
      sortedEvents = sortedEvents.concat(spanishEventApproachingCapacityAndDeadlineTOI);
    } else {
      sortedEvents = sortedEvents.concat(spanishEventApproachingCapacityAndDeadlineTOI.splice(0,remainingEventToReachLimit));
    }
  } else {
    return sortedEvents;
  }

  remainingEventToReachLimit = limit - sortedEvents.length;
  if(remainingEventToReachLimit > 0) {
    if(spanishEventApproachingCapacityAndDeadline.length <= remainingEventToReachLimit) {
      sortedEvents = sortedEvents.concat(spanishEventApproachingCapacityAndDeadline);
    } else {
      sortedEvents = sortedEvents.concat(spanishEventApproachingCapacityAndDeadline.splice(0,remainingEventToReachLimit));
    }
  } else {
    return sortedEvents;
  }

  remainingEventToReachLimit = limit - sortedEvents.length;
  if(remainingEventToReachLimit > 0) {
    if(englishEventApproachingCapacityAndDeadlineTOI.length <= remainingEventToReachLimit) {
      sortedEvents = sortedEvents.concat(englishEventApproachingCapacityAndDeadlineTOI);
    } else {
      sortedEvents = sortedEvents.concat(englishEventApproachingCapacityAndDeadlineTOI.splice(0,remainingEventToReachLimit));
    }
  } else {
    return sortedEvents;
  }

  remainingEventToReachLimit = limit - sortedEvents.length;
  if(remainingEventToReachLimit > 0) {
    if(spanishWLTOI.length <= remainingEventToReachLimit) {
      sortedEvents = sortedEvents.concat(spanishWLTOI);
    } else {
      sortedEvents = sortedEvents.concat(spanishWLTOI.splice(0,remainingEventToReachLimit));
    }
  } else {
    return sortedEvents;
  }

  remainingEventToReachLimit = limit - sortedEvents.length;
  if(remainingEventToReachLimit > 0) {
    if(spanishWL.length <= remainingEventToReachLimit) {
      sortedEvents = sortedEvents.concat(spanishWL);
    } else {
      sortedEvents = sortedEvents.concat(spanishWL.splice(0,remainingEventToReachLimit));
    }
  } else {
    return sortedEvents;
  }

  remainingEventToReachLimit = limit - sortedEvents.length;
  if(remainingEventToReachLimit > 0) {
    if(englishWLTOI.length <= remainingEventToReachLimit) {
      sortedEvents = sortedEvents.concat(englishWLTOI);
    } else {
      sortedEvents = sortedEvents.concat(englishWLTOI.splice(0,remainingEventToReachLimit));
    }
  } else {
    return sortedEvents;
  }
  
  return sortedEvents;
}

const filterCategories = (collections, filters) => {
  let filteredCollections = [];
  let startDateFilter = filters.dates?.startDate;
  let endDateFilter =filters.dates?.endDate;
  console.log("filterEvents", filters);
  console.log("collections in filterCategories", collections);

  collections.forEach(collection => {
    //Topics Filter
    if(filters.selectedTopicsOfInterest.indexOf("View all") == -1 && filters.selectedTopicsOfInterest.length > 0 && !filters.selectedTopicsOfInterest.some(r=> collection.tags?.includes(r))) {
      return;
    }

    //date filter
    if(startDateFilter && endDateFilter && collection.startDate < startDateFilter && collection.endDate > endDateFilter) {
        return;
    } else if(startDateFilter && collection.startDate < startDateFilter) {
      return;
    } else if(endDateFilter && collection.endDate > endDateFilter) {
        return;
    }

    //Status Filter
    if(filters.statuses?.length > 0) {
      if(collection.format == "Event") {
        let keepThisEvent = false;
        if(filters.statuses.indexOf("availableSeats") != -1 && collection.availableSeats > 0) {
          keepThisEvent = true;
        } 
        if(filters.statuses.indexOf("waitingAvailable") != -1 && collection.availableSeats <= 0 && !keepThisEvent) {
          keepThisEvent = true;
        } 
        if(filters.statuses.indexOf(collection.enrollmentStatus) != -1 && !keepThisEvent) {
          keepThisEvent = true;
        }
        if(filters.statuses.indexOf('View all') != -1) {
          keepThisEvent = true;
        }
        if(!keepThisEvent)
          return;
      } else {
      if(filters.statuses.indexOf(collection.enrollmentStatus) == -1 && filters.statuses.indexOf('View all') == -1) {
        return;
      }
    }
    }

    //Type check
    if(filters.type.length > 0) {
      if(collection.format == "Quick Tip") {
        if(filters.type.indexOf(collection.resourceType) == -1 && filters.type.indexOf('View all') == -1)
          return;
      } else if (!filters.type.some(r=> collection.tags?.includes(r)) && filters.type.indexOf('View all') == -1){
        return;
      }
    }
    
    //Format Check
    if(filters.format?.length > 0) {
      if(filters.format.indexOf(collection.format) == -1) {
          return;
      }
    }

    //Skill Level
    if(filters.skillLevel?.length > 0 && filters.skillLevel?.indexOf('View all') == -1 && !filters.skillLevel?.some(r=> collection.skillLevels?.includes(r)))
    return;

    //Langauage Filter
    if(filters.language.length > 0 && !filters.language.some(r=> collection.locale.includes(r)))
      return;

    filteredCollections.push(collection);
  });  

  console.log("filteredCollections", filteredCollections);
  
  return filteredCollections;
}

const createFilterInputForUtil = (filterData, page) => {
  let filterInputForUtil = {
    selectedTopicsOfInterest: [],
    dates: {},
    statuses: [],
    type: [],
    format: [],
    language: [],
    skillLevel: []
  }
  filterData.forEach((filter) => {
    switch(filter.category){
      case "Topic":
        filter?.subCategory?.forEach((item) => {
          item.options.forEach((internalItem) => {
            if(internalItem.selected) {
              filterInputForUtil.selectedTopicsOfInterest.push(internalItem.value);
            }
          });
        });
        break;
      case "Dates":
        if(filter.startDate)
          filterInputForUtil.dates.startDate = filter.startDate;
        if(filter.endDate)
          filterInputForUtil.dates.endDate = filter.endDate;
        break;
      case "Status":
        filter?.subCategory?.forEach((subcat) => {
          subcat.options.forEach((item) => {
            if(item.selected || subcat.options[0].selected) {
              filterInputForUtil.statuses.push(item.value);
            }
          });
        });
        break;
      case "Type":
        if(page == 'myLibrary') {
          filter?.subCategory?.forEach((subcat) => {
            subcat.options.forEach((item) => {
              if(item.selected || subcat.options[0].selected) {
                filterInputForUtil.format.push(item.value);
              }
            });
          });
        } else {
        filter?.subCategory?.forEach((subcat) => {
          subcat.options.forEach((item) => {
            if(item.selected || subcat.options[0].selected) {
              filterInputForUtil.type.push(item.value);
            }
          });
        });
        }
        break;
      case "Skill level":
        filter?.subCategory?.forEach((subcat) => {
          subcat.options.forEach((item) => {
            if(item.selected || subcat.options[0].selected) {
              filterInputForUtil.skillLevel.push(item.value);
            }
          });
        });
        break;
      case "Language":
        filter?.subCategory?.forEach((subcat) => {
          subcat.options.forEach((item) => {
            if(item.selected || subcat.options[0].selected) {
              filterInputForUtil.language.push(item.value);
            }
          });
        });
        break;
    }
  });
  return filterInputForUtil;
}

const mapperForCourseCardDesktop = (collection) => {
  switch (collection.format) {
    case "Event":
      if(Object.keys(collection.additionalStatus).length > 0) {
        return ({
          desktopImage: collection.image,
          label: collection.title,
          duration: { 
            time: collection.additionalStatus.tagName, 
            color: collection.additionalStatus.color, 
            icon: collection.additionalStatus.tagIcon, 
            preTextIcon: collection.additionalStatus.registeredIcon, 
            preText: collection.duration.tileViewTime
          },
          resourceType: collection.type,
          format: collection.format,
          tag:collection.tag,
          showBookmarkIcon: false,
          courseId: collection.id,
          startDate: collection.startDate,
          endDate: collection.endDate,
        });
      } else {
        return ({
          desktopImage: collection.image,
          label: collection.title,
          duration: { 
            time: collection.duration.dateTime, 
            color: collection.duration.color, 
            icon: 'calendar' 
          },
          resourceType: collection.type,
          format: collection.format,
          tag:collection.tag,
          showBookmarkIcon: false,
          courseId: collection.id
        });
      }
    case "Course":
      return ({
        desktopImage: collection.image,
        label: collection.title,
        duration: { 
          time: collection.completedDuration ? collection.completedDuration: collection.duration, 
          color: collection.timeColor? collection.timeColor: DEFAULT_COLOR, 
          icon: collection.icon
        },
        resourceType: collection.type,
        format: collection.format,
        tag:collection.tag,
        showBookmarkIcon: false,
        courseId: collection.id
      });
    case "Quick Tip":
      const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
      let title = collection.label;
      if(userLanguage && userLanguage == 'es') {
        title = collection.labelSpanish ? collection.labelSpanish : collection.label;
      }
      let tag="";
      if (collection.locale.indexOf('es-ES') != -1 && userLanguage === "es"){
        tag= TAG_SPANISH;
      }
      return ({
        desktopImage: collection.desktopImage,
        label: title,
        duration: { 
          time: collection.dateAndTimeValue, 
          color: DEFAULT_COLOR, 
          icon: 'count-down'
        },
        resourceType: collection.resourceType,
        format: collection.format,
        tag:tag,
        showBookmarkIcon: false,
        courseId: collection.courseId
      });
  }
}
 const loadQuickTips=(language)=> {
  switch (language) {
    case "en":
      console.log("quickTip_en", quickTip_en);
      return quickTip_en;
    case "es":
      return quickTip_es;
  }
}

const forcedRankingOffers = (offersData) => {
  const forcedRanking = offersData?.forcedRanking;
  const rawOffers = offersData?.offers;
  var orderedInterests = Object.keys(forcedRanking);
  let sortedresults = [];
  let interestIndex = 0, forcedRankIndex = 0, completedIndex = 0;
  while (completedIndex < orderedInterests?.length) {
    const tempObj = forcedRanking?.[orderedInterests[interestIndex]];
    if (tempObj.length > forcedRankIndex) {
      var offerObject = rawOffers[0]?.[tempObj[forcedRankIndex]];
      if (sortedresults.filter((item) => item == offerObject).length == 0) {
        sortedresults.push(offerObject);
      }
    } else if(tempObj?.length >0 ) {
      completedIndex++;
    }
    if (interestIndex < (orderedInterests.length - 1)) {
      interestIndex++;
    }
    else {
      interestIndex = 0;
      forcedRankIndex++;
    }
  }
  return sortedresults;
}

const sortArrayByName = (unSortedArray) => {
  return unSortedArray.sort((a, b) => {
      const nameA = a.label.toUpperCase(); // ignore upper and lowercase
      const nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    });
}

const sortLearningPathList = (learningPaths, userLanguage) => {
  //filter in progress and completed
  let defaultStateLP = learningPaths;

  switch (userLanguage) {
    case "en":
      defaultStateLP = defaultStateLP.sort((a,b) => new Date(b.datePublished) - new Date(a.datePublished));
      break;
    case "es":
      let defaultStateLPSpanish = [];
      let defaultStateLPEnglish = [];
      defaultStateLP.forEach((e) => {
        if(e.locale.indexOf('es-ES') != -1) {
          defaultStateLPSpanish.push(e);
        } else {
          defaultStateLPEnglish.push(e);
        }
      });
      defaultStateLPSpanish = defaultStateLPSpanish.sort((a,b) => new Date(b.datePublished) - new Date(a.datePublished));
      defaultStateLPEnglish = defaultStateLPEnglish.sort((a,b) => new Date(b.datePublished) - new Date(a.datePublished));
      defaultStateLP = defaultStateLPSpanish.concat(defaultStateLPEnglish);
      break;
  }

  return defaultStateLP;
}

function LabelsFormat(str){
  let label=str.toLowerCase()
  return label.charAt(0).toUpperCase() + label.slice(1)
}
const getLanguageBasedHref = () => {
  let languageBasedHref = null;
  let locationHref = location.pathname;
  let currentLang = localStorage.getItem("lng") ? localStorage.getItem("lng")  : "en";
  // if(localStorage.getItem('languageSwitchHref')) {
  //   locationHref = localStorage.getItem('languageSwitchHref');
  //   localStorage.removeItem('languageSwitchHref');
  // }
  
  if(currentLang == "es" && locationHref.indexOf("/es") == -1) {
    languageBasedHref = `/${currentLang}${locationHref}`;
  } else if (currentLang == "en" && locationHref.indexOf("/es") != -1){
    languageBasedHref = locationHref.replace("/es","");
  }
  return languageBasedHref;
}

export {
    TAG_RECORDED_EVENTS,
    compareCurrentTimeInSeconds,
    getCookieValue,
    setWithExpiry,
    getWithExpiry,
    createEventObject,
    getSuggestedCourses,
    recommendedEvents,
    comingSoonEvents,
    getSuggestedCoursesbyTopic,
    comingSoonEnglishEvents,
    filterCategories,
    createFilterInputForUtil,
    createCourseObject,    
    mapperForCourseCardDesktop,
    loadQuickTips,
    forcedRankingOffers,
    forcedRankingOffersTopics,
    createLearningPathObject,
    topicsCollection,
    sortArrayByName,
    sortLearningPathList,
    LabelsFormat,
    getLanguageBasedHref
};
