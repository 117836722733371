import React , { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ScrollTo = () => {
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if(searchParams.get("scrollto") && searchParams.get("scrollto") != "") {
            let scrollInterval = setInterval(function() {
                // code to be executed repeatedly
                if(document.getElementById(searchParams.get("scrollto")) && document.getElementById(searchParams.get("scrollto")) != null) {
                    document.getElementById(searchParams.get("scrollto")).scrollIntoView({ block: 'end' });
                    clearInterval(scrollInterval);
                }
            }, 2500);
        } else {
            window.scrollTo({ top: 0});
        }
    });
};

export default ScrollTo;