// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.learningPathCoverBg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top !important;
}`, "",{"version":3,"sources":["webpack://./src/elements/ImageHeader.scss"],"names":[],"mappings":"AAAA;EACI,uCAAA;EACA,iCAAA;EACA,mCAAA;AACJ","sourcesContent":[".learningPathCoverBg{\n    background-repeat: no-repeat !important;\n    background-size: cover !important;\n    background-position: top !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
