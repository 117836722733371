import React from 'react';
import './Networking.scss';
import BreadCrumbs from '../components/Networking/Breadcrumbs/Breadcrumbs.jsx';
import Hero from '../components/Networking/Hero/Hero.jsx';
import SecondaryContent from '../components/Networking/SecondaryContent/SecondaryContent.jsx';
import CommunityEvents from '../components/Networking/CommunityEvents/CommunityEvents.jsx';
import Spotlight from '../components/Networking/Spotlight/Spotlight.jsx';
import Guidelines from '../components/Networking/Guidelines/Guidelines.jsx';
import { useEffect, useState } from 'react';
import { useSelector} from 'react-redux'
import { retrieveNetworking } from '../utility/common.js';
import { pageLoadStarted,pageLoadCompleted } from '../common/Analytics/GAWrapper.jsx';

const Networking = () => {
  const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
  const allEventsWithTimeZone = useSelector((state) => state.AdobeResources.allEventsWithTimeZone);
  const [content, setContent] = useState()
  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );
  
  useEffect(() => {
    retrieveNetworking(userLanguage).then((networkingData) => {
      setContent(networkingData);
    });
    console.log("location",localStorage.getItem('prevUrl'))

    pageLoadStarted("Networking",window.location.origin + localStorage.getItem('prevUrl'),selectedTopics?.toString());
    pageLoadCompleted();
  },[]);
  

  return (<div className="app-networking-page bg-white">
    {content && 
    <>
      {/*<BreadCrumbs props= {content.breadCrumbs}/>*/}
      <div className='pt-16X sm-pt-12X mt-4X sm-mt-0X'></div>
      <Hero props= {content.heroContent}/>
      <SecondaryContent props= {content.secondaryContent}/>
      {allEventsWithTimeZone && <CommunityEvents props={content.communityEvents}/>}
      <Spotlight props={content.spotlight}/>
      <Guidelines props={content.guidelines}/>
      </>
}
  </div>
  )
}

export default Networking;