import { ButtonGroup, TextLink, TextLinkCaret } from '@vds/buttons';
import { Title } from '@vds/typography';
import { Tooltip } from '@vds/tooltips';

import React from 'react';
import { Icon } from '@vds/icons';
import { isMobile } from 'react-device-detect';
import { LabelsFormat } from '../../utility/utils';

const CategoryHeadersComponent = ({ props }) => {
  console.log(props);
  return (
    <div className='pv-8X u-flex-apart pb-16X m-flex-col pt-16X mt-4X sm-mt-0X'>
      <div className='maxw-808'>
        {(props.sideIcon?.name && isMobile) && (
          <div className='sm-mb-4X'>
            <Icon
              name={props.sideIcon?.name}
              size={props.sideIcon?.size}
              color={props.sideIcon?.color}
            />
          </div>
        )}
        <Title bold={true} size='2XLarge'>{LabelsFormat(props.label)}</Title>
        <div className='mt-4X'>
          {props.subText && (
            <Title size='medium'>
              {props.subText}
            </Title>)}
          {props.textLink?.label && (<div className='mt-4X u-flex-left'>
            <TextLink
              type="standAlone"
              onClick={e => alert('TextLink clicked')}>
              {props.textLink?.label}
            </TextLink>
            {!!props.textLink?.tooltipText}
            {!!props.textLink?.tooltipText && (
              <Tooltip
                size="medium"
                surface="light">
                {props.textLink?.tooltipText}{!!props.textLink?.tooltipText}
              </Tooltip>
            )}


          </div>)}
          {props.buttons && (
            <div className='cta-area mt-8X'>
              <ButtonGroup
                childWidth={'100%'}
                viewport={'desktop'}
                rowQuantity={{ desktop: 2 }}
                data={props.buttons}
                alignment={'left'}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        {props.sideTextLink?.label && (
          <TextLinkCaret
            surface="light"
            disabled={false}
            iconPosition="right"
            onClick={e => alert('TextLinkCaret clicked')}>
            {props.sideTextLink?.label}
          </TextLinkCaret>
        )}
        {(props.sideIcon?.name && !isMobile) && (
          <Icon
            name={props.sideIcon?.name}
            size={props.sideIcon?.size}
            color={props.sideIcon?.color}
          />
        )}
      </div>

    </div>
  );
};

// class QuickTipsCategoryComponent extends React.Component {
//   render() {
//     return (
//       <div>qtc</div>
//     );
//   }
// }
export default CategoryHeadersComponent;
