import React, { useState, useRef, useEffect } from 'react';
import './seeMore.scss';
import { Icon } from '@vds/icons';
import { useTranslation } from 'react-i18next';

const SeeMore = ({ children, initialHeight }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { t, i18n } = useTranslation();

  const handleSeeMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  const style = isExpanded ? {} : { maxHeight: initialHeight, overflow: 'hidden' };
  // let styleLay = ;





  return (
    <div className='see-more pb-12X'>
      <div style={style} className='content'>{children}
      {!isExpanded && <div className="seemore-back"></div>}
      
      </div>
      <div className='action'>
        {!isExpanded &&
          <div><span className='pr-0X'><Icon name='plus'></Icon></span><button onClick={handleSeeMoreClick}>{t("Show more")}</button></div>}
        {isExpanded && <div><span className='pr-0X'><Icon name='minus'></Icon></span><button onClick={handleSeeMoreClick}>{t("Show Less")}</button></div>}
      </div>

    </div>
  );
};

export default SeeMore;