import { ButtonGroup, TextLink, TextLinkCaret } from '@vds/buttons';
import { Body, Title } from '@vds/typography';
import { Tooltip } from '@vds/tooltips';

import React from 'react';
import { Icon } from '@vds/icons';
import { isMobile } from 'react-device-detect';
import { Col, Grid, Row } from '@vds/grids';
import { useTranslation } from 'react-i18next';

import './ImageHeader.scss'; 

const ImageHeadersComponent = ({ props }) => {
  const { t, i18n } = useTranslation();
  console.log('header data', props);
  // let imgUrl = 'https://s3-alpha-sig.figma.com/img/b977/e847/057f954cdc3447f228081cae6261ced9?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Iky4xjgWAza3yGMY8lf9nmEk-iK5bj~wAJfjZIfLnZ4xwPgkDbutfbiI5Y6ddZd0qmqXYkvJPx53FgEA8Tr8vJ95Yr7Bt3GFnlKCE7LnHEl8uzYmUU71L44Jm1YmxEkbt6pj8hcSLznW9izJisQ9dn7IY4WGwTsJPFm9Ajs~5A03GhzzugMswf~dG7NaLLzm0cM3wSOiEpGhz6NRbHoW9V6xsKgSYbH~cbBNd8QTMv5CGAFLGyR34ozyCIsePFS9~S0n7MFPH6PwB-UD4h3zhR5~sPOqCQghaWpV8c7iK3HlcusmX1fEj7EkTbR4MlRAQh-rImTcxSdd8PG6Na9~UQ__';
  // 'https://s3-alpha-sig.figma.com/img/2bcc/fe58/ba42358b65e3d825e065334eac8b78fe?Expires=1709510400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Loj8Cy2Fa5mp5ncYk6I5zPqZUjKM0AlC04IQk04XVE99YDIbQvAP4VSYFGRoYzaIOkYqq6zkEvbN2v1mal-dMAnXj9a1tkGzVks5r66WpxpasyAeTq-qbwixHGgMR-kXAY~p7uQWcMt99Le1olQUDWHNmPp~YzCY80Nl4wUmF-ng0ScSKtIM1DuAvlhzxC7JP-4SPOPW9B0COrN3Idjws40dyVhgVV6xCFs2GCAsTYYFCxdkEjHODE0a-Kr3X6ttJaPVN8Z7qqJU-HQ0xnP4XIhWD84qMGZ~IWoSpCjWbo9-dOMwITyD9VfN76qpqfQt4hJjahNaB0mOplMXqGhx-w__';
  let bgStyle = {
    // 'background': ` url(` + imgUrl + `)`,
    background: `linear-gradient(0deg, rgba(246, 246, 246, 0.50) 0%, rgba(246, 246, 246, 0.50) 100%), linear-gradient(90deg, #FFF 24.72%, rgba(255, 255, 255, 0.00) 97.08%), url(${props?.imageUrl}), lightgray 273.519px -239.75px / 114.2% 228.281% no-repeat`,
    backgroundBlendMode: 'screen, normal, normal',
    backgroundSize: !isMobile ? 'cover' : 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <div className='car pv-24X u-flex-apart pb-16X m-flex-col pt-16X sm-pt-12X sm-pb-12X learningPathCoverBg' style={bgStyle}>
      <Grid
        bleed="1440"
        colSizes={{
          mobile: 4,
          tablet: 12,
          desktop: 12,
        }}
      >
        <Row>
          <Col>
            <div className='maxw-808'>
              {(props.sideIcon?.name && isMobile) && (
                <div className='sm-mb-4X'>
                  <Icon
                    name={props.sideIcon?.name}
                    size={props.sideIcon?.size}
                    color={props.sideIcon?.color}
                  />
                </div>
              )}
              <div className='mb-3X'>
                <Body size='large' bold>{t("New!")}</Body>
              </div>
              <Title primitive='h1' bold={true} size='2XLarge'>
                {props?.label}
                {props?.label1?.length > 0 && <> <br/>
                   {props?.label1}
                </>}
              </Title>
              <div className='mt-4X'>
                {props.subText && (
                  <Body size='large'>
                    {props.subText}
                  </Body>)}
                {props.textLink?.label && (<div className='mt-4X u-flex-left'>
                  <TextLink
                    type="standAlone"
                    onClick={e => alert('TextLink clicked')}>
                    {props.textLink?.label}
                  </TextLink>
                  {!!props.textLink?.tooltipText}
                  {!!props.textLink?.tooltipText && (
                    <Tooltip
                      size="medium"
                      surface="light">
                      {props.textLink?.tooltipText}{!!props.textLink?.tooltipText}
                    </Tooltip>
                  )}


                </div>)}
                {props.buttons.length > 0 && (
                  <div className='cta-area mt-8X'>
                    <ButtonGroup
                      childWidth={'100%'}
                      viewport={'desktop'}
                      rowQuantity={{ desktop: 2 }}
                      data={props.buttons}
                      alignment={'left'}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              {props.sideTextLink?.label && (
                <TextLinkCaret
                  surface="light"
                  disabled={false}
                  iconPosition="right"
                  onClick={e => alert('TextLinkCaret clicked')}>
                  {props.sideTextLink?.label}
                </TextLinkCaret>
              )}
              {(props.sideIcon?.name && !isMobile) && (
                <Icon
                  name={props.sideIcon?.name}
                  size={props.sideIcon?.size}
                  color={props.sideIcon?.color}
                />
              )}
            </div>

          </Col>
        </Row>
      </Grid>

    </div>
  );
};

export default ImageHeadersComponent;
