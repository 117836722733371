/** @format */
import Moment from 'moment';
// import DOMPurify from 'dompurify';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { Carousel } from '@vds/carousels';
import OffersCardComponent from '../components/Category/Offers/OffersCard.jsx';

import { Col, Grid, Row } from '@vds/grids';
import { Button, ButtonGroup , TextLink} from '@vds/buttons';
import { Icon } from '@vds/icons';
import { Tabs, Tab } from '@vds/tabs';


import { Notification } from '@vds/notifications';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { Body, Micro, Title } from '@vds/typography';
import { useTranslation } from 'react-i18next';
import { pageLoadCompleted, pageLoadStarted } from '../common/Analytics/GAWrapper.jsx';
import CourseContentComponent from '../components/CourseContentComponent.jsx';
import CourseContentHeaderComponent from '../components/CourseContentHeaderComponent.jsx';
import StartCourseComponent from '../components/StartCourseComponent.jsx';
import CoursePlayerComponent from '../elements/CoursePlayerComponent.jsx';
import TagBasedCoursesComponent from '../elements/TagBasedCoursesComponent.jsx';
import './CourseContentPage.scss';
import Footer from '../layout/Footer.jsx';
import { forcedRankingOffersTopics } from '../utility/utils.js'
import { cloneDeep } from 'lodash';
import CourseCardComponent from '../elements/CourseCardComponent.jsx';
import LearningPathCard from '../components/LearningPath/LerningPathCard.jsx';
import ResourcesStandard from './ResourceStandard.jsx';
import { useNavigate, useNavigationType } from 'react-router';
import { getWithExpiry, sortLearningPathList } from '../utility/utils.js'
import { Line } from '@vds/lines';
import  PreviewCourseContent  from '../components/common/preview.jsx'
import  SeeMore  from '../components/common/seeMore.jsx'
import  ResourcesOverview  from '../components/common/resoursesOverview.jsx'


// import offersMock from '../components/Category/Offers/offersmock.json'

const CourseOverview = ({ allQuickTips, quickTips, offersMock, aemData }) => {
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  // const aemData = useSelector((state) => state.Aem.data);
  // const offersMock = useSelector((state) => state.Aem.offerData);
  // const quickTips = useSelector((state) => state.Aem.quickTipData);
  // const allQuickTips = useSelector((state) => state.AdobeResources.allQuickTips);
  const registerAemData = useSelector((state) => state.Aem.registerData);
  const courseCx = useSelector((state) => state.Aem.courseCx);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const minifiedCatalog = useSelector(
    (state) => state.AdobeResources.catalogData
  );
  let { courseId } = useParams();
  const [isCopied, setIsCopied] = useState(false);
  const [ifLearningPath, setIfLearningPath] = useState(false);
  const [courseInLearningPath, setCourseInLearningPath] = useState([]);
  const [learningPathObj, setLearningPathObj] = useState(null);
  const [dynamicBreadCrumb, setDynamicBreadcrumb] = useState(null);
  const [moduleResource, setModuleResource] = useState([]);
  const [lpDetails, setLpDetails] = useState({ lpResources: [], noofResoources: 0, duration: 0 })
  let suggestion = getWithExpiry('suggestion');
  const learningPaths = useSelector((state) => state.AdobeResources.learningPaths);
  const isLearningPath = window.location.pathname.indexOf('learningProgram') !== -1 ? true : false;
  const isCourseDetailPage = window.location.pathname.indexOf('course:') !== -1 ? true : false;
  const allEventsWithTimeZone = useSelector((state) => state.AdobeResources.allEventsWithTimeZone);
  var coursetitle = minifiedCatalog.find((course) => course.id == courseId);
  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );

  const navigationType = useNavigationType();
  Moment.locale('en');
  useEffect(() => {
    window.scrollTo({ top: 0 });
    setIfLearningPath(window.location.pathname.indexOf('learning-details') ? true : false);
  }, []);

  useEffect(() => {
    const url = location.pathname.split('/');
    if (url.length > 2 && url[2].includes('learningProgram')) {
      const lpObj = {
        'lpId': suggestion?.learningProgram[url[2]]?.id,
        'lpTitle': getEnEsTitles(suggestion?.learningProgram[url[2]]?.localizeData)
      }
      localStorage.setItem('learningPathObj', JSON.stringify(lpObj))
    }
  }, [location.pathname])

  useEffect(() => {
    let urlsArr = [];
    if (sessionStorage.getItem('urlNavigated')) {
      urlsArr = JSON.parse(sessionStorage.getItem('urlNavigated'))
    }
    let action = navigationType;
    if (action === "PUSH" ||
      (action === "POP" && window?.history?.state?.idx === 0 && urlsArr[urlsArr?.length - 1] !== location?.pathname)) {
      urlsArr.push(location.pathname);
      sessionStorage.setItem('urlNavigated', JSON.stringify(urlsArr));
      fetchLpObj(urlsArr);
    }
  }, [location.pathname]);

  useEffect(() => {
    const navigationEntries = window.performance.getEntriesByType('navigation');
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
      fetchLpObj(JSON.parse(sessionStorage.getItem('urlNavigated')));
    }
  }, []);
  const fetchLpObj = (urlsArr) => {
    if (urlsArr && urlsArr[urlsArr?.length - 2]?.includes('learningProgram')) {
      const lpUrl = urlsArr[urlsArr.length - 2]?.split('/');
      const lpObj = {
        'lpId': suggestion?.learningProgram[lpUrl[2]]?.id,
        'lpTitle': getEnEsTitles(suggestion?.learningProgram[lpUrl[2]]?.localizeData)
      };
      setLearningPathObj(lpObj);
    }
    else {
      setLearningPathObj(null);
    }
  }

  const getEnEsTitles = (titleArr) => {
    const lng = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
    let title = titleArr?.find((item) => item.locale == 'en-US') ? titleArr?.find((item) => item.locale == 'en-US').name : '';
    if (lng === 'es' && titleArr?.find((item) => item.locale == 'es-ES')) {
      title = titleArr?.find((item) => item.locale == 'es-ES').name;
    }
    return title;
  }

  useEffect(() => {
    let learningProgramArr = [];

    for (let item in suggestion?.learningProgram) {
      const learningProgramItem = suggestion?.learningProgram[item];

      if (learningProgramItem?.listOfResources[0]?.loIds.includes(courseId)) {
        let lng = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
        let title = learningProgramItem.localizeData.find((item) => item.locale == 'en-US') ? learningProgramItem.localizeData.find((item) => item.locale == 'en-US').name : '';
        if (lng === 'es' && learningProgramItem.localizeData.find((item) => item.locale == 'es-ES')) {
          title = learningProgramItem.localizeData.find((item) => item.locale == 'es-ES').name;
        }

        learningProgramArr.push({
          icon: { name: 'timer', color: '#6f7171', label: t('Get Started') },
          topic: title,
          lpId: learningProgramItem?.id,
          viewFullPath: true,
          onClickViewFullPath: function () {
            localStorage.setItem('categoryPath', '/category/learningpath')
            navigate(`/learning-details/${learningProgramItem?.id}`)
          }
        });
      }
    }
    let carouselCard = learningProgramArr.slice(0, 3);
    if (learningPathObj) {
      const lpNavigated = carouselCard?.filter(e => e?.lpId === learningPathObj?.lpId);
      if (lpNavigated.length > 0) {
        const index = carouselCard.indexOf(lpNavigated[0]);
        if (index !== 0) {
          let swapVal = carouselCard[0];
          carouselCard[0] = lpNavigated[0];
          carouselCard[index] = swapVal;
        }
      } else if (lpNavigated.length === 0) {
        carouselCard[0] = learningProgramArr?.filter(e => e?.lpId === learningPathObj?.lpId)[0];
      }
    }

    setCourseInLearningPath(carouselCard.filter(e => e !== undefined));
    if (isLearningPath) {
      var resourcesInLP = [];
      var lpDuration = 0;
      course?.instances?.map((resources) => {
        let tempEvent = minifiedCatalog?.find((event) => event?.id == resources?.id)
        if (tempEvent != undefined) {
          resourcesInLP.push(tempEvent);
        }
      });
      resourcesInLP?.map((resource) => {
        lpDuration += Math.ceil(resource?.duration / 60);
      });
      setLpDetails({ lpResources: resourcesInLP, noofResoources: resourcesInLP?.length, duration: lpDuration });
    }
  }, [courseId, learningPathObj]);

  useEffect(() => {
    if (!selectedCourse) {
      pageLoadStarted(coursetitle.title, window.location.origin + localStorage.getItem("prevUrl"), selectedTopics?.toString())
      pageLoadCompleted();
    }
    else {
      pageLoadStarted(selectedCourse?.label, window.location.origin + localStorage.getItem("prevUrl"), selectedTopics?.toString())
      pageLoadCompleted();
    }
  }, [selectedCourse, courseId]);
  const {
    labels: {
      courseDetailsPage: {
        headerBackLinkLabel,
        registerSecTitle_Tips,
        registerSecTitle_Course,
        registerSecTitle_Event,
        registerSecDesc,
        registerButtonLabel,
        alreadyMemberLabel,
        signInLabel,
        topicsLabel,
        meetTheExpertsLabel,
        mayBeInterestedIn,
        relatedOffers
      },
    },
    TipsAndResourcesSectionData: { courseDetails },
  } = aemData;

  var selectedCourse = Object.values(quickTips) ? Object.values(quickTips).find((item) => item.courseId === courseId) : [];
  if (!selectedCourse) {
    var selectedCourse = Object.values(allQuickTips) ? Object.values(allQuickTips).find((item) => item.courseId === courseId) : [];
  }
  // courseDetails.find(
  //   (course) => course.courseId == courseId
  // );
  var langType = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
  var listOfLoIds = [];
  //checking adobe data
  if (!selectedCourse) {
    var course = minifiedCatalog.find((course) => course.id == courseId);
    if (!course && isLearningPath) { course = suggestion?.learningProgram[courseId] }
    // const courseIdTemp = course.id.split(':').length === 2 ? course.id.split(':')[1] : '';

    if (isLearningPath && course?.listOfResources) {
      listOfLoIds = course.listOfResources[0].loIds.map((item) => {
        let eventDetails;
        if (suggestion?.events[item]) {
          eventDetails = suggestion?.events[item];
        } else if (suggestion?.courses[item]) {
          eventDetails = suggestion?.courses[item];
        }
        return eventDetails;

      })
    }

    const getLPtimertext = (courseDuration) => {
      var hrs = 0;
      if (courseDuration > 60) {
        hrs = Math.floor(courseDuration / 60);
      }
      var mins = Math.ceil((courseDuration - (hrs * 60)));
      var timeText = '';
      if (hrs > 0) {
        timeText = hrs + " " + (hrs == 1 ? t('hour') : t('hours')) + " ";
      }
      if (mins > 0) {
        timeText += mins + " " + (mins == 1 ? t('minute') : t('minutes'));
      }
      return timeText;
    }

    //Fix for SBDR-174 - If the user has already bookmarked the event and the event is over or expired then the user should be directed to the landing page.
    if (!course) {
      location.href = "/";
    }

    const shareLink = location.href;//course.launchUrl;
    let nowTime = new Date();
    let courseLangDesc = course.localizeData.find((item) => item.locale == 'en-US') ? course.localizeData.find((item) => item.locale == 'en-US').richTextOverview : ''
    var title = course.localizeData.find((item) => item.locale == 'en-US') ? course.localizeData.find((item) => item.locale == 'en-US').name : ''
    var lPDescription = course.localizeData.find((item) => item.locale == 'en-US') ? course.localizeData.find((item) => item.locale == 'en-US').description : '';

    if (langType === 'es' && course.localizeData.find((item) => item.locale == 'es-ES')) {
      courseLangDesc = course.localizeData.find((item) => item.locale == 'es-ES').richTextOverview;
      title = course.localizeData.find((item) => item.locale == 'es-ES').name;
      lPDescription = course.localizeData.find((item) => item.locale == 'es-ES').description;
    }
    selectedCourse = {
      resourceInfoTag: course.isNew ? 'New' : '',
      resourceType: course.type,
      resourceTags: course.format,
      label: title,
      dateAndTimeIcon: 'calender',
      dateAndTimeValue: course.completionDeadline,
      courseId: course.id,
      icons: [
        course.dateStart != null
          ? {
            icon: 'calendar',
            label: Moment(course.dateStart).locale(i18n.language).format('MMMM DD, h:mm A'),
          }
          : {},
        isLearningPath
          ? {
            icon: 'reminder',
            label:
              (isLearningPath ? lpDetails?.noofResoources : course.listOfResources[0].loIds.length) + t(' resources'),
          }
          : course.enrollmentDeadline != null
            ? {
              icon: 'reminder',
              label:
                t('Deadline to register: ') +
                Moment(course.enrollmentDeadline).format('MMMM DD, h:mm A'),
            }
            : {},
        course.duration > 0 ? { icon: 'clock', label: (isLearningPath ? getLPtimertext(lpDetails?.duration) : ((Math.ceil(course.duration / 60))) + " " + t('minutes')) } : '',
        course.desiredDuration > 0
          ? {
            icon: 'video',
            label:
              Math.ceil(course.desiredDuration / 60) + ' mins learning video',
          }
          : {},
        course.format === "Self Paced" && langType === 'en' && course.locale.indexOf('es-ES') !== -1
          ? { icon: 'international', label: 'Disponible en Español' }
          : langType === 'es' && course.format === "Self Paced" && course.locale.indexOf('en-US') !== -1 ? { icon: 'international', label: 'Available in English' } : {},
        course.format !== "Self Paced" && course.locale.indexOf('en-US') !== -1 && course.locale.indexOf('es-ES') !== -1
          ? { icon: 'international', label: langType === 'es' ? 'Bilingue (inglés y español)' : 'Bilingual (English & Spanish)' } : {},
        course.availableSeats > 0 && course.enrollmentDeadline != null && course.enrollmentDeadline > nowTime.getTime()//Removed Seat Remaining as per requirement
          ? {
            icon: 'group-family',
            label: course.availableSeats + t(' seats remaining'),
          }
          : {},
        { icon: 'share', label: t('Share'), shareLink: shareLink },
      ],
      headerBreadcrumbs: [
        {
          name: 'Course',
          link: 'https://www.verizon.com/',
        },
        {
          name: 'Self-paced',
          link: 'https://www.verizon.com/',
        },
      ],
      // deliveryMethod: {
      //   type: 'article',
      //   source: course.imageUrl,
      //   thumbnailImage: course.imageUrl,
      // },
      tags: course.tags,
      courseDescription: courseLangDesc ? courseLangDesc : '',
      modules: course.modules,
      preview: course.preview
    };
  }

  const {
    label,
    publishedOnDate,
    icons,
    resourceTags,
    headerBreadcrumbs,
    deliveryMethod,
    tags,
    courseDescription,
    expertsDetails,
    modules,
    preview
  } = selectedCourse;

  const testTagsForCarousel = ['Learn - Financial basics', 'Group Session'];
  let courseDesc = courseDescription;
  if (courseDescription && courseDescription.indexOf('.html') !== -1) {
    const courseDescSplit = courseDescription.split("/");
    const fileName = courseDescSplit[courseDescSplit.length - 1];
    courseDesc = articleContent.filter(item => item.fileName === fileName).length > 0 ? articleContent.filter(item => item.fileName === fileName)[0].payload : '';
  }
  
  let presentTopics = tags;
  if (registerAemData &&
    registerAemData.registerModule &&
    registerAemData.registerModule.topics &&
    tags != null) {
    presentTopics = tags.filter(item => registerAemData.registerModule.topics.filter(topicItem => topicItem.label === item).length > 0);
  }
  const shareButtonHandle = function (link) {
    const currentHost = (selectedCourse.format == 'Quick Tip' || selectedCourse.format == 'Offer') ? `${window.location.protocol}//${window.location.host}` : '';
    link = currentHost + link;
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }

  let resourceHeadingTag = resourceTags == "" ? headerBreadcrumbs[0].name : resourceTags;
  let registerSecTitle = registerSecTitle_Tips;
  var recordedEventTag = tags && tags.find((item) => item === "Recorded Event") ? true : false

  switch (resourceHeadingTag) {
    case "Virtual Classroom":
    case "Blended":
      resourceHeadingTag = "Virtual Event";
      registerSecTitle = registerSecTitle_Event;
      break;
    case "Classroom":
      resourceHeadingTag = "In-Person Event";
      registerSecTitle = registerSecTitle_Event;
      break;
    case "Self Paced":
      resourceHeadingTag = "Course";
      registerSecTitle = registerSecTitle_Course;
      break;
  }
  {
    const path = location.pathname;
    let url = localStorage.getItem('url');
    localStorage.setItem('prevUrl', url);
    localStorage.setItem('url', path);

    console.log("Url", localStorage.getItem("prevUrl"));
  }

  const resourcesStandard = {
    icon: { name: 'timer', color: '#6f7171', label: '12 resources' },
    topic: 'Learning path title (74 characters max) lorem ipsum dolor set amet consect',
    buttonGroup: [

    ],
    viewFullPath: true,
    secondaryCta: true
  };

  const isDesktop = isMobile ? "mobile" : "desktop"
  const imageUrl = global.config.AEM_URL + "images/learningpath/" + isDesktop + "/" + courseId.split(":").pop() + ".png"

  useEffect(() => {
    let resourceType = resourceTags === '' || resourceTags === undefined ? headerBreadcrumbs?.[0]?.name : resourceTags;
    if (learningPathObj && isCourseDetailPage) {
      setDynamicBreadcrumb(<Breadcrumbs surface="dark">

        <BreadcrumbItem onClick={() => navigate("/")}>{t('Home')}</BreadcrumbItem>
        <BreadcrumbItem onClick={() => navigate("/category/learningpath")}>{t('Learning paths')}</BreadcrumbItem>
        <BreadcrumbItem onClick={() => navigate("/course-details/${learningPathObj['lpId']}")}>{learningPathObj['lpTitle']}</BreadcrumbItem>
        <BreadcrumbItem>{''}</BreadcrumbItem>
      </Breadcrumbs>);
    }
    else {
      if (resourceType === "Self Paced") {
        setDynamicBreadcrumb(<Breadcrumbs surface="dark">

          <BreadcrumbItem onClick={() => navigate("/")}>{t('Home')}</BreadcrumbItem>
          <BreadcrumbItem onClick={() => navigate("/category/courses")}>{recordedEventTag ? t('Events') : t('Courses')}</BreadcrumbItem>
          <BreadcrumbItem>{''}</BreadcrumbItem>
        </Breadcrumbs>);
      } else if (resourceType === "Virtual Classroom" || resourceType === "Blended" || resourceType === "Classroom") {
        setDynamicBreadcrumb(<Breadcrumbs surface="dark">

          <BreadcrumbItem onClick={() => navigate("/")}>{t('Home')}</BreadcrumbItem>
          <BreadcrumbItem onClick={() => navigate("/category/events")}>{t('Events')}</BreadcrumbItem>
          <BreadcrumbItem>{''}</BreadcrumbItem>
        </Breadcrumbs>);
      }
      else if (resourceType === "Tips & Resources / Article" || resourceType === "Tips & Resources / Video" || resourceType === "Tips & Resources / Audio") {
        setDynamicBreadcrumb(<Breadcrumbs surface="dark">
          <BreadcrumbItem onClick={() => navigate("/")}>{t('Home')}</BreadcrumbItem>
          <BreadcrumbItem onClick={() => navigate("/category/quicktips")}>{t('Quick tips')}</BreadcrumbItem>
          <BreadcrumbItem>{title}</BreadcrumbItem>
        </Breadcrumbs>);
      }
      else {
        setDynamicBreadcrumb(null);
      }

    }
  }, [learningPathObj, resourceTags, title])

  const tabArr = [t('Overview'),t('Sections'),courseInLearningPath.length > 0 && t('Learning paths'),t('Offers'),t('Related courses')].filter(item => item!==false);
  const onTabNavigation = (item) =>{
    const navigationId = document.getElementById(`tab${item}Id`);
    window.scrollTo({
      top:navigationId?.offsetTop,
      behavior:"smooth"
    });
  }
  useEffect(()=>{
  let resourcesDetail = [];   
  modules?.forEach(item => {
    let moduleIcon;
    let moduledesc;
    let moduleTitle;
    if(courseCx.resoursesDetails){
      moduledesc = courseCx.resoursesDetails?.filter( aemItem => item?.title?.toLowerCase().includes(aemItem.title?.toLowerCase()))[0]?.sectionDetailContent;
      moduleTitle = courseCx.resoursesDetails?.filter( aemItem => item?.title?.toLowerCase().includes(aemItem.title?.toLowerCase()))[0]?.title;
      let iconName = courseCx.resoursesDetails?.filter( aemItem => item?.title?.toLowerCase().includes(aemItem.title?.toLowerCase()))[0]?.iconName;
      let iconColor = courseCx.resoursesDetails?.filter( aemItem => item?.title?.toLowerCase().includes(aemItem.title?.toLowerCase()))[0]?.iconColor;
      moduleIcon = { baseIcon: iconName, name: iconName, size: '28px', color: iconColor };
    }
    const sectionObj = {
      icon: moduleIcon,
      title: getEnEsTitles(item?.localizedMetadata),
      time: (Math.ceil(item.duration / 60) + " " + t('minutes')),
      sectionDetailTitel: 'Section details',
      sectionDetailContent: moduledesc,
      collapsed: false
    }
    resourcesDetail.push(sectionObj);
  })
  setModuleResource(resourcesDetail);
},[courseId,courseCx]);

const widgetRef = useRef(null);
  
useEffect(() => {
  const checkScroll = () => {
    // console.log('>>>>>>>>>>>>>>>',widgetRef);

    if (widgetRef.current) {
      const scrollHeight = window.pageYOffset;
      const divOffset = widgetRef.current.offsetTop;
      // console.log('>>>>>>>>>>>>>>>',scrollHeight, divOffset);
      if ((scrollHeight-170) > 365 ) {
        widgetRef.current.classList.remove('sticky-bottom-widget');
        widgetRef.current.classList.add('bottom-widget');
      } else {
        widgetRef.current.classList.remove('bottom-widget');
        widgetRef.current.classList.add('sticky-bottom-widget');
      }
    }
  };

  window.addEventListener('scroll', checkScroll);

  return () => {
    window.removeEventListener('scroll', checkScroll);
  };
}, []);

  return (

    <>

      <section aria-label="details-page" style={{'position':'relative'}}>
        <CourseContentHeaderComponent
          breadCrumb={dynamicBreadCrumb}
          headerTitle={label ?? detail?.title}
          // headerBreadcrumbs={resourceHeadingTag}
          recordedEventTag={recordedEventTag}
          gutterCol={true}
        />

        <div ref={widgetRef} className="sticky-bottom-widget pv-6X sm-pv-6X sm-ph-4X">
        <div className="carousel-container-fix mv-2X">
          <div className="content">
            <div className="text-content sm-pb-4X">
              <Title size='small' >
                <b className="mr-1X">{t("Register now")}</b>{t(" to access this course. It's fast, eazy and free.")}

              </Title>
            </div>

            <ButtonGroup
              childWidth={'100%'}
              viewport={'desktop'}
              rowQuantity={{ desktop: 2 }}
              data={[
                {
                  children: t('Register'),
                  size: 'large',
                  use: 'primary',
                  width: 'auto',
                  onClick: () => navigate("/onboarding"),
                },
                {
                  children: t('Sign in'),
                  size: 'large',
                  use: 'textLink',
                  width: 'auto',
                  onClick: () => {
                    let userLanguage = localStorage.getItem("lng") && localStorage.getItem("lng") === 'es' ? 'es' :'en';
                    location.href=global.config.CIAM_SIGN_IN_URL+encodeURI(`${global.config.URL_MAPPING_AUTHENTICATED_USER[location.pathname]}?ciamLang=${userLanguage}`)
                    document.cookie = `firstTimeLogin=true;domain=${global.config.APP_COOKIE_DOMAIN};path=/`
                  },
                },

              ]}
              alignment={!isMobile ? 'right' : 'left'}
            />

          </div>
        </div>

      </div>

        <div className="course-details-body">
          <Grid>
            <Row>
              <Col
                colSizes={{
                  mobile: 4,
                  tablet: 1,
                  desktop: 1,
                }}
              ></Col>
              <Col
                colSizes={{
                  mobile: 4,
                  tablet: 1,
                  desktop: 1,
                }}
              >
              <div className={isMobile ? 'ico-mobile' : ''} >
                  {icons.map((item) => {
                    return (
                      <>

                        {item.icon === 'share' ?
                          item.shareLink !== null ? <div className="n-textCenter mb-8X">
                            <div className='share-content' aria-label='share-content' onKeyDown={(e) => {
                              if (e.key === "Enter") { shareButtonHandle(item.shareLink) }
                            }} onClick={() => shareButtonHandle(item.shareLink)}><Icon name={item.icon} size="XLarge" color="#000000" /></div>
                            <Micro color="#000000">{t(item.label)}</Micro>
                            <span className={isCopied ? 'share-content-copy-message' : 'share-content-copy-message hidden'}>Copied to clipboard</span>
                          </div> : ''
                          :
                          item.icon ? <div className="n-textCenter mb-8X">
                            <div>
                              <Icon name={item.icon} size="XLarge" color="#000000" />
                            </div>
                            <Micro color="#000000">{t(item.label)}</Micro>
                          </div> : ''
                        }
                      </>
                    );
                  })}
                </div>
              </Col>
              <Col
              colSizes={{
                mobile: 4,
                tablet: 8,
                desktop: 8,
              }}
            >
            {preview && <PreviewCourseContent preview={preview}></PreviewCourseContent>}

            <div className='mt-12X pt-2X'>
                  <Tabs
                    orientation="horizontal"
                    indicatorPosition="bottom">
                    {tabArr.map(item => <Tab onClick={() => onTabNavigation(item.replaceAll(' ',''))} label={item} />)}
                  </Tabs>
                </div>

                
                <SeeMore initialHeight="252px">
                  <div className='pt-8X'>
                  <div className='desc' dangerouslySetInnerHTML={{ __html: courseDesc }} />
                
                 
                    <div className='mv-12X'>
                      <Title size='medium' bold={true} primitive="h1">{t("Topics covered.")}</Title>
                      <div>
                      {presentTopics && presentTopics != null && presentTopics.length > 0 ? <div className={deliveryMethod?.source ? "mt-12X mb-6X" : "mb-6X"}>
                        <Body size="small" color="#000000" bold={true}>
                          {/* {topicsLabel} */}
                          <Body
                            size="small"
                            color="#6F7171"
                            primitive={isMobile ? 'div' : 'span'}
                            bold={false}
                          >
                            {presentTopics.map((item, i) => {
                              return (
                                i < 4 ? (
                                  isMobile ? <div className={i === 0 ? 'mt-3X' : 'ml-3X mt-3X'} style={{ display: 'inline-block' }}>
                                    <TextLink onClick={() => navigate(`/results/${item.replaceAll(' ','').toLowerCase()}`)}>{t(item)}</TextLink>
                                  </div> :
                                    <span className={i === 0 ? 'mt-3X' : 'ml-3X mt-3X'}>
                                      <TextLink onClick={() => navigate(`/results/${item.replaceAll(' ','').toLowerCase()}`)}>{t(item)}</TextLink>
                                    </span>) : ""
                              );
                            })}
                          </Body>
                          </Body>
                          {isMobile}
      </div> : ''}
                      </div>
                    </div>
                  </div>
                </SeeMore>

                {!isMobile && (
                  <div>
                    <ResourcesOverview resourcesDetails={moduleResource}></ResourcesOverview>
                  </div>
                )}
            
            </Col>


              </Row>
              </Grid>
              {isMobile && (
                <div>
                  <ResourcesOverview resourcesDetails={moduleResource}></ResourcesOverview>
                </div>
              )}
              </div>



        {!isMobile && <div className="carousel-container-fix">
          <Line type='secondary' />
        </div>}
        {courseInLearningPath.length>0 && <div id='tabLearningpathsId' className="pt-12X pb-16X sm-pt-0X">
          <div className="carousel-container-fix mb-8X sm-ph-4X">
            <Title size='medium' bold={true}>
              {t("This course is also included in at least one learning path:")}
            </Title>
          </div>
          <div className="intrested-in-carousel mt-8X sm-mt-6X" >
            <Carousel
              peek="standard"
              layout={"1UP"}
              gutter="24px"
              surface="light"
              paginationInset="12px"
              paginationDisplay="persistent"
              data={sortLearningPathList(learningPaths.filter(function (item) {
                return item.courseId !== courseId;
              })).filter(e => tags?.some(r => e.tags?.includes(r)))}
              renderItem={props => isMobile ? <CourseCardComponent data={props} /> : <LearningPathCard data={props} />}
            />
          </div>
        </div>}

        <div id='tabOffersId' className="grey-widget pt-12X pb-16X ">
          <div className="carousel-container-fix mb-8X sm-ph-4X">
            <Title size='medium' bold={true}>
              {t("Related offers:")}
            </Title>
          </div>
          <div className="carousel-container-fix intrested-in-carousel pt-8X sm-pt-6X" >
          <Carousel
          peek="standard"
          layout={isMobile ? "1UP" : "3UP"}
          gutter="24px"
          surface="light"
          paginationInset="12px"
          paginationDisplay="persistent"
          aspectRatio='none'
          data={forcedRankingOffersTopics(offersMock, selectedTopics, 12).map((item) => {
            let _item = cloneDeep(item)
            _item.bg = 'bg-white height-correct'
            console.log(_item)
            return _item;
          })}
          renderItem={props => <OffersCardComponent data={props} />}
        />
          </div>
        </div>


        <div id='tabRelatedcoursesId' className="pt-12X pb-16X">
          <div className="carousel-container-fix mb-8X sm-ph-4X">
            <Title size='medium' bold={true}>
              {t("You may also be interested in:")}
            </Title>
          </div>
          <div className="intrested-in-carousel mt-8X sm-mt-6X" >
            <TagBasedCoursesComponent tags={tags} isLandingPage={false} />
          </div>
        </div>

      </section>

      
      <Footer />
    </>
  );
};

export default CourseOverview;
