/** @format */
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { Col, Grid, Row } from '@vds/grids';
import { getLanguageBasedHref, getWithExpiry, setWithExpiry } from './utility/utils'

import './App.scss';
import { updateCatalogData, updateQuickTips , updateNotifications,updateRecommendedCourses } from './store/adoberesources';
import { updateAEMRegisterData, updateArticleContent, updateRegistrationStepTopics, updateAEMQuickTIpsData, updateAEMJsonData, updateAEMOffersJsonData, updateSpanish, updateUiLocaledata, updateLangSpanish, addFAQ, updateIncentives, courseCx } from './store/aem';

import { updateIsMobile, updateIsIpad, updateIsLowResolutionDesktop } from './store/mediatype';
import store from './store/store';
import { retrieveAdobeData, retrieveJsonData, retrieveFileData } from './utility/common';
import { quickTip_en, quickTip_es} from './mocks/quick_tip.js'
import { pageLoadStarted } from './common/Analytics/GAWrapper.jsx';

const App = () => {
  const mediaQueryList = window.matchMedia('screen and (max-width:767px)');
  const tabletMediaQueryList = window.matchMedia('screen and (min-width:768px) and (max-width:1024px)');
  const lowResolutionDesktopMediaQueryList = window.matchMedia('screen and (min-width:1025px) and (max-width:1280px)');

  const aemData = useSelector((state) => state.Aem.data);

  const navigate = useNavigate();

  // useMemo(() => {
  //   store.dispatch(updateCatalogData(minifiedCatalog));
  // }, []);

  //Load all the suggestion object

  useEffect(() => { 


    console.log("useeffect inside app.jsx")
    const location = window.location;
    const path = location.pathname;
    const store = window.localStorage;
    let url = '';
    let prevUrl = '';
    console.log("Language",localStorage.getItem("lng"))
    url = store.getItem('url');
   // store.setItem('prevUrl', url);
    store.setItem('url', path);
    console.log("Url", store.getItem("prevUrl"))
    console.log("Url current", store.getItem("url"))
    const lang = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
    var variation = (lang === 'es') ? "prospect-es" : "prospect";
    console.log("**global.config",JSON.stringify(global.config));
    let appId = global.config.AEM_APP_ID ? global.config.AEM_APP_ID : "vsbdr";
    window.gnavdl = {
			"bu": "corporate",
			"appid": appId,
			"variation": variation,
      "options": { "hide-item": ["search"]}
		}
    console.log("**window.gnavdl",window.gnavdl);
   }, []);
  useMemo(() => {
    let payload= {allEvents:[], allCourses:[],learningPaths: []};
    let suggestion = getWithExpiry('suggestion');
    if(suggestion == null || suggestion.length == 0) {
      retrieveAdobeData()
      .then((jsonData) => {
        if (jsonData) {
          setWithExpiry('suggestion', jsonData.data, 3600000, jsonData.data.redisdate);
          if(jsonData.data.courses || jsonData.data.events || jsonData?.data?.learningProgram) {
            payload.allEvents = Object.values(JSON.parse(localStorage.suggestion).value.events);
            payload.allCourses = Object.values(JSON.parse(localStorage.suggestion).value.courses);
            payload.learningPaths = Object.values(JSON.parse(localStorage.suggestion).value.learningProgram);
            store.dispatch(updateCatalogData(payload));
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    } else{
      if(suggestion.courses && suggestion.events) {
        payload.allEvents = Object.values(JSON.parse(localStorage.suggestion).value.events);
        payload.allCourses = Object.values(JSON.parse(localStorage.suggestion).value.courses);
        payload.learningPaths = Object.values(JSON.parse(localStorage.suggestion).value.learningProgram);
        store.dispatch(updateCatalogData(payload));
      }
    }  
  }, []);

  useEffect(() => {
    retrieveJsonData('static-contents/dashboardjson/quickTip_en.json')
      .then((jsonData) => {
        let englishQuickTips = Object.values(jsonData)
        retrieveJsonData('static-contents/dashboardjson/quickTip_es.json')
          .then((jsonDataES) =>{
          let spanishQuickTips = Object.values(jsonDataES)
          console.log((englishQuickTips.concat(spanishQuickTips)),"==> combined quicktips");
          let combinedQuickTips = [];
          englishQuickTips.forEach((e) => {
            if(jsonDataES[e.courseId]){
              e.locale.push('es-ES');
              e.labelSpanish = jsonDataES[e.courseId].label;
            }
            if(e.format == "Quick Tip") {
              combinedQuickTips.push(e);
            }
          });
          store.dispatch(updateQuickTips(combinedQuickTips));
        } )
        .catch((error) => {
          console.error(error,"error in spanish quicktips");
        });
      })
      .catch((error) => {
        console.error(error,"error in english quicktips");
      });
  }, []);
  
  useEffect(() => {
    if(localStorage.combinedNotifications) {
      store.dispatch(updateNotifications(JSON.parse(localStorage.combinedNotifications)));
    } else {
      retrieveJsonData('static-contents/notifications_en.json')
      .then((notifications_en) => {
        retrieveJsonData('static-contents/notifications_es.json')
          .then((notification_es) =>{
            let combinedNotifications = {
              "en" : notifications_en,
              "es" : notification_es
            }
            localStorage.setItem('combinedNotifications', JSON.stringify(combinedNotifications));
          store.dispatch(updateNotifications(combinedNotifications));
        } )
        .catch((error) => {
          console.error(error,"error in spanish notifications");
        });
      })
      .catch((error) => {
        console.error(error,"error in english notifications");
      });
    }    
  }, []);
  
//Load All tips
//  useMemo(() => {
//     let englishQuickTips = Object.values(quickTip_en);
//     let spanishQuickTips = Object.values(quickTip_es);
//     store.dispatch(updateQuickTips(englishQuickTips.concat(spanishQuickTips)));
//   }, []);

  useEffect(() => {
    store.dispatch(updateIsMobile(mediaQueryList.matches));
    mediaQueryList.addEventListener('change', (e) =>
      store.dispatch(updateIsMobile(e.matches))
    );
  }, [mediaQueryList]);

  useEffect(() => {
    store.dispatch(updateIsIpad(tabletMediaQueryList.matches));
    tabletMediaQueryList.addEventListener('change', (e) =>
      store.dispatch(updateIsIpad(e.matches))
    );
  }, [tabletMediaQueryList]);

  useEffect(() => {
    store.dispatch(updateIsLowResolutionDesktop(lowResolutionDesktopMediaQueryList.matches));
    tabletMediaQueryList.addEventListener('change', (e) =>
      store.dispatch(updateIsLowResolutionDesktop(e.matches))
    );
  }, [lowResolutionDesktopMediaQueryList]);

  useEffect(() => {
    retrieveJsonData('static-contents/register/english/registerPage.json')
      .then((jsonData) => {
        store.dispatch(updateAEMRegisterData(jsonData));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    let url=localStorage.getItem("lng")==='es'?'static-contents/dashboardjson/quickTip_es.json':'static-contents/dashboardjson/quickTip_en.json'
    retrieveJsonData(url)
      .then((jsonData) => {
        store.dispatch(updateAEMQuickTIpsData(jsonData));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    
    retrieveJsonData('static-contents/offersDetail.json')
      .then((jsonData) => {
        store.dispatch(updateAEMOffersJsonData(jsonData));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    retrieveJsonData('static-contents/register/spanish/registerPage.json')
      .then((jsonData) => store.dispatch(updateLangSpanish(jsonData)))
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    console.log("Language--",localStorage.getItem("lng"))
    let url=localStorage.getItem("lng")==='es'?'static-contents/coursecx/courseCx_es.json':'static-contents/coursecx/courseCx_en.json'
    retrieveJsonData(url)
      .then((jsonData) => store.dispatch(courseCx(jsonData)))
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    console.log("Language--",localStorage.getItem("lng"))
    let url=localStorage.getItem("lng")==='es'?'static-contents/FAQ_es.json':'static-contents/FAQ.json'
    retrieveJsonData(url)
      .then((jsonData) => store.dispatch(addFAQ(jsonData)))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    retrieveJsonData('static-contents/incentives/incentives_en.json')
      .then((jsonData) => store.dispatch(updateIncentives(jsonData)))
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    retrieveJsonData('static-contents/dashboardjson/recommendedCourses.json')
      .then((jsonData) => store.dispatch(updateRecommendedCourses(jsonData)))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // useEffect(() => {
  //   retrieveFileData('Article_content.html')
  //     .then((jsonData) => store.dispatch(updateArticleContent(jsonData)))
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);
  // useEffect(() => {
  //   retrieveFileData('Article_content.html')
  //     .then((jsonData) => store.dispatch(updateArticleContent(jsonData)))
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);

  // useEffect(() => {
  //   retrieveFileData('Howtoreclaimyourworkschedule.html')
  //     .then((jsonData) => store.dispatch(updateArticleContent(jsonData)))
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);
 
  useEffect(() => {
    // const lang = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
    // let languageBasedHref = getLanguageBasedHref();
    // if(!sessionStorage.isSwitchedLanguage && location.pathname.indexOf("/es") != -1 && lang != "es") {
    //   localStorage.setItem("lng","es");
    //   navigate(location.pathname);
    // }
    
    // if(languageBasedHref != null && languageBasedHref != location.pathname) {
    //   location.pathname =  languageBasedHref;
    // }

    //Added logic to change the language based on the URL
    if(localStorage.getItem("lng") != "es" && location.pathname.indexOf("/es") != -1) {
      localStorage.setItem("lng","es");
      window.location.reload();
    } else if(localStorage.getItem("lng") != "en" && location.pathname.indexOf("/es") == -1) {
      localStorage.setItem("lng","en");
      window.location.reload();
    }

    const handleLangSwitchClick = (event) => {
      if (event.target && event.target.className && event.target.className === 'gnav20-lang-link') {
        event.preventDefault();

        const userLang = (event && event.target && event.target.getAttribute('data-lang') && (event.target.getAttribute('data-lang') === 'English' || event.target.getAttribute('data-lang') === 'Inglés')) ? 'en' : 'es';
        localStorage.setItem('lng', userLang);
        sessionStorage.setItem('isSwitchedLanguage', true);
        window.gnavdl.variation = (userLang && userLang === 'en') ? 'prospect' : 'prospect-es';
        const isSpanish = (userLang && userLang === 'en') ? false : true;
        store.dispatch(updateSpanish(isSpanish));
        let languageBasedHref = getLanguageBasedHref();
        if(languageBasedHref != null) {
          location.pathname =  languageBasedHref;
        } else {
          window.location.reload();
        }
      }
    };

    document.addEventListener('click', handleLangSwitchClick);
    document.addEventListener('touchstart', handleLangSwitchClick);
  }, []);

  useEffect(() => {
    // if (!aemData) {
      let langUI = localStorage.getItem("lng");
      if (langUI === 'es') {
          retrieveJsonData('static-contents/landing/landingPage_es.json')
          .then((jsonData) => {
          const courseDetails =
              jsonData.TipsAndResourcesSectionData.courseDetails.map(
                (course, i) => {
                  return {
                    ...course,
                    courseId: i + 1,
                    courseType: course.courseType,
                  };
                }
              );
            jsonData.TipsAndResourcesSectionData.courseDetails = courseDetails;
            store.dispatch(updateAEMJsonData(jsonData));
          })          
          .catch((error) => {
            console.error(error);
          });
      } else {
        retrieveJsonData('static-contents/landing/landingPage.json')
          .then((jsonData) => {
          const courseDetails =
              jsonData.TipsAndResourcesSectionData.courseDetails.map(
                (course, i) => {
                  return {
                    ...course,
                    courseId: i + 1,
                    courseType: course.courseType,
                  };
                }
              );
            jsonData.TipsAndResourcesSectionData.courseDetails = courseDetails;
            store.dispatch(updateAEMJsonData(jsonData));
           })
          .catch((error) => {
            console.error(error);
          });
      }
    // }
  }, []);



  return aemData ? (
    <>
      <Outlet />
    </>

  ) : (
    <></>
  );
};

export default App;
