import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { DropdownSelect } from '@vds/selects';
import { Body, Title } from '@vds/typography';
import { Button, TextLink } from '@vds/buttons';
import { Line } from '@vds/lines';
import { Modal, ModalBody, ModalTitle, ModalFooter } from '@vds/modals';

import CategoryFiltersComponent from './CategoryFiltersComponent.jsx';

import '../CategoryPage.scss';
import '../Categories.scss';
import { Toggle } from '@vds/toggles';
import { ButtonIcon } from '@vds/button-icons';
import SearchInput from '../../common/inputSearch.jsx';
import { useLocation } from 'react-router-dom';
import { Icon } from '@vds/icons';


const CategoryFiltersAndSortComponent = ({ filterSortData, filters, removeSorting, sortingValues, handleSorting, handleRemoveFilter, handleFilter, handleRemoveAllFilter, resultSize, headingData, sort=true, search = false, toggle = false, totalResultCount = 0, handleFavourites = () => { }, favouriteVal = false, showSearchBar = true }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const history = useNavigate();
  const { categoryType } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  console.log("CategoryFiltersAndSortComponent", filters);

  return (

    <>
    {!isMobile && showSearchBar && (
      <SearchInput></SearchInput>
    )}
    <div className='u-flex-apart m-flex-colrev pv-8X sm-pt-0X pb-16X sm-pb-8X'>
        {search && <div className='results-search'>
          <Title size='large' bold>
          {totalResultCount} {t("results for")} "{searchParams.get("query")}"
          </Title>
          <div className='sm-pt-3X pl-3X sm-pl-0X'>
            <TextLink onClick={() => {location.href = `/category/${categoryType}`}}>{t("Clear all results")}</TextLink>
          </div>
        </div>}
        {!search && (
          <div className='width70'>
            {filterSortData && (
              <div className='u-flex-wrap'>
                {filterSortData.map((item, n) => {
                  return (
                    <div key={n} className='u-flex chips mr-3X mb-3X'>
                      <Body size='small'>{t(item.label)}</Body>
                      <span className='ml-2X u-flex' onClick={e => handleRemoveFilter(item.value, item.label, item.category)} tabIndex={0} aria-label={'remove filter ' + item.label}> <Icon name="close" size="small" color="#000000" /></span>
                    </div>);
                })}
                {filterSortData.length > 0 ? (
                  <span className='mb-2X'>
                    <TextLink type="standAlone"
                      size='small'
                      onClick={e => handleRemoveAllFilter()} role='button' ariaLabel={t('Clear All')}>
                      {t('Clear All')}

                    </TextLink>
                  </span>

                ) : ''}
                {filterSortData.length == 0 ? (
                  <span className='u-flex chips mb-2X'>
                    <Body size='small'>{t('No filters selected')}</Body>
                  </span>

                ) : ''}
              </div>
        )}
      </div>
      )} 
      {isMobile && (
        <div className='pv-2X'> <Line type="secondary" /> </div>
      )}
      {isMobile && (
        <div className="mobile-action-figures width100 mt-8X">
          <div className="u-flex-apart pb-6X">
            <div className="left-action-place">
                {toggle && (
                  <div className="spanish-toggle ">
                    <Body primitive='span' size='large' bold>
                      <span className="toggle-text mr-2X">
                        View my favorites
                      </span>
                    </Body>

                    <span className='toggle-text mr-2X'></span>
                    <Toggle
                      disabled={false}
                      showText={false}
                      ariaLabel={t('favriotes')}
                      onChange={handleFavourites}
                    on={favouriteVal}
                    />
                  </div>
                )}
              <div>
                {showSearchBar && <SearchInput></SearchInput>}
              </div>

            </div>
            <div className='right-action-place'>
              <ButtonIcon
                kind="lowContrast"
                size="large"
                renderIcon={(props) => <Icon name="filter" {...props} />}
                onClick={(e) => setShowMobileFilter(true)}
              />
              <Modal onOpenedChange={(e) => { if (!e) { setShowMobileFilter(false); } }} opened={showMobileFilter} surface="light" fullScreenDialog={false} disableAnimation={false} disableOutsideClick={false} ariaLabel="Filters" >
                  <ModalTitle>{t("Filter")}</ModalTitle>
                  <ModalBody>
                    <CategoryFiltersComponent filters={filters} handleFilter={handleFilter} headingData={headingData} handleRemoveAllFilter={handleRemoveAllFilter} setShowMobileFilter={setShowMobileFilter}></CategoryFiltersComponent>
                  </ModalBody>
                </Modal>
                {sort && false && (
                  <span className='ml-2X'>

                    <ButtonIcon
                      kind="lowContrast"
                      size="large"
                      renderIcon={(props) => <Icon name="sort" {...props} />}
                    />
                  </span>
                )}
            </div>
          </div>
          <Line type="secondary" />
        </div >
      )}

      <div className='u-flex-apart sort-by'>
        {/* <div className='mr-8X'>
          <Body size='large' color='grey'>Showing {totalResults} results</Body>
        </div> */}
        {isMobile && (
          <div className='u-flex'>
            {sort && sortingValues?.length > 0 && <div className='ml-2X'>
                <DropdownSelect onChange={e => handleSorting(e.target.value)} label={t("Sort by:")} errorText={'Select a state'} error={false} disabled={resultSize == 0 ? true : false} readOnly={false} inlineLabel={true} >
                  {sortingValues?.map(e => <option value={e.value}>{e.label}</option>)}
                </DropdownSelect>
              </div>}
          </div>
        )}
        {/* {resultSize == 0 && !isMobile && <Body size='medium'>{t("Showing 0 results")}</Body>} */}
        {toggle && !isMobile &&
            <div className="spanish-toggle mb-6X">
              <Body primitive='span' size='large' bold>
                <span className="toggle-text mr-2X">
                  View my favorites
                </span>
              </Body>

            <span className='toggle-text mr-2X'></span>
            <Toggle
              disabled={false}
              showText={false}
              ariaLabel={t('favriotes')}
              onChange={handleFavourites}
              on={favouriteVal}
            />
          </div>
        }
        {!isMobile && !removeSorting && sortingValues?.length > 0 && (
          <DropdownSelect onChange={e => handleSorting(e.target.value)} label={t("Sort by:")} errorText='Select a state' error={false} disabled={resultSize == 0 ? true : false} readOnly={false} inlineLabel={true} >
            {sortingValues.map(e => <option value={e.value}>{t(e.label)}</option>)}
          </DropdownSelect>)}
      </div>
    </div>
    </>
    
  );
};

export default CategoryFiltersAndSortComponent;

