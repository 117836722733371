// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordian-container {
  border-bottom: 1px solid black;
}
.accordian-container .accordian-labels {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.accordian-container .accordian-labels .content {
  display: flex;
  align-items: center;
}
.accordian-container .accordian-labels .accordian-icon {
  float: right;
}

.dot-wrap {
  display: inline-flex;
  width: 2px;
  height: 2px;
  background-color: #000;
  border-radius: 100px;
}

.horizontal-stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.horizontal-stepper .step {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
}
.horizontal-stepper .step-line {
  width: 100%;
  height: 1px;
  background-color: #a7a7a7;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/common/resourcesOverview.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF;AAAI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AAEN;AADM;EACE,aAAA;EACA,mBAAA;AAGR;AADM;EACE,YAAA;AAGR;;AAGA;EACE,oBAAA;EACA,UAAA;EACA,WAAA;EACA,sBAAA;EACA,oBAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAAF;AACE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;AACJ;AACE;EACE,WAAA;EACA,WAAA;EACA,yBAAA;EACA,gBAAA;AACJ","sourcesContent":[".accordian-container{\n  border-bottom: 1px solid black;\n    .accordian-labels{\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      cursor: pointer;\n      .content{\n        display: flex;\n        align-items: center;\n      }\n      .accordian-icon{\n        float: right;\n      }\n    }\n   \n}\n\n.dot-wrap {\n  display: inline-flex;\n  width: 2px;\n  height: 2px;\n  background-color: #000;\n  border-radius: 100px;\n}\n\n.horizontal-stepper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  .step {\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 24px;\n    width: 24px;\n  }\n  .step-line{\n    width: 100%;\n    height: 1px;\n    background-color: #a7a7a7;\n    overflow: hidden;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
