import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ButtonGroup } from '@vds/buttons';
import { Carousel } from '@vds/carousels';
import { Body, Feature, Title } from '@vds/typography';

import { pageLoadStarted, userRegistrationClick } from '../common/Analytics/GAWrapper';
import './WelcomeComponent.scss';
import { Col, Grid, Row } from '@vds/grids';
import Cookies from 'js-cookie';

const WelcomeComponent = () => {
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  const isIpad = useSelector((state) => state.MediaType.isIpad);
  const isLowResolutionDesktop = useSelector((state) => state.MediaType.isLowResolutionDesktop);
  const aemData = useSelector((state) => state.Aem.data);
  const userDataFromStore = useSelector((state) => state.data);

  let lng = localStorage.getItem("lng") && localStorage.getItem("lng") === 'es' ? 'es' :'en';
  if(lng === null){
    lng = userDataFromStore?.business?.attributes?.Language??'en';
  }
  let udpatedCiamSignInURL = `${global.config.CIAM_SIGN_IN_URL}${process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH}?ciamLang=${lng}`;

  //Logic to get hero image for the screens
  let welcomeImage = aemData?.welcomModule?.welcomeImageDesktop;
  if (isMobile) {
    welcomeImage = aemData?.welcomModule?.welcomeImageMobile;
  } else if (isIpad) {
    welcomeImage = aemData?.welcomModule?.welcomeImageIpad;
  } else if (isLowResolutionDesktop) {
    welcomeImage = aemData?.welcomModule?.welcomeImageLowResolutionDesktop;
  }

  const navigate = useNavigate();
  const handleRegisterClick = () => {
   // pageLoadStarted( "onboardingPage", window.location.origin);
   localStorage.setItem('prevUrl',window.location.href);
    navigate('/onboarding');
   
    userRegistrationClick('HeroImage', 'Register now', window.location.host + '/onboarding')
  };

  const handleSignInClick = () => navigate('/onboarding', { replace: true });

  let childTiles = [];
  if (aemData && aemData.welcomModule && aemData.welcomModule.cardData) {
    childTiles = aemData.welcomModule.cardData.map((card) => ({
      title: card.title,
      subtitle: card.subTitle,
    }));
    console.log('childTiles => ', childTiles);
  }
  useEffect(() => {
    const prevUrl = Cookies.get('current_url') ? Cookies.get('current_url') : ''
    pageLoadStarted( 'LandingPage', prevUrl,'');
   // localStorage.setItem("prevUrl",window.location.href)
  }, []);
  return (
    <section
      aria-label="hero-image sm-pt-2X"
      className={isMobile ? 'hero-image-mobile' : 'hero-image'}
      style={{ backgroundImage: 'url(' + welcomeImage + ')' }}
    >
      <Grid
        className=""
        bleed="1440"
        colSizes={{
          mobile: 4,
          tablet: 12,
          desktop: 12,
        }}>
        <Row>
          <Col>
            <div tabIndex={0} className="pt-32X hero-image-title">
              <Feature size={isMobile ? "XSmall" : "small"} bold={true} color="#ffffff" primitive='h1'>
                {aemData?.welcomModule?.heroImageSlogan1}
                <br />
                {aemData?.welcomModule?.heroImageSlogan2}
              </Feature>
            </div>
            <div tabIndex={0} className="mt-6X">
              <Body size="large" bold={false} color="#ffffff">
                {aemData?.welcomModule?.heroImageTitle}
              </Body>
            </div>
            <div className="button-area mt-8X">
              <ButtonGroup
                childWidth={'100%'}
                viewport={'desktop'}
                surface="dark"
                rowQuantity={{ desktop: 2 }}
                data={[
                  {
                    children: <div>{aemData?.welcomModule?.registerText}</div>,
                    size: 'large',
                    use: 'secondary',
                    width: 'auto',
                    onClick: handleRegisterClick,
                  },
                  {
                    children: <div>{aemData?.welcomModule?.signInText}</div>,
                    size: 'large',
                    use: 'textLink',
                    width: 'auto',
                    href: udpatedCiamSignInURL,
                    onClick: () => {document.cookie = `firstTimeLogin=true;domain=${global.config.APP_COOKIE_DOMAIN};path=/`},
                  },
                ]}
                alignment={'center'}
              />
            </div>
          </Col>
        </Row>
      </Grid>


      {childTiles && childTiles.length > 0 ? (
        <Grid
          className=""
          bleed="1440"
          colSizes={{
            mobile: 4,
            tablet: 12,
            desktop: 12,
          }}>
          <Row>
            <Col>

              <div className="features-carousel">
                <Carousel
                  viewport="mobile"
                  peek="none"
                  layout={isMobile ? '1UP' : isIpad ? '2UP' : '3UP'}
                  gutter="32px"
                  surface="light"
                  paginationFill="light"
                  paginationInset="12px"
                  paginationDisplay="persistent"
                  aspectRatio={isMobile ? "45:29" : '140:89'}
                  data={childTiles}
                  renderItem={props => {
                    console.log(props, 'props')
                    return <div tabIndex={0} {...props} className='carousel-cont'>
                      <h2 tabIndex={0} aria-label={props.title} className="carousel-title">{props.title}</h2>
                      <h2 tabIndex={0} aria-label={props.subtitle} className="carousel-subtitle">{props.subtitle}</h2>
                    </div>
                  }}
                />
              </div>
            </Col>
          </Row>
        </Grid>
      ) : (
        <></>
      )}
    </section>
  );
};

export default WelcomeComponent;
