import { useEffect, useState } from 'react';
import Header from '../layout/Header';
import ContactUSComponent from '../components/ContactUSComponent';
import CourseFilterComponent from '../components/CourseFilterComponent';
import EvenMoreResourcesComponent from '../components/EvenMoreResourcesComponent';
import GrantsComponent from '../components/GrantsComponent';
import PartnersComponent from '../components/PartnersComponent';
import SocialProofComponent from '../components/SocialProofComponent';
import TipsAndResourcesComponent from '../components/TipsAndResourcesComponent';
import ValuePropositionComponent from '../components/ValuePropositionComponent';
import WelcomeComponent from '../components/WelcomeComponent';
import './LandingPage.scss';
import Footer from '../layout/Footer';
import QuickTips from '../components/QuickTips/QuickTips.jsx';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import store from '../store/store.js';
import { updateReferralCode } from '../store/aem.js';

const LandingPage = () => {

  let aemQuickTips = useSelector((state) => state.Aem.quickTipData);
  useEffect(()=>{
    localStorage.setItem('categoryPath',location.pathname);
    const url = new URLSearchParams(window.location.href);
    const refCode = url.get('referralCode');
    if(refCode){
      // Set Cookie
      const hostname = window.location.hostname;
      const domain = hostname.substring(hostname.lastIndexOf(".",hostname.lastIndexOf(".") - 1));
      const expireTime = new Date(new Date().getTime() + 10 * 60 * 1000);
      Cookies.set('referrelCode',refCode,{domain:domain, expires: expireTime, path:'/', secure:true});
      store.dispatch(updateReferralCode(refCode));
    }
  },[]);
  return (
    <main className="landing-container text-center">
      <Header />
      <WelcomeComponent />
      {/* <QuickTips /> */}
      {aemQuickTips && <TipsAndResourcesComponent /> }
      
      <CourseFilterComponent />
      <EvenMoreResourcesComponent />
      <GrantsComponent />
      <SocialProofComponent />
      <ValuePropositionComponent />
      <PartnersComponent />
      <ContactUSComponent />
    </main>
  );
};

export default LandingPage;
