import React , { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';

import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';

import CategoryHeadingComponent from './common/CategoryHeadingComponent.jsx';
import CategoryFeatureComponent from './common/CategoryFeatureComponent.jsx';
import CategoryFiltersAndSortComponent from './common/CategoryFiltersAndSortComponent.jsx';
import CategoryFiltersComponent from './common/CategoryFiltersComponent.jsx';
import CategoryFilteredCardsComponent from './common/CategoryFilteredCardsComponent.jsx';
import { courseFilters } from './mocks.js';
import { filterCategories, createFilterInputForUtil, createCourseObject, TAG_RECORDED_EVENTS }  from '../../utility/utils.js';
import { cloneDeep } from "lodash";
import store from '../../store/store.js'
import { updateCoursesCategoryFilterData } from '../../store/adoberesources';
import { Body, Feature, Title } from '@vds/typography';
import {getLMSSearchResult} from '../../utility/common.js'

import './CategoryPage.scss';

const CategoryCoursesComponent = ({forcedRankingCourse, topics}) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [coursesDetailsHeading , setCoursesDetailsHeading] = useState(null);
    const [coursesDetailsFeature , setCoursesDetailsFeature] = useState();
    const [coursesDetailsFilterSortData , setCoursesDetailsFilterSortData] = useState();
    const [coursesDetailsFilter , setCoursesDetailsFilter] = useState(courseFilters);
    const [coursesDetailsData , setCoursesDetailsData] = useState();
    const [coursesMainSortedDetailsData , setCoursesMainSortedDetailsData] = useState();
    const allCourses = useSelector((state) => state.AdobeResources.allCourses);
    const courseCategoryPageFilters = useSelector((state) => state.AdobeResources.courseCategoryPageFilters);
    const [sortType, setSortType] = useState('best-match');
    const [allCoursesList, setAllCoursesList] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const aemData = useSelector((state) => state.Aem.data);
    const [resetPagination, setResetPagination] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const recommendedCourses = forcedRankingCourse;
    const { filterType } = useParams();
    const [isLoader, setIsLoader] = useState(true);
    const [showingSearch, setShowingSearch] = useState(filterType === "search" ? true : false);
    const [sortingValues, setSortingValues] = useState([
      { label: t('Best Match'), value: 'best-match' },
      { label: t('Popularity'), value: 'popularity' },
      { label: t('Date (newest to oldest)'), value: 'date' }
    ]);
    const [nextCursor, setNextCursor] = useState('');
    
    console.log("filterType", filterType);
    const NUMBER_OF_TILES = 17;

    const {
      labels: {
        courseDetailsPage: {
          registerSecTitle_Course,
          registerSecDesc,
          registerButtonLabel,
          alreadyMemberLabel,
          signInLabel,
        },
      },
    } = aemData;

    const firstCardDetails = {
      registerSecTitle: registerSecTitle_Course,
      registerSecDesc: registerSecDesc,
      registerButtonLabel: registerButtonLabel,
      alreadyMemberLabel: alreadyMemberLabel,
      signInLabel: signInLabel,
      resourceType: "Course"
    }

    let courseFilter = courseFilters;

    useEffect(()=>{
      localStorage.setItem('categoryPath',location.pathname);
    },[]);
    
    useEffect(() => {
        console.log("allCourses in the Courses Category", allCourses);
        //Mock API Call
        // let filterSortDataOnLoad = topics.filter(topic => businessData?.attributes[topic.label] == "Yes").map(topic => topic.label);
        setCoursesDetailsHeading({
          breadcrumbLabel: 'Courses',
          modalLinkHeading: 'what types of course formats do we offer',
          modalLinkUrl: '',
          label: 'Courses',
          subText: 'From capitalizing on social media to managing money, learn critical skills that can move your business forward.',
          textLink: { label: 'What types of course formats do we offer?', link: '', tooltipText: 'toolTipHeading' },
          buttons: [],
          sideIcon: { name: '', color: '' },
          modalContent: [{label:'Standard courses',text:'Explore key business topics with expert-led courses, delivered by fellow small business owners. Includes a video, short quizzes and downloadable tools that can be completed when and where you want.'},
          {label:'Mini-courses',text:"Bite-sized content that gets to the point and prepares you to captilalize on specefic opportunities. You'll also get a quick-reference guide when you're done."}],
        });
        
        //For authenticated user need to all topics of interest and Statuses (Seats available, Registered and Waitlisted) as selected
        //TODO: Once we get confirmation on Pre Recorded courses need to add that also in default
        //For Spanish Users selected filter is Topics:All and Language: All
        //I'm not sure selected users should have all topics selected
        const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
        let selectedTopics = [];
        //getting filters from redux store if available
        const resultTopic = searchParams.get("topic");
        console.log("courseCategoryPageFilters", courseCategoryPageFilters);
        if(courseCategoryPageFilters?.length > 0) {
          courseFilter = cloneDeep(courseCategoryPageFilters);
        } else {
          if(resultTopic && resultTopic != "") {
            courseFilter[0].subCategory[0].options.push({children: "View all", value: "View all", showFilterName: "All Topics", selected: false, skipToShow: true});
            topics.forEach((e) => {
              courseFilter[0].subCategory[0].options.push({children: t(e.label), value: e.label, selected: resultTopic == e.label ? true : false});
            });
          } else {
            courseFilter[0].subCategory[0].options.push({children: "View all", value: "View all", showFilterName: "All Topics", selected: true, skipToShow: true});
            topics.forEach((e) => {
              courseFilter[0].subCategory[0].options.push({children: t(e.label), value: e.label, selected: true});
            });
          }
        }
       
        //Remove language filter for english users
        if (userLanguage == "en") {
          courseFilter.splice(4,1);
        }

        //Langugae conversion
        courseFilter.forEach((filter) => {
          filter?.subCategory?.forEach((item) => {
            item.options.forEach((item) => {
              item.children = t(item.children);
            });
          });
        });
        
        if(filterType === "search") {
          setShowingSearch(true);
          setSortingValues([]);
          setAllCoursesList([]);
          getLMSSearchResult("Course", searchParams.get("query")).then((result) => {
            console.log("Course category search result", result);
            let searchCourseList = [];
            result?.data?.searchResult?.forEach((course) => {
              if(course?.tags?.indexOf(TAG_RECORDED_EVENTS) == -1)
                searchCourseList.push(createCourseObject(course, userLanguage));
            });
            setAllCoursesList(searchCourseList);
            //Calling Filter Function
            handleFilter(courseFilter, 'best-match', searchCourseList);
            //postFilterImplementation(searchCourseList, filterData);
          }).catch((error) => {
            console.log("error while uCourse category search result", error);
          })
        } else {
          setAllCoursesList(allCourses);
          //Calling Filter Function
          handleFilter(courseFilter, 'best-match', allCourses);
        }
    },[]);

    const handleFilter = (filterData, sortBy, courseList) => {
      setIsLoader(true)
      //Creating courses as per util function for filtering
      let filterForUtil = createFilterInputForUtil(filterData);

      //Calling Filter Function
      let filteredCourses = [];

      if (filterForUtil.selectedTopicsOfInterest.length > 0) {
        filteredCourses = filterCategories(courseList ? courseList : allCoursesList, filterForUtil);
      }

      let sortingParam = sortBy ? sortBy : sortType;

      //Check for the user for sorting
      const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';

      //Calling Sorting Function
      let sortedCourses = [];
      if(showingSearch) {
        sortedCourses = filteredCourses;
      } else {
        sortedCourses = applyLanguageBasedSorting(userLanguage, sortingParam, filteredCourses, filterForUtil);
      }

      console.log("applyLanguageBasedSorting", sortedCourses);

      postFilterImplementation(sortedCourses, filterData);
    }

    const postFilterImplementation = (sortedCourses, filterData) => {
      //Setting main sorted data for pagination
      setCoursesMainSortedDetailsData(sortedCourses);

      //Showing results based on number of tiles
      let clonedData = cloneDeep(sortedCourses);
      setCoursesDetailsData(clonedData?.splice(0, NUMBER_OF_TILES));
      setCoursesDetailsFilter(filterData);

      //Creating the array for the top selected filters
      let sortingData = [];
      filterData.forEach((filter) => {
        filter?.subCategory?.forEach((item) => {
          item.options.forEach((item) => {
            if(item.selected && !item.skipToShow) {
              let showingValue = item.showFilterName ? item.showFilterName : item.children;
              sortingData.push({label: showingValue, value: item.value, category: filter?.category});
            }
          });
        });
      });

      setCoursesDetailsFilterSortData(sortingData);
      store.dispatch(updateCoursesCategoryFilterData(cloneDeep(filterData)));
      setResetPagination(true);
      setIsLoader(false)
    }

    const applyLanguageBasedSorting = (userLanguage, sortType, filteredCourses, filterForUtil) => {
      switch(sortType) {
        case "best-match":
          switch (userLanguage){
            case "en":
              return bestMatchEnglishSorting(filteredCourses, filterForUtil);
            case "es":
              return bestMatchEnglishSorting(filteredCourses, filterForUtil).sort(sortBySpanishFirst);
          }
          break;
        case "popularity":
          switch (userLanguage){
            case "en":
              return filteredCourses.sort((a,b)=> (b.enrollmentCount) - (a.enrollmentCount));
            case "es":
              return filteredCourses.sort((a,b)=> (b.enrollmentCount) - (a.enrollmentCount)).sort(sortBySpanishFirst);
          }
          break;
        case "date":
          switch (userLanguage){
            case "en":
              return filteredCourses;
            case "es":
              return filteredCourses.sort(sortBySpanishFirst);
          }
          break;
      }
    }

    const sortBySpanishFirst = (a, b) => {
      if(a.locale.indexOf("es-ES") != -1) {
        return -1;
      } else if(b.locale.indexOf("es-ES") != -1) {
        return 1;
      }
      return 0;
    }

    const bestMatchEnglishSorting = (filteredCourses, filterForUtil) => {
      let sortedCourses = [];
      let topicOfInterest = filterForUtil.selectedTopicsOfInterest;
      let inProgressCourseIds = filteredCourses.filter(c => c.enrollmentStatus == "STARTED").map(c => c.id);
      let alternatingCourses = recurringFunctionForForcedRanking(topicOfInterest, structuredClone(recommendedCourses), inProgressCourseIds);
      let iAmNotSureArray = recommendedCourses["I'm not sure"];
      let completedCourseIds = filteredCourses.filter(c => c.enrollmentStatus == "COMPLETED").map(c => c.id);
      let listOfAllCourses = [];
      filteredCourses.forEach((course) => {listOfAllCourses[course.id] = course});

      let finalList = inProgressCourseIds;
      alternatingCourses.forEach((course) => {
        if(finalList.indexOf(course) == -1)
          finalList.push(course);
      });
      iAmNotSureArray.forEach((course) => {
        if(finalList.indexOf(course) == -1)
          finalList.push(course);
      });
      completedCourseIds.forEach((course) => {
        if(finalList.indexOf(course) == -1)
          finalList.push(course);
      });
      console.log("finalList", finalList);

      finalList.forEach((e) =>{
        if(listOfAllCourses[e]) {
          sortedCourses.push(listOfAllCourses[e]);
          delete listOfAllCourses[e];
        }
      });

      return sortedCourses.concat(Object.values(listOfAllCourses));
    }

    const bestMatchingSpanishSorting = (filteredCourses, filterForUtil) => {
      let sortedCourses = [];
      let topicOfInterest = filterForUtil.selectedTopicsOfInterest;
      let inProgressCourseIds = filteredCourses.filter(c => c.enrollmentStatus == "STARTED").map(c => c.id);
      console.log("inProgressCourseIds", inProgressCourseIds);
      let alternatingCourses = recurringFunctionForForcedRanking(topicOfInterest, structuredClone(recommendedCourses), []);
      console.log("alternatingCourses", alternatingCourses);
      let iAmNotSureArray = recommendedCourses["I'm not sure"];
      console.log("iAmNotSureArray", iAmNotSureArray);
      let completedCourseIds = filteredCourses.filter(c => c.enrollmentStatus == "COMPLETED").map(c => c.id);
      console.log("completedCourseIds", completedCourseIds);
      let listOfAllCourses = [];
      filteredCourses.forEach((course) => {listOfAllCourses[course.id] = course});

      let finalList = inProgressCourseIds;
      alternatingCourses.forEach((course) => {
        if(finalList.indexOf(course) == -1)
          finalList.push(course);
      });
      iAmNotSureArray.forEach((course) => {
        if(finalList.indexOf(course) == -1)
          finalList.push(course);
      });
      completedCourseIds.forEach((course) => {
        if(finalList.indexOf(course) == -1)
          finalList.push(course);
      });
      console.log("finalList", finalList);

      finalList.forEach((e) =>{
        if(listOfAllCourses[e]) {
          sortedCourses.push(listOfAllCourses[e]);
          delete listOfAllCourses[e];
        }
      });

      return sortedCourses.concat(Object.values(listOfAllCourses));
    }

    const recurringFunctionForForcedRanking = (attr, recommendedClone, finalArray) => {
      let array = [];
      attr.forEach((e) => {
        if(recommendedClone[e]?.length > 0){
          if(array.indexOf(recommendedClone[e][0]) != -1 || finalArray.indexOf(recommendedClone[e][0]) != -1) {
            let splitIndex = 0;
            let breaker = true;
            recommendedClone[e].forEach((courseId, index) => {
              if(breaker && recommendedClone[e]?.length > 0 && array.indexOf(courseId) == -1 && finalArray.indexOf(courseId) == -1) {
                splitIndex = index+1;
                breaker = false;
              }
            });
            recommendedClone[e].splice(0,splitIndex-1);
            array.push(recommendedClone[e].splice(0,1)[0]); 
          } else {
            array.push(recommendedClone[e].splice(0,1)[0]); 
          }
        }
      })
      if(array.length > 0){
        if(!finalArray) finalArray = [];
        finalArray = [...finalArray, ...array];
        return recurringFunctionForForcedRanking(attr,recommendedClone,finalArray);
      } else {
        return finalArray;
      }
    }

    const handleSorting = (sorting) => {
      setSortType(sorting);
      handleFilter(coursesDetailsFilter, sorting);
    }

    const handleRemoveFilter = (removedFilter, removedAllFilter, category) => {
      if (category == "Topic" && removedFilter != "View all") {
        if (coursesDetailsFilter[0].subCategory[0].options.filter(e => e.value == removedFilter).length > 0) {
          coursesDetailsFilter[0].subCategory[0].options[0].selected = false;
        }
      }
      //Removing Filter from the filter data 
      coursesDetailsFilter.forEach((filter) => {
        filter?.subCategory?.forEach((item) => {
          item.options.forEach((item) => {
            if(removedFilter != removedAllFilter) {
              if(item.showFilterName == removedAllFilter) {
                item.selected = false;
              } else if(item.value == removedFilter) {
                item.selected = false;
              }
            } else {
              if(item.value == removedFilter || item.showFilterName == removedFilter || item.children == removedFilter) {
                item.selected = false;
              }
            }
          });
        });
      });
      setCoursesDetailsFilter(coursesDetailsFilter);
      handleFilter(coursesDetailsFilter);
      // setCoursesDetailsFilterSortData(coursesDetailsFilterSortData.filter(e => e != removedFilter));
    }

    const handleRemoveAllFilter = () => {
      //Removing Filter from the filter data 
      coursesDetailsFilter.forEach((filter) => {
        filter?.subCategory?.forEach((item) => {
          item.options.forEach((item) => {
            if(coursesDetailsFilterSortData.indexOf(item.value) || coursesDetailsFilterSortData.indexOf(item.showFilterName)) {
              item.selected = false;
            }
          });
        });
      });
      setCoursesDetailsFilter(coursesDetailsFilter);
      handleFilter(coursesDetailsFilter);
      setCoursesDetailsFilterSortData([]);
      setCoursesDetailsData([]);
      store.dispatch(updateFilterData({
        type: "UPDATE_QUICK_TIPS_CATEGORY_PAGE_FILTERS",
        payload: cloneDeep(coursesDetailsFilter)
      }));
    }

    const handlePagination = (page) => {
      let clonedData = cloneDeep(coursesMainSortedDetailsData);
      setCoursesDetailsData(clonedData.splice((page - 1)*NUMBER_OF_TILES, NUMBER_OF_TILES));
      setResetPagination(false);
    }

    const loadNextCursor = () => {
      handleFilter(coursesDetailsFilter);
    }
    
    const getNoResultDiv = () => {
      if(showingSearch) {
        return <div className='n-textCenter'>
        <Title size='XLarge'>
          {t("Sorry, we couldn't find any results for ")} "{searchParams.get("query")}".
        </Title>
        <div className='mt-3X'>
          <Body size="large">{t('Try broadening your search terms.')}</Body>
        </div>
      </div>
      } else {
        return <div>
        <Title size='XLarge'>
          {t("No results available. Please select a filter option to update your results.")}
        </Title>
      </div>
      }
    }

    return (
        <section>
            <div className='categories-header bg-gray'>
                <Grid bleed="1440" colSizes={{ mobile: 4, tablet: 12, desktop: 12, }} >
                    {/*<Row>
                        <Col>
                            <div className='mv-8X bg-gray'>
                                <Breadcrumbs surface="light">
                                    <BreadcrumbItem href="/">{t("Home")}</BreadcrumbItem>
                                    <BreadcrumbItem>{t("Courses")}</BreadcrumbItem>
                                </Breadcrumbs>
                            </div>
                        </Col>
    </Row>*/}
            <Row>
              <Col>
                {coursesDetailsHeading && <CategoryHeadingComponent props={coursesDetailsHeading} />}
              </Col>
            </Row>
          </Grid>
        </div>     
        <div className='bg-white results-section pt-12X pb-16X'>
        {isLoader && <div className='category-loader-container LoadingIndicator'></div>}
        {!isLoader && <div>
          <Grid bleed="1440" colSizes={{ mobile: 4, tablet: 12, desktop: 12, }}>
            <Row>
              <Col>
                {/* <div>
                                <FeatureComponent type={"Course"}/>
                              </div> */}
                {coursesDetailsFilter && coursesDetailsHeading &&
                  <CategoryFiltersAndSortComponent filterSortData={coursesDetailsFilterSortData} filters={coursesDetailsFilter} sortingValues={sortingValues} handleSorting={handleSorting} handleRemoveFilter={handleRemoveFilter} handleFilter={handleFilter} handleRemoveAllFilter={handleRemoveAllFilter} resultSize={coursesDetailsData?.length} headingData={coursesDetailsHeading} search={showingSearch} toggle={false} totalResultCount={coursesDetailsData?.length}/>
                }
              </Col>
            </Row>
            <Row>
              <Col colSizes={{ mobile: 4, tablet: 3, desktop: 3, }}> {!isMobile && coursesDetailsFilter && coursesDetailsHeading && (
                <CategoryFiltersComponent filters={coursesDetailsFilter} handleFilter={handleFilter} headingData={coursesDetailsHeading} handleRemoveAllFilter={handleRemoveAllFilter}/>
              )}
              </Col>
              <Col colSizes={{ mobile: 4, tablet: 12, desktop: 9, }} >
                {coursesDetailsData.length > 0 ? <CategoryFilteredCardsComponent detailsData={coursesDetailsData} type="courses" numberOfTiles={NUMBER_OF_TILES} totalResults={coursesMainSortedDetailsData.length} handlePagination={handlePagination} resetPagination={resetPagination} firstCardDetails={firstCardDetails} showLoadMore={nextCursor != '' ? true : false} loadNextCursor={loadNextCursor} showingSearch={showingSearch} />
                : getNoResultDiv()
              }
              </Col>
            </Row>
          </Grid>
        </div>}
        </div>
      </section>
    );

};

export default CategoryCoursesComponent;