import { useEffect, useMemo , useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, forEach } from 'lodash';
import store from '../store/store';
import { updateRegistrationStepTopics, updateAEMJsonData, updateSpanish, updateUiLocaledata, updateLangSpanish } from '../store/aem';
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Icon } from "@vds/icons";
import Cookies from 'js-cookie';

const Header = () => {
  const urlForGlobalNavCSS = "https://www.verizon.com/etc/designs/vzwcom/gnav20/core.min.css";
  const urlForGlobalNavJS = global.config.AEM_NAV_URL + "etc/designs/vzwcom/gnav20/corporate.js";
  const allNotifications = useSelector((state) => state.AdobeResources.allNotifications);
  const userDataFromStore = useSelector((state) => state.data);
  const userLanguage = localStorage.getItem("lng") && localStorage.getItem("lng") === 'es' ? 'es' : 'en';
  
  let udpatedCiamSignInURL = `${global.config.CIAM_SIGN_IN_URL}${process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH}?ciamLang=${userLanguage}`;

  const [showContactModal, setShowContactModal] = useState(false)

  useEffect(() => {
    const linkTag = document.createElement("link");
    linkTag.href = urlForGlobalNavCSS;
    linkTag.rel = "stylesheet";
    linkTag.type = "text/css";
    document.head.appendChild(linkTag);

    const script = document.createElement("script");
    script.src = urlForGlobalNavJS;
    script.async = false;
    document.head.appendChild(script);

    // Func to handle navigation click events for Analytics
    const handleHeaderNavListeners = (event) => {
      let targetElement = event.target;
      const tagName = targetElement.tagName.toLowerCase();
      if (targetElement && (tagName === 'a') && !isEmpty(targetElement.href) && targetElement.href.includes('http')) {
        const link_identifier = !isEmpty(targetElement.id) ? targetElement.id : (!isEmpty(targetElement.ariaLabel) ? targetElement.ariaLabel : (!isEmpty(targetElement.innerHTML) ? targetElement.innerHTML : ''));
        const link_text = !isEmpty(targetElement.innerText) ? (targetElement.innerText).replace(/[\n\t]/g, '') : (!isEmpty(targetElement.textContent) ? (targetElement.textContent).replace(/[\n\t]/g, '') : '');
        const link_url = !isEmpty(targetElement.href) ? targetElement.href : '';
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event_data: null });  // Clear the previous event_data object.
        window.dataLayer.push({
          "event": "click",
          "detailed_event": "Navigation Link Clicked",
          "event_data": {
            "identifier": link_identifier,
            "link_text": link_text,
            "link_url": link_url
          }
        });
      }
    };

    const addHeaderNavListeners = () => {
      const primaryNavMenu = document.querySelectorAll('.gnav20-primary-menu');
      if (primaryNavMenu && primaryNavMenu.length > 0) {
        primaryNavMenu.forEach(primaryNavMenuElement => {
          primaryNavMenuElement.addEventListener('click', handleHeaderNavListeners);
          primaryNavMenuElement.addEventListener('touchstart', handleHeaderNavListeners);
        });
      } else {
        setTimeout(addHeaderNavListeners, 1000);
      }
    };
    addHeaderNavListeners();
  }, []);
  //Signin listener starts

 useEffect(() => {
    const handleSignInListeners = (event) => {
      let targetElement = event.target;
      const tagName = targetElement.tagName.toLowerCase();
      console.log("clicked sign in");
      if (targetElement && (tagName === 'a') && !isEmpty(targetElement.href) && targetElement.href.includes('http')) {
        document.cookie = `firstTimeLogin=true;domain=${global.config.APP_COOKIE_DOMAIN};path=/`

      }};
    const addSignInListener = () => {
    const signInLinks = document.querySelectorAll('a[id="gnav20-account-link-id"]');
    if (signInLinks && signInLinks.length > 0) {
      signInLinks.forEach(signInLink => {
        signInLink.addEventListener('click', handleSignInListeners);
        signInLink.addEventListener('touchstart', handleSignInListeners);
      });
    }
        else {
          setTimeout(addSignInListener, 1000);
        }
      };
      addSignInListener();
}, []);
  //Signin listener ends

  const openSupportModal = () => {
    //alert("add Support Modal Functionality here");
    setShowContactModal(true)
    document.getElementById('gnav20-closex').click();
   }
  const addSupportNavListners = () => {
    const supportLink = document.querySelectorAll(".gnav20 .gnav20-vsbdr-support");
    if(supportLink && supportLink.length > 0){
         for (i = 0; i < supportLink.length; i++) {
           supportLink[i].addEventListener("click", openSupportModal);
         }
      }else{
        setTimeout(addSupportNavListners, 1000)
      }
  }

  // useEffect(()=>{
  //   addSupportNavListners();
  // },[])

  useEffect(() => {

    const showHideHeaderPromoRibbon = (showPromo, promoText, datePlaceHolderTimeStamp) => {
      const headerPromoDiv = document.querySelector('.gnav20-promo');
      const headerPromoDivText = document.querySelector('.gnav20-promo-text');
      const currentTime = new Date().getTime() / 1000; // Get Current time in seconds since Epoch
      headerPromoDiv.style.display = 'none';

      if (showPromo) {
        headerPromoDivText.innerHTML = promoText;
        headerPromoDiv.style.display = '';
        document.querySelector('.gnav20-promo-text').innerHTML = promoText;
      }      
    };

    const addHeaderPromoRibbon = () => {
      if (allNotifications) {
        // Load Custom Notifications Msgs frm AEM
        let _headerPromoDetails = {};
        if (userLanguage && userLanguage === 'es' && allNotifications['es']) {
          if (allNotifications['es']?.Header_Promo) _headerPromoDetails = allNotifications['es'].Header_Promo;
        } else if (allNotifications['en']) {
          if (allNotifications['en']?.Header_Promo) _headerPromoDetails = allNotifications['en'].Header_Promo;
        }
        showHideHeaderPromoRibbon(_headerPromoDetails.showPromo, _headerPromoDetails.promoText, _headerPromoDetails.datePlaceHolderTimeStamp);
      }
    };

    const updateHeaderSignIn = () => {
      // Load Custom Notifications Msgs frm AEM
      const signInLinks = document.querySelectorAll('a[id="gnav20-account-link-id"]');
      if(signInLinks && signInLinks.length > 0) {
        const signInLink = Array.from(signInLinks).find(
          link => link.getAttribute('aria-label') === "Sign in" || link.getAttribute('aria-label') === "Ingresar" 
        );
        if(signInLink) {
          signInLink.href = udpatedCiamSignInURL;
      }
      }      
    };

    window.gnav20Ready = () => {
      addHeaderPromoRibbon();
      updateHeaderSignIn();
    }
  }, [allNotifications]);

  return (
  <>
    <div id="vz-gh20"></div>
    <Modal
    opened={showContactModal}
    surface="light"
    fullScreenDialog={false}
    disableAnimation={false}
    disableOutsideClick={false}
    ariaLabel="Testing Modal"
    closeButton={<span tabIndex={'0'} onKeyUp={e => { if (e.key == "Enter" || e.key == "Space")
     { setShowContactModal(false); } }} onClick={() => setShowContactModal(false)} className='modalIcon'><Icon
      name="close"
      size="medium"
      color="#000000"
    />
    </span>
    }
  >
    <ModalBody>
      <h2 tabIndex={'0'} className='contactTitle'>"Need help with your account?"</h2>
      <br />
    </ModalBody>
    <ModalFooter
      buttonData={{
        primary: {
          width: '100%',
          children: 'Email',
          onClick: () => { window.location.assign("mailto:xyz@test.com"); },
        },
        close: {
          width: '100%',
          children: 'Call 800.916.4351',
          onClick: () => {
            setShowContactModal(false);
            window.location.href = "tel:+12244404633";
          },
        },
      }}
    />
  </Modal>
</>
  );
};

export default Header;
