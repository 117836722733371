// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grants-container {
  background-color: #ff8027;
}
.grants-container .button-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.capital-funding-grants {
  background-color: #f3ede0;
}
.capital-funding-grants .container {
  max-width: 1000px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .capital-funding-grants .container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/GrantsComponent.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAIA;EACE,yBAAA;AADF;AAEE;EACE,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AAEI;EAPF;IAQI,sBAAA;IACA,uBAAA;IACA,2BAAA;EACJ;AACF","sourcesContent":[".grants-container {\n  background-color: #ff8027;\n\n  .button-area {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n}\n\n\n.capital-funding-grants{\n  background-color: #f3ede0;\n  .container{\n    max-width: 1000px;\n    margin:auto;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  \n    @media (max-width: 767px) {\n      flex-direction: column;\n      align-items: flex-start;\n      justify-content: flex-start;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
