const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

// Load app configuration
console.log("***process.env.REACT_APP_ENV: " + JSON.stringify(process.env));

const urlMappingForAuthenticatedPage = {
  '/grants':`${process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH}grants`,
  '/category/events':`${process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH}category/events`,
  '/category/courses':`${process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH}category/courses`,
  '/category/quicktips':`${process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH}category/quicktips`,
  '/category/offers':`${process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH}category/offers`,
  'courseDetials': ``, //Not set because we are using another config global.config.SSO_LOGIN_COURSE 
  '/networking':`${process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH}networking`,
  'resultsPage':`${process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH}results/`,
  '/category/learningpath':`${process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH}category/learningpath`,
};

let config = {
  AEM_URL: process.env.REACT_APP_AEM_URL,
  QA_LOC_URL: process.env.REACT_APP_QA_LOC_URL,
  reCAPTCHASiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  PLATFORM_API_URL: process.env.REACT_APP_PLATFORM_API_URL,
  CIAM_SIGN_IN_URL: process.env.REACT_APP_CIAM_SIGN_IN_URL,
  AUTO_LOGIN_URL: process.env.REACT_APP_AUTO_LOGIN_URL,
  SSO_LOGIN: process.env.REACT_APP_SSO_LOGIN,
  SSO_LOGIN_COURSE: process.env.REACT_APP_SSO_LOGIN_COURSE,
  ADOBE_SHARE_BASE_URL: process.env.REACT_APP_ADOBE_SHARE_BASE_URL,
  AEM_NAV_URL: process.env.REACT_APP_AEM_NAV_URL,
  AEM_APP_ID: process.env.REACT_APP_AEM_ID,
  CIAM_COOKIE_NAME:process.env.REACT_APP_CIAM_COOKIE_NAME,
  APP_COOKIE_DOMAIN:process.env.REACT_APP_COOKIE_DOMAIN,
  URL_MAPPING_AUTHENTICATED_USER:urlMappingForAuthenticatedPage,
  AUTHENTICATED_URL_BASE_PATH:process.env.REACT_APP_AUTHENTICATED_URL_BASE_PATH,
  SSO_LOGIN_WITHOUT_GA:process.env.REACT_APP_SSO_LOGIN_WITHOUT_GA
};

export default global.config = config;