import React , { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';

import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';

import CategoryHeadingComponent from './common/CategoryHeadingComponent.jsx';
import CategoryFeatureComponent from './common/CategoryFeatureComponent.jsx';
import CategoryFiltersAndSortComponent from './common/CategoryFiltersAndSortComponent.jsx';
import CategoryFiltersComponent from './common/CategoryFiltersComponent.jsx';
import CategoryFilteredCardsComponent from './common/CategoryFilteredCardsComponent.jsx';
import { quickTipFilters } from './mocks.js';
import { filterCategories, createFilterInputForUtil } from '../../utility/utils.js';
import { cloneDeep } from "lodash";
import store from '../../store/store.js'
import { updateQuickTipCategoryFilterData } from '../../store/adoberesources';
import mockdata from './mockdata.json';
import mockdataSpanish from './mockdataSpanish.json';

import './CategoryPage.scss';
import { Body, Feature, Title } from '@vds/typography';

const CategoryQuickTipsComponent = ({forcedRankingQuickTip, topics}) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [quickTipsDetailsHeading , setQuickTipsDetailsHeading] = useState(null);
    const [quickTipsDetailsFeature , setQuickTipsDetailsFeature] = useState();
    const [quickTipsDetailsFilterSortData , setQuickTipsDetailsFilterSortData] = useState();
    const [quickTipsDetailsFilter , setQuickTipsDetailsFilter] = useState(quickTipFilters);
    const [quickTipsDetailsData , setQuickTipsDetailsData] = useState();
    const [quickTipsMainSortedDetailsData , setQuickTipsMainSortedDetailsData] = useState();
    const allQuickTips = useSelector((state) => state.AdobeResources.allQuickTips);
    const quickTipCategoryPageFilters = useSelector((state) => state.AdobeResources.quickTipCategoryPageFilters);
    const [resetPagination, setResetPagination] = useState(true);
    const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
    const recommended = forcedRankingQuickTip.recommended;
    const [sortType, setSortType] = useState('best-match');
    const [courseFilter, setcourseFilter] = useState('best-match');
    const [filterData, setFilterData] = useState([]);
    const aemData = useSelector((state) => state.Aem.data);
    const [searchParams, setSearchParams] = useSearchParams();

    const NUMBER_OF_TILES = 17;
    const sortingValues = [
      {label: 'Best Match', value:'best-match'},
      {label: 'Date (newest to oldest)', value:'date'}
    ];

    const {
      labels: {
        courseDetailsPage: {
          registerSecTitle_Tips,
          registerSecDesc,
          registerButtonLabel,
          alreadyMemberLabel,
          signInLabel,
        },
      },
    } = aemData;

    const firstCardDetails = {
      registerSecTitle: registerSecTitle_Tips,
      registerSecDesc: registerSecDesc,
      registerButtonLabel: registerButtonLabel,
      alreadyMemberLabel: alreadyMemberLabel,
      signInLabel: signInLabel,
      resourceType: "Quick Tip"
    }

    let quickTipFilter = quickTipFilters;
    
    useEffect(()=>{
      localStorage.setItem('categoryPath',location.pathname);
    },[]);

    useEffect(() => {
        console.log("allQuickTips in the QuickTips Category", allQuickTips);
        //Mock API Call
        // let filterSortDataOnLoad = topics.filter(topic => businessData?.attributes[topic.label] == "Yes").map(topic => topic.label);
        setQuickTipsDetailsHeading({
          breadcrumbLabel: 'Quick Tips',
          label: 'Quick tips',
          subText: 'Better your business with brief, easy-to-follow lessons.',
          buttons: [],
          sideIcon: { name: '', color: '' },
        });
        
        //For authenticated user need to all topics of interest and Statuses (Seats available, Registered and Waitlisted) as selected
        //TODO: Once we get confirmation on Pre Recorded quickTips need to add that also in default
        //For Spanish Users selected filter is Topics:All and Language: All
        //I'm not sure selected users should have all topics selected
        const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
        let selectedTopics = [];
        //getting filters from redux store if available
        console.log("quickTipCategoryPageFilters", quickTipCategoryPageFilters);
        const resultTopic = searchParams.get("topic");
        if(quickTipCategoryPageFilters?.length > 0) {
          quickTipFilter = cloneDeep(quickTipCategoryPageFilters);
        } else {
          if(resultTopic && resultTopic != "") {
            quickTipFilter[0].subCategory[0].options.push({children: "View all", value: "View all", showFilterName: "All Topics", selected: false, skipToShow: true});
            topics.forEach((e) => {
              quickTipFilter[0].subCategory[0].options.push({children: t(e.label), value: e.label, selected: resultTopic == e.label ? true : false});
            });
          } else {
            quickTipFilter[0].subCategory[0].options.push({children: "View all", value: "View all", showFilterName: "All Topics", selected: true, skipToShow: true});
            topics.forEach((e) => {
              quickTipFilter[0].subCategory[0].options.push({children: t(e.label), value: e.label, selected: true});
            });
          }
        }

        //Remove language filter for english users
        if (userLanguage == "en") {
          quickTipFilter.splice(2,1);
        }

        //Langugae conversion
        quickTipFilter.forEach((filter) => {
          filter?.subCategory?.forEach((item) => {
            item.options.forEach((item) => {
              item.children = t(item.children);
            });
          });
        });
        
        //Calling Filter Function
        handleFilter(quickTipFilter);
    },[]);

    const handleFilter = (filterData, sortBy) => {
      //Creating quickTips as per util function for filtering
      let filterForUtil = createFilterInputForUtil(filterData);
      console.log("filterForUtil", filterForUtil);

      //Calling Filter Function
      let filteredQuickTips = [];
      if(filterForUtil.selectedTopicsOfInterest.length > 0) {
        filteredQuickTips = filterCategories(allQuickTips, filterForUtil);
      }

      console.log("filteredQuickTips", filteredQuickTips);
      
      let sortingParam = sortBy ? sortBy : sortType; 

      //Check for the user for sorting
      const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';

      //Calling Sorting Function
      let sortedQuickTips = applyLanguageBasedSorting(userLanguage, sortingParam, filteredQuickTips, filterForUtil);

      //Setting main sorted data for pagination
      setQuickTipsMainSortedDetailsData(sortedQuickTips);

      //Showing results based on number of tiles
      if(sortedQuickTips?.length > 0) {
        let clonedData = cloneDeep(sortedQuickTips);
        setQuickTipsDetailsData(clonedData.splice(0, NUMBER_OF_TILES));
        setQuickTipsDetailsFilter(filterData);
      } else {
        setQuickTipsDetailsData(sortedQuickTips);
        setQuickTipsDetailsFilter(filterData);
      }

      //Creating the array for the top selected filters
      let sortingData = [];
      filterData.forEach((filter) => {
        filter?.subCategory?.forEach((item) => {
          item.options.forEach((item) => {
            if(item.selected && !item.skipToShow) {
              let showingValue = item.showFilterName ? item.showFilterName : item.children;
              sortingData.push({label: showingValue, value: item.value});
            }
          });
        });
      });
      setQuickTipsDetailsFilterSortData(sortingData);
      store.dispatch(updateQuickTipCategoryFilterData(cloneDeep(filterData)));
      setResetPagination(true);
    }

    const applyLanguageBasedSorting = (userLanguage, sortType, filteredQuickTips, filterForUtil) => {
      switch(sortType) {
        case "best-match":
          switch (userLanguage){
            case "en":
              return bestMatchEnglishSorting(filteredQuickTips, filterForUtil);
            case "es":
              return bestMatchEnglishSorting(filteredQuickTips, filterForUtil).sort(sortBySpanishFirst);
          }
          break;
        case "date":
          switch (userLanguage){
            case "en":
              return filteredQuickTips.sort((a,b) => new Date(b.publishedOnDate) - new Date(a.publishedOnDate));
            case "es": //Can change later for optimization
              return filteredQuickTips.sort((a,b) => new Date(b.publishedOnDate) - new Date(a.publishedOnDate)).sort(sortBySpanishFirst);
          }
          break;
      }
    }

    const sortBySpanishFirst = (a, b) => {
      if(a.locale.indexOf("es-ES") != -1) {
        return -1;
      } else if(b.locale.indexOf("es-ES") != -1) {
        return 1;
      }
      return 0;
    }

    const bestMatchEnglishSorting = (filteredQuickTips, filterForUtil) => {
      let sortedCourses = [];
      let topicOfInterest = filterForUtil.selectedTopicsOfInterest;
      let alternatingCourses = recurringFunctionForForcedRanking(topicOfInterest, cloneDeep(recommended), []);
      let iAmNotSureArray = recommended["I'm not sure"];
      let listOfAllCourses = [];
      filteredQuickTips.forEach((course) => {listOfAllCourses[course.courseId] = course});

      let finalList = alternatingCourses;
      iAmNotSureArray.forEach((course) => {
        if(finalList.indexOf(course) == -1)
          finalList.push(course);
      });
      
      finalList = finalList.filter(function(item, pos) {
        return finalList.indexOf(item) == pos;
      });
      console.log("finalList", finalList);
      finalList.forEach((e) =>{
        if(listOfAllCourses[e]) {
          sortedCourses.push(listOfAllCourses[e]);
          delete listOfAllCourses[e];
        }
      });

      return sortedCourses.concat(Object.values(listOfAllCourses));
    }

    const recurringFunctionForForcedRanking = (attr, recommendedClone, finalArray) => {
      let array = [];
      attr.forEach((e) => {
          if(recommendedClone[e]?.length > 0){
            if(array.indexOf(recommendedClone[e][0]) != -1 || finalArray.indexOf(recommendedClone[e][0]) != -1) {
              let splitIndex = 0;
              let breaker = true;
              recommendedClone[e].forEach((courseId, index) => {
                if(breaker && recommendedClone[e]?.length > 0 && array.indexOf(courseId) == -1 && finalArray.indexOf(courseId) == -1) {
                  splitIndex = index+1;
                  breaker = false;
                }
              });
              recommendedClone[e].splice(0,splitIndex-1);
              array.push(recommendedClone[e].splice(0,1)[0]); 
            } else {
              array.push(recommendedClone[e].splice(0,1)[0]); 
            }
          }
      });
      
      if(array.length > 0){
        if(!finalArray) finalArray = [];
        finalArray = [...finalArray, ...array];
        return recurringFunctionForForcedRanking(attr,recommendedClone,finalArray);
      } else {
        return finalArray;
      }
    }

    const bestMatchingSpanishSorting = (filteredQuickTips) => {
      let enrolledQuickTips = [];
      let spanishQuickTips = [];
      let restAllQuickTips = [];

      filteredQuickTips.forEach((quickTip) => {
        if(quickTip.enrollmentStatus != "NOT_ENROLLED"){
          enrolledQuickTips.push(quickTip);
          return;
        }
        if(quickTip.locale.indexOf("es-ES") != -1){
          spanishQuickTips.push(quickTip);
          return;
        }
        restAllQuickTips.push(quickTip);
      });
      return enrolledQuickTips.concat(spanishQuickTips).concat(restAllQuickTips);
    }

    const handleSorting = (sorting) => {
      setSortType(sorting);
      handleFilter(quickTipsDetailsFilter, sorting);
    }

    const handleRemoveFilter = (removedFilter, removedAllFilter) => {

      if (quickTipsDetailsFilter[0].subCategory[0].options.filter(e => e.value == removedFilter).length > 0) {
        quickTipsDetailsFilter[0].subCategory[0].options[0].selected = false;
      }

      //Removing Filter from the filter data 
      quickTipsDetailsFilter.forEach((filter) => {
        filter?.subCategory?.forEach((item) => {
          item.options.forEach((item) => {
            if(removedFilter != removedAllFilter) {
              if(item.showFilterName == removedAllFilter) {
                item.selected = false;
              } else if(item.value == removedFilter) {
                item.selected = false;
              }
            } else {
              if(item.value == removedFilter || item.showFilterName == removedFilter || item.children == removedFilter) {
                item.selected = false;
              }
            }
          });
        });
      });
      setQuickTipsDetailsFilter(quickTipsDetailsFilter);
      handleFilter(quickTipsDetailsFilter);
      // setQuickTipsDetailsFilterSortData(quickTipsDetailsFilterSortData.filter(e => e != removedFilter));
    }

    const handleRemoveAllFilter = () => {
      //Removing Filter from the filter data 
      quickTipsDetailsFilter.forEach((filter) => {
        filter?.subCategory?.forEach((item) => {
          item.options.forEach((item) => {
            if(quickTipsDetailsFilterSortData.indexOf(item.value) || quickTipsDetailsFilterSortData.indexOf(item.showFilterName)) {
              item.selected = false;
            }
          });
        });
      });
      setQuickTipsDetailsFilter(quickTipsDetailsFilter);
      handleFilter(quickTipsDetailsFilter);
      setQuickTipsDetailsFilterSortData([]);
      setQuickTipsDetailsData([]);
      store.dispatch(updateFilterData({
        type: "UPDATE_QUICK_TIPS_CATEGORY_PAGE_FILTERS",
        payload: cloneDeep(quickTipsDetailsFilter)
      }));
    }

    const handlePagination = (page) => {
      let clonedData = cloneDeep(quickTipsMainSortedDetailsData);
      setQuickTipsDetailsData(clonedData.splice((page - 1)*NUMBER_OF_TILES, NUMBER_OF_TILES));
      setResetPagination(false);
    }

    return (
        <section>
            <div className='categories-header bg-gray'>
                <Grid bleed="1440" colSizes={{ mobile: 4, tablet: 12, desktop: 12, }} >
                   {/**<Row>
                        <Col>
                            <div className='mv-8X bg-gray'>
                                <Breadcrumbs surface="light">
                                    <BreadcrumbItem href="/">{t("Home")}</BreadcrumbItem>
                                    <BreadcrumbItem>{t("Quick Tips")}</BreadcrumbItem>
                                </Breadcrumbs>
                            </div>
                        </Col>
                    </Row> */} 
                    <Row>
                        <Col>
                            {quickTipsDetailsHeading && <CategoryHeadingComponent props={quickTipsDetailsHeading} />}
                        </Col>
                    </Row>
                </Grid>
            </div>
            <div className='bg-white results-section pt-8X sm-pt-8X pb-16X'>
                <Grid bleed="1440" colSizes={{ mobile: 4, tablet: 12, desktop: 12, }}>
                    <Row>
                        <Col>
                            <div>
                                {quickTipsDetailsFeature && <CategoryFeatureComponent data={quickTipsDetailsFeature} />}
                            </div>
                            {quickTipsDetailsFilter &&
                              <CategoryFiltersAndSortComponent filterSortData={quickTipsDetailsFilterSortData} filters={quickTipsDetailsFilter} sortingValues={sortingValues} handleSorting={handleSorting} handleRemoveFilter={handleRemoveFilter} handleFilter={handleFilter} handleRemoveAllFilter={handleRemoveAllFilter} resultSize={quickTipsDetailsData?.length} showSearchBar={false}/>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col colSizes={{ mobile: 4, tablet: 3, desktop: 3, }}> {!isMobile && quickTipsDetailsFilter && (
                            <CategoryFiltersComponent filters={quickTipsDetailsFilter} handleFilter={handleFilter} handleRemoveAllFilter={handleRemoveAllFilter}/>
                            )}
                        </Col>
                        <Col colSizes={{ mobile: 4, tablet: 12, desktop: 9,}} >
                            {quickTipsDetailsData?.length > 0 && <CategoryFilteredCardsComponent detailsData={quickTipsDetailsData} type="quickTips" numberOfTiles={NUMBER_OF_TILES} totalResults={quickTipsMainSortedDetailsData.length} handlePagination={handlePagination} resetPagination={resetPagination} firstCardDetails={firstCardDetails}/>}
                            {quickTipsDetailsData?.length == 0 && <Title size='XLarge'>{t('No results available. Please select a filter option to update your results.')}</Title>}
                        </Col>
                    </Row>
                </Grid>
            </div>
        </section>
    );

};

export default CategoryQuickTipsComponent;