import React , { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import moment from 'moment-timezone';

import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';

import CategoryHeadingComponent from './common/CategoryHeadingComponent.jsx';
import CategoryFeatureComponent from './common/CategoryFeatureComponent.jsx';
import CategoryFiltersAndSortComponent from './common/CategoryFiltersAndSortComponent.jsx';
import CategoryFiltersComponent from './common/CategoryFiltersComponent.jsx';
import CategoryFilteredCardsComponent from './common/CategoryFilteredCardsComponent.jsx';
import { eventFilters } from './mocks.js';
import { filterCategories, createFilterInputForUtil }  from '../../utility/utils.js';
import { cloneDeep } from "lodash";
import store from '../../store/store.js'
import { updateEventsCategoryFilterData } from '../../store/adoberesources';

import './CategoryPage.scss';
import { Body, Feature, Title } from '@vds/typography';

const CategoryEventsComponent = ({topics}) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [eventsDetailsHeading , setEventsDetailsHeading] = useState(null);
    const [eventsDetailsFeature , setEventsDetailsFeature] = useState();
    const [eventsDetailsFilterSortData , setEventsDetailsFilterSortData] = useState();
    const [eventsDetailsFilter , setEventsDetailsFilter] = useState(eventFilters);
    const [eventsDetailsData , setEventsDetailsData] = useState();
    const [eventsMainSortedDetailsData , setEventsMainSortedDetailsData] = useState();
    let allEventsWithTimeZone = useSelector((state) => state.AdobeResources.allEventsWithTimeZone);
    const eventCategoryPageFilters = useSelector((state) => state.AdobeResources.eventCategoryPageFilters);
    const aemData = useSelector((state) => state.Aem.data);
    const [filterData, setFilterData] = useState([]);
    const [sortType, setSortType] = useState('best-match');
    const [resetPagination, setResetPagination] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const { filterType } = useParams();
    const [isLoader, setIsLoader] = useState(true);
    const [showingSearch, setShowingSearch] = useState(filterType === "search" ? true : false);
    const [sortingValues, setSortingValues] = useState([
      { label: t('Best Match'), value: 'best-match' },
      { label: t('Start date/time'), value: 'start-date' }
    ]);
    const [nextCursor, setNextCursor] = useState('');
    
    const NUMBER_OF_TILES = 17;

    const {
      labels: {
        courseDetailsPage: {
          registerSecTitle_Event,
          registerSecDesc,
          registerButtonLabel,
          alreadyMemberLabel,
          signInLabel,
        },
      },
    } = aemData;

    const firstCardDetails = {
      registerSecTitle: registerSecTitle_Event,
      registerSecDesc: registerSecDesc,
      registerButtonLabel: registerButtonLabel,
      alreadyMemberLabel: alreadyMemberLabel,
      signInLabel: signInLabel,
      resourceType: "Event"
    }

    useEffect(()=>{
      localStorage.setItem('categoryPath',location.pathname);
    },[]);

    useEffect(() => {
      console.log("eventsMainSortedDetailsData", eventsMainSortedDetailsData);
    }, [eventsMainSortedDetailsData]);

    let eventFilter = eventFilters;

    useEffect(() => {
        console.log("allEventsWithTimeZone in the Events Category", allEventsWithTimeZone);
        //Mock API Call
        // let filterSortDataOnLoad = topics.filter(topic => businessData?.attributes[topic.label] == "Yes").map(topic => topic.label);
        setEventsDetailsHeading({
          breadcrumbLabel: 'Events',
          h1:'Events',
          h2:'From capitalizing on social media to managing money, learn critical skills that can move your business forward.',
          modalLinkHeading:'what types of course formats do we offer',
          modalLinkUrl:'',
          label: t('Events'),
          subText: 'Discover exciting ways to learn about crucial topics with other small business owners.',
          textLink: { label: 'What types of event formats do we offer?', link: '', tooltipText: '' },
          modalContent: [{label:'Ask the experts',text:'Expert workshops by industry leaders with a focus on Q&A to help you get answers to your specific questions.'},
          {label:'Hands-on help',text:'Get paired with an industry expert to work directly on a specific aspect of your business.'},
          {label:'Course Q&As',text:'Get even more out of your Digital Ready courses in live follow-up sessions led by the instructors themselves.'},
          {label:'Networking sessions',text:'Meet other small business owners and learn from your peers as you build your professional network.'}],
          buttons: [],
          sideIcon: { name: '', color: '' },
          toolTipHeading:'Get your next phone free',
          toolTipBody:'Up to $168 device payment purchase or full retail price purchase req’d. Less up to $168 promo credit applied over 24 mos.promo credit ends if req’s are no longer met 0% APR.'
        });
        
        //For authenticated user need to all topics of interest and Statuses (Seats available, Registered and Waitlisted) as selected
        //TODO: Once we get confirmation on Pre Recorded events need to add that also in default
        //For Spanish Users selected filter is Topics:All and Language: All
        //I'm not sure selected users should have all topics selected
        const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
        let selectedTopics = [];
        //getting filters from redux store if available
        console.log("eventCategoryPageFilters", eventCategoryPageFilters);
        const resultTopic = searchParams.get("topic");
        if(eventCategoryPageFilters?.length > 0) {
          eventFilter = cloneDeep(eventCategoryPageFilters);
        } else {
            if(resultTopic && resultTopic != "") {
              eventFilter[0].subCategory[0].options.push({children: "View all", value: "View all", showFilterName: "All Topics", selected: false, skipToShow: true});
              topics.forEach((e) => {
                eventFilter[0].subCategory[0].options.push({children: t(e.label), value: e.label, selected: resultTopic == e.label ? true : false});
              });
            } else {
              eventFilter[0].subCategory[0].options.push({children: "View all", value: "View all", showFilterName: "All Topics", selected: true, skipToShow: true});
              topics.forEach((e) => {
                eventFilter[0].subCategory[0].options.push({children: t(e.label), value: e.label, selected: true});
              });
            }
        }

        //Langugae conversion
        eventFilter.forEach((filter) => {
          if(filter.category == 'Dates') {
            
          } else {
          filter?.subCategory?.forEach((item) => {
            item.options.forEach((item) => {
              item.children = t(item.children);
            });
          });
          }
        });

        //Calling Filter Function
        if(filterType === "search") {
          setShowingSearch(true);
          //setSortingValues([]);
        }
        handleFilter(eventFilter);
    },[]);

    const handleFilter = (filterData, sortBy) => {
      //Creating events as per util function for filtering
      let filterForUtil = createFilterInputForUtil(filterData);

      if(showingSearch) {
        allEventsWithTimeZone = allEventsWithTimeZone.filter(e => e.title?.toLowerCase().indexOf(searchParams.get("query")?.toLowerCase()) != -1);
      }

      //Calling Filter Function
      let filteredEvents = [];
      
      if(filterForUtil.selectedTopicsOfInterest.length > 0) {
        let todayTime = new Date().getTime();
        console.log("allEventsWithTimeZone", allEventsWithTimeZone);
        allEventsWithTimeZone = allEventsWithTimeZone.filter(e => new Date(e.startDate).getTime() > todayTime);
        console.log("allEventsWithTimeZone", allEventsWithTimeZone);
        filteredEvents = filterCategories(allEventsWithTimeZone, filterForUtil);
      }

      console.log("filteredEvents", filteredEvents);
      
      let sortingParam = sortBy ? sortBy : sortType; 

      //Check for the user for sorting
      const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';

      //Calling Sorting Function
      let sortedEvents = applyLanguageBasedSorting(userLanguage, sortingParam, filteredEvents);

      //Setting main sorted data for pagination
      setEventsMainSortedDetailsData(sortedEvents);

      //Showing results based on number of tiles
      if(sortedEvents.length > 0) {
        let clonedData = cloneDeep(sortedEvents);
        setEventsDetailsData(clonedData.splice(0, NUMBER_OF_TILES));
        setEventsDetailsFilter(filterData);
      } else {
        setEventsDetailsData(sortedEvents);
        setEventsDetailsFilter(filterData);
      }

      //Creating the array for the top selected filters
      let sortingData = [];
      filterData.forEach((filter) => {
        if(filter.category == 'Dates') {
          if(filter.startDate) {
            let momentStartTime = moment(filter.startDate);
            sortingData.push();
            let showingDate = `${filter.startDateTitle} ${momentStartTime.format("MM/DD/yy")}`;
            sortingData.push({label: showingDate, value: showingDate});
          }
          if(filter.endDate) {
            let momentEndTime = moment(filter.endDate);
            let showingDate = `${filter.endDateTitle} ${momentEndTime.format("MM/DD/yy")}`;
            sortingData.push({label: showingDate, value: showingDate});
          }
          if(!filter.startDate && !filter.endDate) {
            sortingData.push({label: filter.viewAllDateTitle, value: filter.viewAllDateTitle});
          }
        } else {
        filter?.subCategory?.forEach((item) => {
          item.options.forEach((item) => {
            if(item.selected && !item.skipToShow) {
              let showingValue = item.showFilterName ? item.showFilterName : item.children;
              sortingData.push({label: showingValue, value: item.value});
            }
          });
        });
        }
      });
      console.log("sortingData", sortingData);
      setEventsDetailsFilterSortData(sortingData);
      store.dispatch(updateEventsCategoryFilterData(cloneDeep(filterData)));
      setResetPagination(true);
    }

    const applyLanguageBasedSorting = (userLanguage, sortType, filteredEvents) => {
      switch(sortType) {
        case "best-match":
          switch (userLanguage){
            case "en":
              return bestMatchEnglishSorting(filteredEvents);
            case "es":
              return bestMatchingSpanishSorting(filteredEvents);
          }
          break;
        case "start-date":
          switch (userLanguage){
            case "en":
              return filteredEvents;
            case "es":
              return filteredEvents.sort(sortBySpanishFirst);
          }
          break;
      }
    }

    const sortBySpanishFirst = (a, b) => {
      if(a.locale.indexOf("es-ES") != -1) {
        return -1;
      } else if(b.locale.indexOf("es-ES") != -1) {
        return 1;
      }
      return 0;
    }

    const bestMatchEnglishSorting = (filteredEvents) => {
      let registeredEvents = [];
      let waitListedEvents = [];
      let finalDayEnrollEvents = [];
      let availableSeatsEvents = [];
      let restAllEvents = [];

      filteredEvents.forEach((event) => {
        if(event.enrollmentStatus == "ENROLLED" || event.enrollmentStatus == "STARTED"){
          registeredEvents.push(event);
          return;
        }
        if(event.enrollmentStatus == "WAITING"){
          waitListedEvents.push(event);
          return;
        }
        if(event.isFinalDayEnrollment == true){
          finalDayEnrollEvents.push(event);
          return;
        }
        if(event.availableSeats > 0 || event.seatLimit == 0){
          availableSeatsEvents.push(event);
          return;
        }
        restAllEvents.push(event);
      });

      return registeredEvents.concat(waitListedEvents).concat(finalDayEnrollEvents).concat(availableSeatsEvents).concat(restAllEvents);
    }

    const bestMatchingSpanishSorting = (filteredEvents) => {
      let spanishEvents = [];
      let restAllEvents = [];

      filteredEvents.forEach((event) => {
        if(event.locale.indexOf("es-ES") != -1){
          spanishEvents.push(event);
          return;
        }
        restAllEvents.push(event);
      });
      return spanishEvents.concat(restAllEvents);
    }

    const handleSorting = (sorting) => {
      setSortType(sorting);
      handleFilter(eventsDetailsFilter, sorting);
    }

    const handleRemoveFilter = (removedFilter, removedAllFilter) => {
      console.log("handleRemoveFilter", removedFilter)

      if (eventsDetailsFilter[0].subCategory[0].options.filter(e => e.value == removedFilter).length > 0) {
        eventsDetailsFilter[0].subCategory[0].options[0].selected = false;
      }

      //Removing Filter from the filter data 
      eventsDetailsFilter.forEach((filter) => {
        if(filter.category == 'Dates') {
          if(filter.startDate && removedFilter?.toLowerCase().indexOf('start') != -1) {
            delete filter.startDate;
          }
          if(filter.endDate && removedFilter?.toLowerCase().indexOf('end') != -1) {
            delete filter.endDate;
          }
        } else {
          filter?.subCategory?.forEach((item) => {
            item.options.forEach((item) => {
              if(removedFilter != removedAllFilter) {
                if(item.showFilterName == removedAllFilter) {
                  item.selected = false;
                } else if(item.value == removedFilter) {
                  item.selected = false;
                }
              } else {
                if(item.value == removedFilter || item.showFilterName == removedFilter || item.children == removedFilter) {
                  item.selected = false;
                }
              }
            });
          });
        }
      });
      setEventsDetailsFilter(eventsDetailsFilter);
      handleFilter(eventsDetailsFilter);
      // setEventsDetailsFilterSortData(eventsDetailsFilterSortData.filter(e => e != removedFilter));
    }

    const handleRemoveAllFilter = () => {
      //Removing Filter from the filter data 
      eventsDetailsFilter.forEach((filter) => {
        filter?.subCategory?.forEach((item) => {
          item.options.forEach((item) => {
            if(eventsDetailsFilterSortData.indexOf(item.value) || eventsDetailsFilterSortData.indexOf(item.showFilterName)) {
              item.selected = false;
            }
          });
        });
      });
      setEventsDetailsFilter(eventsDetailsFilter);
      handleFilter(eventsDetailsFilter);
      setEventsDetailsFilterSortData([]);
      setEventsDetailsData([]);
      store.dispatch(updateFilterData({
        type: "UPDATE_QUICK_TIPS_CATEGORY_PAGE_FILTERS",
        payload: cloneDeep(eventsDetailsFilter)
      }));
    }

    const handlePagination = (page) => {
      let clonedData = cloneDeep(eventsMainSortedDetailsData);
      setEventsDetailsData(clonedData.splice((page - 1)*NUMBER_OF_TILES, NUMBER_OF_TILES));
      setResetPagination(false);
    }

    const getNoResultDiv = () => {
      if(showingSearch) {
        return <div className='n-textCenter'>
        <Title size='XLarge'>
          {t("Sorry, we couldn't find any results for ")} "{searchParams.get("query")}".
        </Title>
        <div className='mt-3X'>
          <Body size="large">{t('Try broadening your search terms.')}</Body>
        </div>
      </div>
      } else {
        return <div>
        <Title size='XLarge'>
          {t("No results available. Please select a filter option to update your results.")}
        </Title>
      </div>
      }
    }
  
    // if (!eventsDetails) return <div>Loading...</div>;

    return (
        <section>
            <div className='categories-header bg-gray'>
                <Grid bleed="1440" colSizes={{ mobile: 4, tablet: 12, desktop: 12, }} >
                   {/** <Row>
                        <Col>
                            <div className='mv-8X bg-gray'>
                                <Breadcrumbs surface="light">
                                    <BreadcrumbItem href="/">{t("Home")}</BreadcrumbItem>
                                    <BreadcrumbItem>{t("Events")}</BreadcrumbItem>
                                </Breadcrumbs>
                            </div>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col>
                            {eventsDetailsHeading && <CategoryHeadingComponent props={eventsDetailsHeading} />}
                        </Col>
                    </Row>
                </Grid>
            </div>
            <div className='bg-white results-section pt-12X sm-pt-8X pb-16X'>
                <Grid bleed="1440" colSizes={{ mobile: 4, tablet: 12, desktop: 12, }}>
                    <Row>
                        <Col>
                            <div>
                                {eventsDetailsFeature && <CategoryFeatureComponent data={eventsDetailsFeature} />}
                            </div>
                            {eventsDetailsFilter && eventsDetailsHeading && eventsDetailsFilterSortData &&
                              <CategoryFiltersAndSortComponent filterSortData={eventsDetailsFilterSortData} filters={eventsDetailsFilter} sortingValues={sortingValues} handleSorting={handleSorting} handleRemoveFilter={handleRemoveFilter} handleFilter={handleFilter} handleRemoveAllFilter={handleRemoveAllFilter} resultSize={eventsDetailsData?.length} headingData={eventsDetailsHeading} search={showingSearch} toggle={false} totalResultCount={eventsMainSortedDetailsData?.length}/>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col colSizes={{ mobile: 4, tablet: 3, desktop: 3, }}> {!isMobile && eventsDetailsFilter && eventsDetailsHeading && (
                            <CategoryFiltersComponent filters={eventsDetailsFilter} handleFilter={handleFilter} headingData={eventsDetailsHeading} handleRemoveAllFilter={handleRemoveAllFilter} />
                            )}
                        </Col>
                        <Col colSizes={{ mobile: 4, tablet: 12, desktop: 9,}} >
                            {eventsDetailsData?.length > 0 && <CategoryFilteredCardsComponent detailsData={eventsDetailsData} type="events" numberOfTiles={NUMBER_OF_TILES} totalResults={eventsMainSortedDetailsData.length} handlePagination={handlePagination} resetPagination={resetPagination} firstCardDetails={firstCardDetails}/>}
                            {eventsDetailsData?.length == 0 && getNoResultDiv()}
                        </Col>
                    </Row>
                </Grid>
            </div>
        </section>
    );

};

export default CategoryEventsComponent;