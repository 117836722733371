import { ButtonGroup, TextLink } from '@vds/buttons';
import React from 'react';
import './preview.scss';
import { Icon } from '@vds/icons';
import { Body, Title } from '@vds/typography';
import { isMobile } from 'react-device-detect';
import { CourseVideoPlayer } from '../../elements/VideoPlayer/CourseVideoPlayer';

const PreviewCourseContent = ({ preview }) => {
  const imageUrl = 'https://mbqa1.sdc.vzwcorp.com/content/dam/vsbdr/images/tips-and-resources/tipsandresources_preview3.png';//"https://example.com/path/to/image.jpg"; // replace with your image URL

  return (
    <>
    <div 
    // style={{
    //   backgroundImage: `url(${imageUrl})`,
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'center',
    // }} className='preview-container'
    >


      <div className='button-container'>
        {/* {!completed && (
          <ButtonGroup
            childWidth={'100%'}
            viewport={'desktop'}
            surface="dark"
            rowQuantity={{ desktop: !isMobile ? 2 : 1, mobile: 1 }}
            data={[
              {
                children: 'Start course',
                size: 'large',
                use: 'primary',
                width: 'auto',
                onClick: () => alert('You clicked the Button example!'),
              },
              {
                children: 'Watch preview',
                size: 'large',
                use: 'secondary',
                width: 'auto',
                onClick: () => alert('You clicked the Button example!'),
              },

            ]}
            alignment={'center'}
          />)} */}
      </div>


    </div>    
    <CourseVideoPlayer role="video" src={preview?.previewVideo?.location} poster={preview?.previewImage?.location} />
    </>
  );
};

export default PreviewCourseContent;