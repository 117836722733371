import { DropdownSelect } from '@vds/selects';
import './Categories.scss';
import React, { useEffect,useState } from 'react';
import { Body } from '@vds/typography';
import { Icon } from '@vds/icons';
import { Button, TextLink } from '@vds/buttons';
import { Line } from '@vds/lines';
import { Modal, ModalBody, ModalTitle, ModalFooter } from '@vds/modals';
import { useSelector } from 'react-redux';

import { isMobile } from 'react-device-detect';

import MultiselectChipsOffers from './MultiselectChipsOffers';
const FiltersAndSortComponent = ({ chips, options, filterResults,sortResults,selectedSort }) => {
  const aemData = useSelector((state) => state.Aem.data);
  const offersDropDownOptions = [aemData?.offers?.datefilter,aemData?.offers?.Featured];
 
  const [sortModalState, setSortModalState] = useState(false);

  const onSelect = (selectedItem) =>{
    setSortModalState(false);
    sortResults(selectedItem);
  }



  return (
    <div className='u-flex-apart m-flex-colrev pv-8X sm-pt-0X pb-16X sm-pb-8X'>
      <div className='width70'>
        {!chips && (
          <div className='u-flex-wrap'>
            {options.map((item, n) => {
              return (<div key={n} className='u-flex chips mr-3X mb-3X'><Body size='small'>{item}</Body> <span className='ml-2X u-flex'><Icon
                name="close"
                size="small"
                color="#000000"

              />
              </span></div>);
            })}
            {options.length > 0 ? (
              <span className='mb-2X'>
                <TextLink
                  type="standAlone"
                  size='small'
                  onClick={e => alert('TextLink clicked')}>
                  Clear All
                </TextLink>
              </span>
            ) : ''}
          </div>
        )}
        {chips && (
          <div className='u-flex-wrap'>
            <MultiselectChipsOffers optionItems={options} selected={options?.[0]} onChange={e => filterResults(e)} orOption={[{ label: "I'm not sure" }]} maxSelect={4} />
          </div>
        )}
      </div>

      {isMobile && (
        <div className="mb-5X width-100">
        <DropdownSelect
          label={aemData?.offers?.sortBy}
          errorText='Select a state'
          error={false}
          disabled={false}
          readOnly={false}
          inlineLabel={true}
          value={selectedSort ?? offersDropDownOptions[0]}
          onChange={e=>onSelect(e.target.value)}
        >
          {offersDropDownOptions.map(item => <option key={item}>{(item)}</option>)}
        </DropdownSelect>
        </div>


      )}

      <div className={isMobile ? 'u-flex-apart-end  sort-option' : 'u-flex-apart sort-option'}>
        {isMobile && (
          <div className='u-flex'>
            {/* {!chips &&  <Modal
              toggleButton={<Button use='secondary' size='small'> Filter </Button>}
              surface="light"
              fullScreenDialog={false}
              disableAnimation={false}
              disableOutsideClick={false}
              ariaLabel="Sort Modal"
            >
              <ModalTitle>
                Filters
              </ModalTitle>
              <ModalBody>
                <FilterComponent filters={filters}></FilterComponent>
              </ModalBody>
              <ModalFooter
                buttonData={{
                  primary: {
                    width: '100%',
                    children: 'Filter',
                    onClick: () => alert('Clicked Primary'),
                  },
                  close: {
                    width: '100%',
                    children: 'Reset',
                    onClick: () => alert('Clicked Secondary'),
                  },
                }}
              />
            </Modal>} 
            <div className='ml-2X'>
              <Modal
                toggleButton={<Button use='secondary' size='small' onClick={e=> setSortModalState(true)}> Sort </Button>}
                opened={sortModalState}
                surface="light"
                fullScreenDialog={false}
                disableAnimation={false}
                disableOutsideClick={false}
                ariaLabel="Sorting Modal"
              >
                <ModalTitle>
                  Sort
                </ModalTitle>
                <ModalBody>
                  <DropdownSelect
                    label={aemData?.offers?.sortBy}
                    errorText='Select a state'
                    error={false}
                    disabled={false}
                    readOnly={false}
                    inlineLabel={true}
                    value={selectedSort ?? offersDropDownOptions[0]}
                    onChange={e => {setSortModalState(false);onSelect(e.target.value)}}
                  >
                    {offersDropDownOptions.map(item => <option key={item}>{(item)}</option>)}
                  </DropdownSelect>
                </ModalBody>
                {/* <ModalFooter
                  buttonData={{
                    primary: {
                      width: '100%',
                      children: 'Filter',
                      onClick: () => alert('Clicked Primary'),
                    },
                    close: {
                      width: '100%',
                      children: 'Reset',
                      onClick: () => alert('Clicked Secondary'),
                    },
                  }}
                /> 
              </Modal>
            
          </div>*/}

          </div>
        )}
        {!isMobile && (<DropdownSelect
          label={aemData?.offers?.sortBy}
          errorText='Select a state'
          error={false}
          disabled={false}
          readOnly={false}
          inlineLabel={true}
          value={selectedSort ?? offersDropDownOptions[0]}
          onChange={e=>onSelect(e.target.value)}
        >
          {offersDropDownOptions.map(item => <option key={item}>{(item)}</option>)}
        </DropdownSelect>)}

      </div>



    </div >
  );
};

// class QuickTipsCategoryComponent extends React.Component {
//   render() {
//     return (
//       <div>qtc</div>
//     );
//   }
// }
export default FiltersAndSortComponent;
