import React, { useRef, useEffect } from 'react';
import './superBowl.scss';
import SuperBowlHeader from '../SuperBowl/SuperBowlHeader/SuperBowlHeader.jsx';
import SuperBowlEditorial from './SuperBowlEditorial/SuperBowlEditorial.jsx';
import ApplicationSubmissionLinkNotification from './ApplicationSubmissionLink/ApplicationSubmissionLink.jsx';
import { Body, Title } from '@vds/typography';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../store/store.js';
import { updateRegisterTriggeredFrom } from '../../../store/aem.js';
import { useNavigate } from 'react-router-dom';
import { Line } from '@vds/lines';
import { ButtonGroup } from '@vds/buttons';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionHeader, AccordionItem, AccordionDetail, AccordionTitle } from '@vds/accordions';
import { userRegistrationClick, pageLoadStarted, pageLoadCompleted } from '../../../common/Analytics/GAWrapper.jsx';
import { Col, Grid, Row } from '@vds/grids';
import DOMPurify from 'dompurify';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';


const SuperbowlGrantDetailsLanding = () => {
    const faqListRef = useRef(null);
    const aemData = useSelector((state) => state.Aem.data);
    const aemFAQ = useSelector((state) => state.Aem.superbowlFAQ);
  const location = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const handleFaqClick = () => {
        faqListRef.current.scrollIntoView({ behaviour: 'smooth', block: 'start' });
        faqListRef.current.focus();
      };

    useEffect(() => { 
    pageLoadStarted("Superbowl", window.location.origin + localStorage.getItem('prevUrl'), '');
      //localStorage.setItem('prevUrl',window.location.href);
      pageLoadCompleted();
      // window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get('scroll_to');
    //debugger;

    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      // const scrollToOffset = element
      if (element) {
        element.scrollIntoView({ block: 'start' });
        // if(isMobile) {
          const scrollInterval = setInterval(() => {
            element.scrollIntoView({ block: 'start' })
            clearInterval(scrollInterval);
          }, 500);
        // }
      }
    } else {
      window.scrollTo({ top: 0 });
    }
  }, [location]);

    return (
        <>
        <div className="superbowl grants bg-white">
            <div className='superbowl-container'>
                <div className='bg-white'>
                    <SuperBowlHeader />
                </div>
                <SuperBowlEditorial />
            </div>
        <div className='grant-status pb-16X sm-pb-12X  pt-24X sm-pt-16X '>
          <div className='carousel-container-fix sm-ph-4X'>
                    <Title size='XLarge' bold>
                      
                      {aemData?.superbowl?.superbowlRegisterTxt}
                    </Title>
                    <div className='mt-4X'>
                      <Title size='medium'>{aemData?.superbowl?.superbowlRegisterTxt1}</Title>
                    </div>
                    <div className={aemData?.superbowl?.superbowlcompleteTxt && aemData?.superbowl?.superbowlcompleteTxt != '' ? 'instructions mv-16X sm-mv-12X' : 'instructions mt-6X'}>
                      <div>
                        <Title size='medium' bold>{aemData?.grants?.whocanApplyTxt}</Title>
                        {aemData?.grants?.whocanApplyTxt && aemData?.grants?.whocanApplyTxt != '' && <div className='mv-4X'>
                         <Line></Line>
                        </div>}
                        <div>
                          <Title size='medium'>{aemData?.superbowl?.superbowlowners18Txt}</Title>
                        </div>
                      </div>
                      <div>
                        <Title size='medium' bold>{aemData?.superbowl?.superbowlhowApplyTxt}</Title>
                        <div>
                        {aemData?.superbowl?.superbowlhowApplyTxt && aemData?.superbowl?.superbowlhowApplyTxt != '' && <div className='mv-4X'>
                        <Line></Line>
                        </div>}
                        {aemData?.superbowl?.superbowlcompleteTxt && aemData?.superbowl?.superbowlcompleteTxt != '' && <div>
                          <Title size='medium'>
                          {aemData?.superbowl?.superbowlcompleteTxt}
                          {/* {t('Complete two grant-eligible courses or events between ')} {new Date(+aemIncentivesData.filter(val => val.id === 'lisc')[0].rules.startTimeBoundary).toDateString().split(' ').slice(1).join(' ')} {t('and')} {new Date(+aemIncentivesData.filter(val => val.id === 'lisc')[0].rules.endTimeStamp).toDateString().split(' ').slice(1).join(' ')} {t('to unlock the application, then submit the application by')} {new Date(+aemIncentivesData.filter(val => val.id === 'lisc')[0].rules.endTimeStamp).toDateString().split(' ').slice(1).join(' ')}. {t(' Late applications will not be considered.')} */}
                          </Title>
                        </div>}
                        </div>
                      </div>
                    </div>
                    <div className='button'>
                      <ButtonGroup
                        // childWidth={'100%'}
                        viewport={'desktop'}
                        rowQuantity={{ desktop: 2 }}
                        data={[
                          {
                            children: aemData?.grants?.register,
                            size: 'large',
                            use: 'primary',
                            // width: '280px',
                            onClick: () => {
                              userRegistrationClick('Superbowl', aemData?.grants?.register, window.location.host + '/onboarding');
                              store.dispatch(updateRegisterTriggeredFrom(location.pathname));
                              sessionStorage.setItem('navigateToSuperBowl',true);
                              localStorage.setItem('prevUrl', window.location.href);
                              navigate('/onboarding');                              
                            },
                          },
                          {
                            children: aemData?.superbowl?.superbowlFAQ,
                            size: 'large',
                            use: 'secondary',
                            // width: '280px',
                            onClick: handleFaqClick,
                          },

                        ]}
                        alignment={'center'}
                      />
                    </div>
                  </div>
        </div>
        <Grid
          bleed="1440"
          colSizes={{
            mobile: 4,
            tablet: 12,
            desktop: 12,
          }}
        >
          <Row >
            <Col >
              <div className='faqs mv-24X sm-mv-16X'>
                <div tabIndex="0" ref={faqListRef}>
                  <Title size='XLarge' bold>{aemData?.grants?.frequently}</Title>
                </div>
                <div className='mt-12X'>
                  <Accordion id="faq"
                    bottomLine={true}
                  >
                    {aemFAQ && aemFAQ.map(item => {
                      let _element = <AccordionItem>
                        <AccordionHeader
                          trigger={{
                            type: "icon",
                            alignment: "top"
                          }}
                        >
                          <AccordionTitle>{t(item.text)}</AccordionTitle>
                        </AccordionHeader>
                        <AccordionDetail>
                          {/* <div className='faq-answers'>{console.log(">>>>>fAQANS",item.subText)}{t(item.subText)}<br /><br />{t(item.subText1)}</div> */}
                          <div className='faq-answers' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.subText, { ADD_ATTR: ['target'] })}} />
                          </AccordionDetail>
                      </AccordionItem>;
                      return _element;

                    })}
                  </Accordion>
                </div>
                </div>
            </Col>
          </Row>
        </Grid>



        <ApplicationSubmissionLinkNotification />

                <div className="pv-24X sm-pv-16X superbowl-promotion-container sm-ph-4X" id="promotion_details">
                    <Title size='XLarge' bold>
                        <div className='n-textCenter'>
                        {aemData?.superbowl?.superbowlPromoDetailsTxt}

                        </div>
                    </Title>
                    <div className='mt-12X  sm-mt-8X'>
                        <div className='mb-2X'>
                        <Title size='medium' bold>{aemData?.superbowl?.superbowlSocialMediaTxt}</Title> 
                        </div>
                        {aemData?.superbowl?.superbowlSocialMediaSubTxt && aemData?.superbowl?.superbowlSocialMediaSubTxt != '' && <div style={{fontFamily:'Verizon-NHG-eDS',fontSize:'16px', fontWeight:"400",lineHeight:'20px'}} className='mb-6X' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(aemData?.superbowl?.superbowlSocialMediaSubTxt)}} />}
                    </div>
                    <div className='mt-12X sm-mt-8X'>
                        <div className='mb-2X'>
                        <Title size='medium' bold>{aemData?.superbowl?.nflDisclaimer}</Title> 
                        </div>
                        <Body size='large'>
                        {aemData?.superbowl?.nflDisclaimerText1}
                        </Body><br></br>
                        <Body size='large'>
                        {aemData?.superbowl?.nflDisclaimerText2}
                        </Body>
                        <Body size='large'>
                        {aemData?.superbowl?.nflDisclaimerText3}
                        </Body><br></br>
                        <Body size='large'>
                        {aemData?.superbowl?.nflDisclaimerText4}
                        </Body>
                    </div>

                </div>


        </div>
      
    
      </>

    );
};

export default SuperbowlGrantDetailsLanding;