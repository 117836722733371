import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import CourseDetails from "./CourseContentPage";
import CourseOverview from "./CourseOverviewPage";
import { getWithExpiry } from '../utility/utils'

const CourseContentWrapperComponent = () => {
    let courseType = 'quicktips';
    let { courseId } = useParams();
    const allQuickTips = useSelector((state) => state.AdobeResources.allQuickTips);
    const aemData = useSelector((state) => state.Aem.data);
    const offersMock = useSelector((state) => state.Aem.offerData);
    const quickTips = useSelector((state) => state.Aem.quickTipData);
    const allEventsWithTimeZone = useSelector((state) => state.AdobeResources.allEventsWithTimeZone);
    let suggestion = getWithExpiry('suggestion');
    if (courseId.slice(0, 2) === 'id' || courseId === 'capital-offering') {
        courseType = 'offers';
    }
    if (suggestion?.events[courseId]) {
        courseType = 'event';
      } else if (suggestion?.courses[courseId]) {
        courseType = 'course';
      } else if (suggestion?.learningProgram[courseId]) {
        courseType = 'learningProgram';
      }
    // return <>
    // {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
    // </>;
    const renderComponentBasedOnCourseType = () => {
        switch (courseType) {
          case 'quicktips' : 
            return (<>
            {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
            </>);
          case 'event' : 
          return (<>
            {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
            </>);
          case 'offers' : 
            return (<>
            {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
            </>);
          case 'course' : 
            return (<>
            {/* {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseOverview allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />} */}
            {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
            </>);
          case 'learningProgram' : 
            return (<> 
             {allQuickTips && quickTips && offersMock && aemData && allEventsWithTimeZone && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
            </>);
        }
    }
  
    return (
      <div>
          {renderComponentBasedOnCourseType()}
      </div>
    );
}

export default CourseContentWrapperComponent;