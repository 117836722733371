import { Body, Title } from '@vds/typography';
import React, { useState } from 'react';
import { Icon } from '@vds/icons';
import './resourcesOverview.scss';
import { Button } from '@vds/buttons';
import { useTranslation } from 'react-i18next';

const ResourcesOverview = ({ resourcesDetails }) => {
  const [expandedResources, setExpandedResources] = useState([]);
  const { t, i18n } = useTranslation();

  const handleExpandClick = (index) => {
    if (expandedResources.includes(index)) {
      setExpandedResources(expandedResources.filter(i => i !== index));
    } else {
      setExpandedResources([...expandedResources, index]);
    }
  };

  return (
    <div id='tabSectionsId' className='grey-widget pa-8X sm-ph-4X'>
      <div className='pb-3X'>
        <Title size='large' bold>{t("Courses sections.")}</Title>
      </div>
      {resourcesDetails.map((resource, index) => (
        <div className='accordian-container pv-6X' key={index}>
          <div className='accordian-labels' onClick={() => handleExpandClick(index)}>
            <div className="content">
              <div className='pr-2X'><Icon name={resource.icon.name} size='28px' color={resource.icon.color}></Icon></div>
              <div>
                <Title size='medium'>{resource.title}</Title>
                <div className='mt-1X'>
                  <Body size='medium'><b>{resource.time}</b> </Body>
                </div>
              </div>
            </div>

            <div className='accordian-icon'>
              <Icon name={expandedResources.includes(index) ? 'up-caret' : 'down-caret'} size='24px' color='#000'></Icon>
            </div>
          </div>
          {expandedResources.includes(index) && (
            <div className='accordian-content pl-1X ml-8X pt-4X'>
              <div className='section-details'>
                <Body size='small'>
                  <b>{resource.sectionDetailTitle}</b>
                  <div>
                    {resource.sectionDetailContent}
                  </div>
                </Body>
                
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ResourcesOverview;

// import { Body, Title } from '@vds/typography';
// import React, { useState } from 'react';
// import { Icon } from '@vds/icons';
// import './resourcesOverview.scss';
// import { Button } from '@vds/buttons';


// const ResourcesOverview = () => {
//   let resoursesDetails = [{
//     icon: { name: 'education', size: '28px', color: '#00' },
//     title: 'Intro quiz',
//     time: '4 minutes',
//     status: 'Not started',
//     sectionDetailTitel: 'Section details',
//     sectionDetailContent: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//     collapsed: false,
//     button: {
//       buttonLabel: 'Go to section',
//       buttonAction: () => alert('Button clicked'),
//       buttonType: 'secondary'
//     }
//   },
//   {
//     icon: { name: 'alternate-checkmark', size: '28px', color: '#008331' },
//     title: 'Video',
//     time: '4 minutes',
//     status: 'Not started',
//     sectionDetailTitel: 'Section details',
//     sectionDetailContent: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//     collapsed: false,
//     button: {
//       buttonLabel: 'Go to section',
//       buttonAction: () => alert('Button clicked'),
//       buttonType: 'secondary'
//     }
//   },
//   {
//     icon: { name: 'sync', size: '28px', color: '#006FC1' },
//     title: 'Knowledge check',
//     time: '4 minutes',
//     status: 'Not started',
//     sectionDetailTitel: 'Section details',
//     sectionDetailContent: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//     collapsed: true,
//     button: {
//       buttonLabel: 'Go to section',
//       buttonAction: () => alert('Button clicked'),
//       buttonType: 'secondary'
//     }
//   }, {
//     icon: { name: 'education', size: '28px', color: '#00' },
//     title: 'Activity',
//     time: '4 minutes',
//     status: 'Not started',
//     sectionDetailTitel: 'Section details',
//     sectionDetailContent: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//     collapsed: false,
//     button: {
//       buttonLabel: 'Go to section',
//       buttonAction: () => alert('Button clicked'),
//       buttonType: 'secondary'
//     }
//   }, {
//     icon: { name: 'education', size: '28px', color: '#00' },
//     title: 'Feedback survey',
//     time: '4 minutes',
//     status: 'Not started',
//     sectionDetailTitel: 'Section details',
//     sectionDetailContent: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//     collapsed: false,
//     button: {
//       buttonLabel: 'Go to section',
//       buttonAction: () => alert('Button clicked'),
//       buttonType: 'secondary'
//     }
//   }
//   ];

//   const { resourses, setResourses } = useState(resoursesDetails);

//   const accordianToggle = (index) => {
//     console.log('res', resourses, index);
//     let _resourses = [...resourses];
//     _resourses[index].collapsed = !_resourses[index].collapsed;
//     console.log(_resourses, index);
//     setResourses(_resourses);
//   };

//   return (
//     <div className='grey-widget pa-8X'>
//       <div className='pb-4X'>
//         <Title size='Large' bold>Courses sections.</Title>
//       </div>


//       {resoursesDetails.map((resourse, index) => {
//         console.log(resourse, '----->res');
//         let _html = <div className='accordian-container pv-6X'>
//           <div className='accordian-labels' onClick={() => { console.log(index); accordianToggle(index); }}>
//             <div className='pr-2X'><Icon name={resourse.icon.name} size='28px' color={resourse.icon.color}></Icon></div>
//             <div>
//               <Title size='medium'>{resourse.title}</Title>
//               <div className='mt-1X'>
//                 <Body size='medium'><b>{resourse.time}</b> <span>{resourse.status}</span></Body>
//               </div>
//             </div>
//           </div>
//           {resourse.collapsed && (
//             <div className='accordian-content pl-1X ml-8X pt-4X'>
//               <div className='section-details'>
//                 <Body size='small'>
//                   <b> {resourse.sectionDetailTitel}</b>
//                   <div>
//                     {resourse.sectionDetailContent}
//                   </div>
//                 </Body>

//                 <div className='mt-4X'>
//                   <Button
//                     size="small"
//                     disabled={false}
//                     use={resourse.button.buttonType}
//                     onClick={e => resourse.button.buttonAction}>
//                     {resourse.button.buttonLabel}
//                   </Button>

//                 </div>
//               </div>

//             </div>
//           )}

//         </div>;
//         return _html;
//       })}
//     </div>
//   );
// };

// export default ResourcesOverview;