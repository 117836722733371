import React, { useState } from 'react';
import './inputSearch.scss';
import { Input } from '@vds/inputs';
import { Icon } from '@vds/icons';
import { ButtonIcon } from '@vds/button-icons';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchInput = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchQuery, setSearchQuery] = useState(searchParams.get("query") ? searchParams.get("query") : "");
  const history = useNavigate();
  let { categoryType } = useParams();
  let searchPlaceholder = "Search page";
  const { t, i18n } = useTranslation();

  switch (categoryType) {
    case "courses":
      searchPlaceholder = "Search courses";
      break;
    case "events":
      searchPlaceholder = "Search events";
      break;
  }

  const handleSearch = () => {
    if(searchQuery?.trim() != "") {
      history(`/category/${categoryType}/search?query=${searchQuery}`);
      location.reload();
    }
  }

  return (
    <div className="search-input-wrapper">
      <input aria-label="Search" name="Search" type="text" className="search-input " placeholder={t(searchPlaceholder)} value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}} onKeyDown={(e) => {if(e.key === 'Enter'){handleSearch()}}}/>
      <span className="icon-space">
        {searchQuery != "" && <ButtonIcon
          kind="ghost"
          size="large"
          ariaLabel={t('Clear search')}
          onClick={(e) => {setSearchQuery("")}}
          renderIcon={(props) => <Icon
            name="close"
            size="large"
            color="#000000"
            {...props}
          />}
        />}
        <ButtonIcon
          kind="ghost"
          size="large"
          ariaLabel={t(searchPlaceholder)}
          onClick={() => {handleSearch()}}
          renderIcon={(props) => <Icon
            name="search"
            size="large"
            color="#000000"
            {...props}
          />}
        />
      </span>
    </div>

  );
};

export default SearchInput;

{/* <div className="search-input">
      <Input
        type="text"
        readOnly={false}
        required={true}
        disabled={false}
        error={false}
        pl
      />

  </div>*/}